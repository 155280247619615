import { Modal , Input} from 'antd';
import React , {useState , useEffect} from 'react';
import { error, success } from '../../contants/snackbars';
import { addBrand, editBrand } from '../../stores/api_calls/carlists';
import './brands.scss';

const BrandForm = (props) => {

    const {isEdit , data  , onCancel , visible , fetchData , tablePagination} = props 
    const [submitLoading , setSubmitLoading] = useState(false)
    const [form ,setForm] = useState({
        brandName:""
    }) 

    useEffect(()=>{
        if(isEdit){
            console.log(data.name)
            setForm({...form , brandName : data.name})
        }else{
            setForm({...form , brandName : ""})
        }
    },[isEdit])

    const handleFieldChange = (name , e) => {
        setForm({
            ...form,
            [name]:e.target.value
        })
    }

    const onSubmit = () => {
        setSubmitLoading(true)
        const pagination = {
            page : tablePagination.current,
            limit :tablePagination.pageSize,
            keyword:""
        }
        
        if(isEdit){
            const updatedData = {
                id:data._id,
                name:form.brandName
            }
            const brand = editBrand(updatedData)

            brand.then((res)=>{
                if(res.data.success){
                    fetchData(pagination)
                    success("Brand Updated Successfully")
                    setSubmitLoading(false)
                    onClose()
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }else{
            const newData = {
                name:form.brandName
            }

            const brand = addBrand(newData)

            brand.then((res)=>{
                if(res.data.success){
                    fetchData(pagination)
                    success("Brand Added Successfully")
                    setSubmitLoading(false)
                    onClose()
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }
    }


    const onClose = () =>{
        onCancel()
        setForm({...form , brandName : ""})
    }

    return (
        <div>
            <Modal
                className='formModal'
                title={`${isEdit?"Edit" : "Add"} Brand`}
                visible={visible}
                onOk={onSubmit}
                okButtonProps={{ disabled:  form.brandName !== "" ? false : true  }}
                onCancel={onClose}
                okText={`${isEdit?"Save" : "Submit"}`}
                confirmLoading={submitLoading}
            >

                <div className='form-container'>
                    <p className='form-label'>Brand Name <span>*</span> : </p>
                    <Input 
                        value={form.brandName}
                        onChange={(text)=>handleFieldChange('brandName',text)}
                        placeholder='Please type Brand Name'
                    />
                </div>
            </Modal>
        </div>
    )
}

export default BrandForm
