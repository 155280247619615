import React , {useEffect , useState , createContext} from 'react';
import { fetchPermissions, fetchRoles } from '../api_calls/users';

export const AccessContext = createContext();

export const AccessContextProvider = (props) => {

    const [roles , setRoles] = useState([])
    const [permissions , setPermissions] = useState([])

    useEffect(()=>{
        getRoles()
        getPermissions()
    },[])

    const getRoles = () => {
        const roles = fetchRoles()
        roles.then((res)=>{
            if(res.data.success){
                setRoles(res.data.data)
            }
        })
    }

    const getPermissions = () => {
        const permissions = fetchPermissions()
        permissions.then((res)=>{
            if(res.data.success){
                setPermissions(res.data.data)
            }
        })
    }

    return (
        <AccessContext.Provider value={[roles , permissions , setRoles , setPermissions]}>
            {props.children}
        </AccessContext.Provider>
    )
}
