import { Modal } from 'antd'
import React from 'react'
import './custom.scss'

const CustomDeleteModal = (props) => {

    const {title , visible , onSubmit , onCancel , data}=props

    return (
        <Modal
            className='deleteModal'
            title={title}
            visible={visible}
            onOk={onSubmit}
            onCancel={onCancel}
            okText="Delete"
        >
            <p
                style={{
                    margin:0
                }}
            >
                Are you sure you want to delete this  <span className='data-name'>“ {data.itemName} ”</span> ? Clicking continue will <span style={{fontWeight:'bold'}}>permanently</span> delete the {data.type} in our database.
            </p>
        </Modal>
    )
}

export default CustomDeleteModal
