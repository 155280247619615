import { Col, Divider, Input, Row } from 'antd';
import React , {useContext , useState} from 'react';
import { UserContext } from '../stores/contexts/userContext';
import './profile.scss';
import {PrimaryButton} from '../custom_components/customButton';
import { colorScheme } from '../contants/variables';
import { RiEdit2Fill , RiSave2Fill} from 'react-icons/ri';
import {FaCheckCircle} from 'react-icons/fa';
import { editProfile, verifyEmail } from '../stores/api_calls/users';
import { error, success } from '../contants/snackbars';
import CustomConfirmModal from '../custom_components/customeConfirmModal';
import { useNavigate } from 'react-router-dom';
import { logout } from '../stores/api_calls/authentications';

const Profile = (props) => {

    const navigate = useNavigate()

    const [userConfig , setUserConfig] = useContext(UserContext)
    const profile = userConfig.userDetails
    const [form , setForm] = useState({
        id:profile._id,
        firstName:profile.user_first_name,
        lastName:profile.user_last_name,
        roleId:profile.role_id,
        email:"",
        contactNumber:"",
    })

    const [emails , setEmails] = useState({isLoading:false, data : profile.user_contact_emails})
    const [contactNumbers , setContactNumbers] = useState({isLoading:false, data : profile.user_contact_numbers})

    const [isEdit , setIsEdit] = useState(false)
    const [confirm , setConfirm] = useState(false)

    const handleFieldChange = (name , e) => {
        setForm({...form , [name]:e.target.value})
    }

    const onLogout = () => {
        const signOut = logout()
        signOut.then((res)=>{
            if(res.data.success){
                setUserConfig({...userConfig,isLoggedIn:false  , userDetails:{}})
                navigate("../login", { replace: true })
            }
        }).catch((e)=>{
            console.log(e)
        })
        
    }

    const onConfirmModal = () => {
        setConfirm(true)
    }

    const onSubmit = () => {
        // console.log(form)
        const profile = editProfile(form , emails.data , contactNumbers.data)

        profile.then((res)=>{
            if(res.data.success){
                console.log(res.data.data)
                setUserConfig({
                    ...userConfig , 
                    userDetails:{
                        ...userConfig.userDetails,
                        user_first_name : res.data.data.user_first_name,
                        user_last_name : res.data.data.user_last_name,
                        // user_contact_emails : res.data.user_contact_emails,
                        // user_contact_numbers : res.data.user_contact_numbers
                    }
                })

                success("Profile updated successfully , You will be log out in a moment.")

                setTimeout(()=>onLogout(),3000)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onEnter = (name , e) => {
        if (e.key === "Enter") {
            if(name==="email"){
                setEmails({
                    ...emails,
                    data:[...emails.data , {email:form.email , is_active:false}]
                })
                setForm({...form , email:""})
            }else{
                setContactNumbers({
                    ...contactNumbers,
                    data:[...contactNumbers.data , {number:form.contactNumber , is_active:false}]
                })
                setForm({...form , contactNumber:""})
            }
        }
    }

    const onRemove = (name , value) => {
        if(name==="email"){
            const filteredData = emails.data.filter((e)=>e.email !== value)
            setEmails({
                ...emails,
                data:filteredData
            })
        }else{
            const filteredData = contactNumbers.data.filter((e)=>e.number !== value)
            setContactNumbers({
                ...contactNumbers,
                data:filteredData
            })
        }
    }

    const onMakeActive = (name , item) => {
        if(name==="number"){
            setContactNumbers({...contactNumbers , isLoading:true})
            const editedData = contactNumbers.data

            editedData.map((el)=>{
                if(item.number === el.number){
                    el.is_active = true
                }else{
                    el.is_active = false
                }
            })

            setContactNumbers({...contactNumbers ,isLoading:false, data:editedData})
        }else{
            setEmails({...emails , isLoading:true})
            const editedData = emails.data

            editedData.map((el)=>{
                if(item.email === el.email){
                    el.is_active = true
                }else{
                    el.is_active = false
                }
            })

            setEmails({...emails ,isLoading:false, data:editedData})
        }
    }

    const onVerifyEmail = () => {
        const verify = verifyEmail(form.email)

        verify.then((res)=>{
            if(res.data.success){
                console.log(res.data.data)
            }
        }).catch((e)=>{
            error("EMAIL NOT FOUND")
        })
    }
    
    return (
        <div className='profile-container'>
            <div className='header-container'>
                <p className='headers'>Personal Details</p>
            </div>
            

            <Row gutter={[20,20]}>
                <Col span={8}>
                    <div className='form-container'>
                        <p>First Name</p>
                        <Input size='large' value={form.firstName} onChange={(text)=>handleFieldChange('firstName',text)} disabled={!isEdit}/>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='form-container'>
                        <p>Last Name</p>
                        <Input size='large' value={form.lastName} onChange={(text)=>handleFieldChange('lastName',text)} disabled={!isEdit}/>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='action-button-container'>
                        <PrimaryButton
                            Icon={<RiEdit2Fill style={{marginRight:"10px"}}/>}
                            label={!isEdit ? "Edit Profile" : "Cancel"}
                            color={!isEdit ? colorScheme.secondary : colorScheme.lightgray}
                            onClick={()=>setIsEdit(!isEdit)}
                        />
                        {isEdit? 
                            <PrimaryButton
                                Icon={<RiSave2Fill style={{marginRight:"10px"}}/>}
                                label="Save Changes"
                                color={colorScheme.primary}
                                // onClick={onConfirmModal}
                                onClick={onSubmit}
                            />
                        :null}
                    </div>
                </Col>
            </Row>
            
            <Divider/>

            <Row gutter={[20,20]} style={{marginTop:"10px"}}>
                <Col span={8}>
                    <div className='form-container'>
                        <p>Contact Numbers</p>
                        <Input 
                            value={form.contactNumber} 
                            onChange={(text)=>handleFieldChange('contactNumber',text)} 
                            placeholder={contactNumbers.data.length===1?"Maximum number of contact numbers reached":"Press \"Enter\" to add contact number"}
                            disabled={!isEdit || contactNumbers.data.length===5?true:false}
                            onKeyDown={(e)=>onEnter("contactNumber" ,e)}
                        />
                        <div className='contacts-container'>
                            <div className='contacts-length'>
                                <p>Available Contact Numbers</p>
                                <p>{contactNumbers.data.length}/5</p>
                            </div>
                            {contactNumbers.isLoading?
                                <p>Loading ... </p>
                            :!contactNumbers.isLoading && contactNumbers.data?.length ? 
                                contactNumbers.data.map((item,idx)=>
                                    <div key={idx} className='contact-div'>
                                        <p>{item.number}</p>
                                        <div className={`contact-badge ${item.is_active ? "active" : ""}`}>
                                            {item.is_active? 
                                                <span className='active'>Active</span>
                                            :null}
                                            {!item.is_active && isEdit ?
                                                <span className='make-active' onClick={()=>onMakeActive("number" , item)}>Make Active</span>
                                            :null}
                                            {!item.is_active&&isEdit  ? <span className='remove' onClick={()=>onRemove("number" , item.number)}> Remove </span> :null}
                                        </div>
                                    </div>
                                )
                            :null}
                        </div>
                    </div>
                </Col>
                <Col span={10}>
                    <div className='form-container'>
                        <p>Email Addresss</p>
                        <Input 
                            value={form.email} 
                            onChange={(text)=>handleFieldChange('email',text)} 
                            placeholder={emails.data.length===5?"Maximum number of emails reached":"Press \"Enter\" to add email"}
                            disabled={!isEdit || emails.data.length===5?true:false}
                            onKeyDown={(e)=>onEnter("email" , e)}
                        />

                        {/* <PrimaryButton
                            label="Verify"
                            color={colorScheme.primary}
                            onClick={()=>onVerifyEmail()}
                        /> */}

                        <div className='contacts-container'>

                            <div className='contacts-length'>
                                <p>Available Emails</p>
                                <p>{emails.data.length}/5</p>
                            </div>
                            
                            {emails.data.map((item,idx)=>
                                <div key={idx} className='contact-div'>
                                    <p>{item.email}</p>
                                    <div className={`contact-badge ${item.is_active ? "active" : ""}`}>
                                        {item.is_active? 
                                            <span className='active'>Active</span>
                                        :null}
                                        {!item.is_active && isEdit ?
                                            <span className='make-active' onClick={()=>onMakeActive("email" , item)}>Make Active</span>
                                        :null}
                                        {!item.is_active && isEdit  ? <span className='remove' onClick={()=>onRemove("email" , item.email)}> Remove </span> :null}
                                    </div>
                                </div>
                            )}
                        </div>
                        
                    </div>
                </Col>
            </Row>

            <Divider className='hr-line'/>

            <div className='header-container'>
                <p className='headers'>Personal Details</p>
            </div>

            <div className='role-container'>
                <p className='form-label'>Role : <span className='role'>{profile.role_details.name}</span></p>
            </div>

            <div className='permissions-container'>
                <p className='form-label'>Permissions : </p>
                <Row gutter={[20,20]}>
                    {profile.user_permissions.map((el,idx)=>
                        <Col span={6} key={idx}>
                            <div className='permission-container'>
                                <span>{el}</span>
                                <FaCheckCircle/>
                            </div>
                        </Col>
                    )}
                </Row>
                
            </div>

            <CustomConfirmModal
                title="Update Profile"
                visible={confirm}
                onSubmit={onSubmit}
                onCancel={()=>setConfirm(false)}
            >
                <p className='modal-phrase'>
                    Are you sure you want to continue to update this profile? You will be automatically log out after clicking "Okay" ,for re-alignment you user details in our system.
                </p>
            </CustomConfirmModal>
        </div>
    );
};

export default Profile;
