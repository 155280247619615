export const validateBannerForm = (data) =>{

    const requiredFields = [
        "bannerName",
        "bannerType",
        "clientName",
        "startDate",
        "endDate",
        // "imageUrl"
    ]

    var validFieldCount = 0
    
    requiredFields.forEach((field)=>{
        if(data[field]){
            validFieldCount = validFieldCount + 1
            // console.log(field , true)
        }else{
            // console.log(field , false)
        }
    })

    if(validFieldCount===requiredFields.length){
        return true
    }else{
        return false
    }
}

