import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  CancelButton,
  IconButton,
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import { RiImageAddFill } from "react-icons/ri";
import { IoMdAddCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { FaUpload } from "react-icons/fa";
import { Col, Input, Row, Select, Checkbox, TimePicker } from "antd";
import { colorScheme } from "../../contants/variables";
import ArticlePreviewModal from "../../custom_components/articlePreviewModal";
import {
  addArticle,
  editArticle,
  fetchSections,
} from "../../stores/api_calls/articles";
import { error, success } from "../../contants/snackbars";
import { uploadImage } from "../../stores/api_calls/upload";
import { useLocation, useNavigate } from "react-router-dom";
import Kjur from "../../stores/utils/jwt";
import { validateArticleForm } from "../../stores/helpers/articleFormValidation";
import {
  addCarWorkShop,
  fetchBrands,
  fetchCategories,
} from "../../stores/api_calls/car-workshop";
import moment from "moment";
import RentalUploads from "../rentals/RentalUploads";

const ImageUploadComponent = (props) => {
  const { onChange } = props;
  const fileUploader = useRef(null);

  const uploadImageCallBack = (link) => {
    onChange(link, "auto", "100%", "uploaded image");
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    // const localUrl = URL.createObjectURL(file)

    const upload = uploadImage(file);

    upload
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          const url = res.data.data.metadata.image_url;
          uploadImageCallBack(url);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
        );
      });
  };

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  return (
    <div>
      <div className="icon-button-div">
        <IconButton
          className="icon-button upload"
          tootTipTitle="Upload Image"
          Icon={<RiImageAddFill />}
          onClick={handleOpenGallery}
        />
      </div>

      <input
        ref={fileUploader}
        hidden
        type="file"
        name="image-event"
        id="image-event"
        accept="image/*"
        onChange={onSelectFile}
      />
    </div>
  );
};

const CarWorkshopForm = (props) => {
  const location = useLocation();

  const fileUploader = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState({
    imagesIds: [],
    name: "",
    address: "",
    email: "",
    contact: "",
    website: "",
    facebook: "",
    categories: [],
    brands: [],
    description: "",
    open_till_late: false,
    open_on_sundays: false,
    customer_lounge: false,
    accepts_credit_card: false,
    installment_plan: false,
    door_step_service: false,
  });

  const [requestLoading, setRequestLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 100,
    defaultPageSize: 100,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const onSubmit = () => {
    setRequestLoading(true);

    let imagesIds = [];

    if (selectedFiles.length) {
      selectedFiles.forEach((file, index) => {
        const upload = uploadImage(file, index + 1);

        upload.then((res) => {
          if (res.data.success) {
            imagesIds = [...imagesIds, res.data.data._id];
            setForm({
              ...form,
              imagesIds: [...form.imagesIds, res.data.data._id],
            });

            if (imagesIds.length === selectedFiles.length) {
              handleAddCarWorkShop(imagesIds);
            }
          }
        });
      });
    } else {
      handleAddCarWorkShop(imagesIds);
    }
  };

  const handleAddCarWorkShop = (imagesIds) => {
    const createCarWorkShop = addCarWorkShop({ ...form, imagesIds });
    createCarWorkShop
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          success("New car workshop has been added successfully!");

          setRequestLoading(false);
        }
      })
      .catch((e) => {
        setForm({ ...form, imagesIds: [] });
        error("Failed to add car workshop");
        console.log("error");
        setRequestLoading(false);
      });
  };

  const onCheckboxChange = (name) => {
    setForm({ ...form, [name]: !form[name] });
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleOnChangeOperatingHours = (time, timeString) => {
    setForm({
      ...form,
      opening_time: timeString[0],
      closing_time: timeString[1],
    });
  };

  const handleChangeCategory = (value) => {
    setForm({ ...form, categories: value });
  };

  const handleChangeBrand = (value) => {
    setForm({ ...form, brands: value });
  };

  useEffect(() => {
    handleFetchCategories();
    handleFetchBrands();
  }, []);

  const handleFetchCategories = () => {
    const filter = {
      keyword: "",
      page: pagination.current,
      limit: pagination.pageSize,
    };
    const getCategories = fetchCategories(filter);

    getCategories
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }));
          setCategories(transformData);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleFetchBrands = () => {
    const filter = {
      keyword: "",
      page: pagination.current,
      limit: pagination.pageSize,
    };
    const getBrands = fetchBrands(filter);

    getBrands
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }));
          setBrands(transformData);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const resetForm = () => {
    setForm({ imagesIds: [] });
  };

  return (
    <div className="article-form-container">
      <div className={`table-title-icon ${!isEdit ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEdit ? "add" : "edit"}`}>
        {!isEdit ? "Add Car Workshop" : `Edit Car Workshop`}
      </p>

      <div className="form-container">
        <Row gutter={[15, 15]}>
          <Col span={16}>
            <div className="form-fields">
              <p className="form-field-label">
                Car Workshop Name <span>*</span> :{" "}
              </p>
              <Input
                placeholder="Car Workshop Name"
                value={form.name}
                onChange={(text) => handleFieldChange("name", text)}
              />
            </div>

            <div className="form-fields">
              <p className="form-field-label">
                Address <span>*</span> :{" "}
              </p>
              <Input
                placeholder="Address"
                value={form.address}
                onChange={(text) => handleFieldChange("address", text)}
              />
            </div>

            <div className="form-fields">
              <p className="form-field-label">
                Email <span>*</span> :{" "}
              </p>
              <Input
                placeholder="Email"
                value={form.email}
                onChange={(text) => handleFieldChange("email", text)}
              />
            </div>
            <div className="form-fields">
              <p className="form-field-label">
                Contact No <span>*</span> :{" "}
              </p>
              <Input
                placeholder="Contact No"
                value={form.contact}
                onChange={(text) => handleFieldChange("contact", text)}
              />
            </div>
            <div className="form-fields">
              <p className="form-field-label">Website : </p>
              <Input
                placeholder="Website URL"
                value={form.website}
                onChange={(text) => handleFieldChange("website", text)}
              />
            </div>
            <div className="form-fields">
              <p className="form-field-label">Facebook : </p>
              <Input
                placeholder="Facebook"
                value={form.facebook}
                onChange={(text) => handleFieldChange("facebook", text)}
              />
            </div>
            <div className="form-fields">
              <p className="form-field-label">
                Operating hours <span></span> :{" "}
              </p>
              <TimePicker.RangePicker
                use12Hours
                onChange={handleOnChangeOperatingHours}
                style={{ width: "100%" }}
                format="h:mm A"
              />
            </div>

            <div className="form-fields">
              <p className="form-field-label">
                Categories <span></span> :{" "}
              </p>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                placeholder="select categories"
                defaultValue={[]}
                onChange={handleChangeCategory}
                optionLabelProp="label"
                options={categories}
                optionRender={(option) => <Space>{option.data.name}</Space>}
              />
            </div>

            <div className="form-fields">
              <p className="form-field-label">
                Brands <span></span> :{" "}
              </p>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                placeholder="select brands"
                defaultValue={[]}
                onChange={handleChangeBrand}
                optionLabelProp="label"
                options={brands}
                optionRender={(option) => <Space>{option.data.name}</Space>}
              />
            </div>

            <div className="form-fields">
              <p className="form-field-label">
                Description <span></span> :{" "}
              </p>
              <Input.TextArea
                rows={8}
                value={form.description}
                onChange={(text) => handleFieldChange("description", text)}
              />
            </div>

            <br />
            <p className="permision-label">Other Details : </p>
            <Row gutter={[5, 5]}>
              <Col span={12}>
                <div
                  className="permission-checklist"
                  style={{
                    background: colorScheme.lightgray,
                  }}
                >
                  <Checkbox
                    valuePropName="checked"
                    value={form.open_till_late}
                    checked={form.open_till_late}
                    onChange={(e) => onCheckboxChange("open_till_late", e)}
                  >
                    Open till late
                  </Checkbox>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="permission-checklist"
                  style={{
                    background: colorScheme.lightgray,
                  }}
                >
                  <Checkbox
                    valuePropName="checked"
                    value={form.open_on_sundays}
                    checked={form.open_on_sundays}
                    onChange={(e) => onCheckboxChange("open_on_sundays", e)}
                  >
                    Open on Sunday
                  </Checkbox>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="permission-checklist"
                  style={{
                    background: colorScheme.lightgray,
                  }}
                >
                  <Checkbox
                    valuePropName="checked"
                    value={form.customer_lounge}
                    checked={form.customer_lounge}
                    onChange={(e) => onCheckboxChange("customer_lounge", e)}
                  >
                    Customer lounge
                  </Checkbox>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="permission-checklist"
                  style={{
                    background: colorScheme.lightgray,
                  }}
                >
                  <Checkbox
                    valuePropName="checked"
                    value={form.accepts_credit_card}
                    checked={form.accepts_credit_card}
                    onChange={(e) => onCheckboxChange("accepts_credit_card", e)}
                  >
                    Credit card
                  </Checkbox>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="permission-checklist"
                  style={{
                    background: colorScheme.lightgray,
                  }}
                >
                  <Checkbox
                    valuePropName="checked"
                    value={form.installment_plan}
                    checked={form.installment_plan}
                    onChange={(e) => onCheckboxChange("installment_plan", e)}
                  >
                    Installment plan
                  </Checkbox>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="permission-checklist"
                  style={{
                    background: colorScheme.lightgray,
                  }}
                >
                  <Checkbox
                    valuePropName="checked"
                    value={form.door_step_service}
                    checked={form.door_step_service}
                    onChange={(e) => onCheckboxChange("door_step_service", e)}
                  >
                    Home Service
                  </Checkbox>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            {/* {!form.image ? (
              <div>
                <div
                  className="upload-container col-div"
                  onClick={handleOpenGallery}
                >
                  <FaUpload />
                  <p>UPLOAD LOGO</p>
                </div>

                <input
                  ref={fileUploader}
                  id="getFile"
                  type="file"
                  hidden
                  onChange={handleImageChange}
                />
              </div>
            ) : (
              <div className="local-image">
                <div
                  className="erase-image"
                  onClick={() => handleDeleteSelectedFile()}
                >
                  <IoMdCloseCircle />
                </div>
                <img src={form.articleThumbnail} />
              </div>
            )} */}
          </Col>
        </Row>
      </div>

      <RentalUploads
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        form={form}
        setForm={setForm}
        isEdit={isEdit}
      />

      <div className="btn-div">
        <div className="btn">
          {requestLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton
              label={isEdit ? "Update Car Workshop" : "Create Car Workshop"}
              color={colorScheme.primary}
              onClick={() => onSubmit()}
              disabled={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarWorkshopForm;
