import React, { useState, useRef, useEffect } from "react";
import "./careers.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  CancelButton,
  IconButton,
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import { RiImageAddFill } from "react-icons/ri";
import { IoMdAddCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { FaUpload } from "react-icons/fa";
import { Col, Input, Row, Select } from "antd";
import { colorScheme } from "../../contants/variables";
import ArticlePreviewModal from "../../custom_components/articlePreviewModal";
import {
  addArticle,
  editArticle,
  fetchSections,
} from "../../stores/api_calls/articles";
import { error, success } from "../../contants/snackbars";
import { uploadImage } from "../../stores/api_calls/upload";
import { useLocation, useNavigate } from "react-router-dom";
import Kjur from "../../stores/utils/jwt";
import { validateArticleForm } from "../../stores/helpers/articleFormValidation";
import { addCareer, updateCareer } from "../../stores/api_calls/careers";

const statuses = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const ImageUploadComponent = (props) => {
  const { onChange } = props;
  const fileUploader = useRef(null);

  const uploadImageCallBack = (link) => {
    onChange(link, "auto", "100%", "uploaded image");
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    // const localUrl = URL.createObjectURL(file)

    const upload = uploadImage(file);

    upload
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          const url = res.data.data.metadata.image_url;
          uploadImageCallBack(url);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
        );
      });
  };

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  return (
    <div>
      <div className="icon-button-div">
        <IconButton
          className="icon-button upload"
          tootTipTitle="Upload Image"
          Icon={<RiImageAddFill />}
          onClick={handleOpenGallery}
        />
      </div>

      <input
        ref={fileUploader}
        hidden
        type="file"
        name="image-event"
        id="image-event"
        accept="image/*"
        onChange={onSelectFile}
      />
    </div>
  );
};

const CareerForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const [isEditing, setIsEditing] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);

  const [form, setForm] = useState({
    jobId: "",
    jobTitle: "",
    status: "Active",
  });

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEditing(true);
      const parsedData = Kjur.decode(params.get("token"));

      const rawDraftContentState = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      // convert the raw state back to a useable ContentState object
      const emptyContent = convertFromRaw(JSON.parse(rawDraftContentState));

      //converting the json to display in EDITOR
      const contentState = convertFromRaw(
        parsedData?.job_description ?? emptyContent
      );
      const newContent = EditorState.createWithContent(contentState);

      setForm({
        ...form,
        id: parsedData._id,
        jobTitle: parsedData.job_title,
        status: parsedData.status,
      });
      setEditorState(newContent);
    }
  }, []);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const handleDropdownChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const onSubmit = () => {
    setRequestLoading(true);

    // the raw state, stringified
    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    // convert the raw state back to a useable ContentState object
    const contentState = convertFromRaw(JSON.parse(rawDraftContentState));

    const newContent = EditorState.createWithContent(contentState);
    setConvertedContent(newContent);

    const newJob = form;
    // newArticle.articleBody = rawDraftContentState
    newJob.jobDescription = JSON.parse(rawDraftContentState);

    if (isEditing) {
      const updateJob = updateCareer(newJob);
      updateJob
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data);
            success("Career updated Successfully");
            navigate("../careers", { replace: true });
            setRequestLoading(false);
          }
        })
        .catch((e) => {
          console.log(e)
          error("Failed to Update Career");
          setRequestLoading(false);
        });
    } else {
      const createCareer = addCareer(newJob);
      createCareer
        .then((res) => {
          if (res.data.success) {
            success("Career created Successfully");
            navigate("../careers", { replace: true });
            setRequestLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          error("Failed to Add Career");
          setRequestLoading(false);
        });
    }
  };

  return (
    <div className="career-form-container">
      <div className={`table-title-icon ${!isEditing ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEditing ? "add" : "edit"}`}>
        {!isEditing ? "Create Job" : `Edit Job`}
      </p>

      <div className="form-container">
        <Row gutter={[15, 15]}>
          <Col span={12}>
            <div className="form-fields">
              <p className="form-field-label">
                Status <span>*</span> :{" "}
              </p>
              <Select
                showSearch
                value={form.status}
                onChange={(value) => handleDropdownChange("status", value)}
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {statuses.map((el, i) => (
                  <Select.Option key={i} value={el.value}>
                    {`${el.label}`}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="form-fields">
              <p className="form-field-label">
                Job Title <span>*</span> :{" "}
              </p>
              <Input
                placeholder="Job Title"
                value={form.jobTitle}
                onChange={(text) => handleFieldChange("jobTitle", text)}
              />
            </div>
          </Col>
        </Row>

        <div className="form-fields">
          <p className="form-field-label">
            Job Description <span>*</span> :{" "}
          </p>
        </div>
        <div className="editor-container">
          <Editor
            editorStyle={{ height: 300 }}
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: { inDropdown: false },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                component: ImageUploadComponent,
                previewImage: true,
                uploadEnabled: true,
                alt: { present: true, mandatory: true },
              },
            }}
          />
        </div>
      </div>

      <div className="btn-div">
        <div className="btn">
          {requestLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton
              label={isEditing ? "Update Job" : "Create Job"}
              color={colorScheme.primary}
              onClick={() => onSubmit()}
              disabled={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
