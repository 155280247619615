import React from "react";
import { Tabs } from "antd";
import "./about.scss";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import BiddingTermsAndConditions from "./BiddingTermsAndConditions";
import AboutUs from "./AboutUs";
import WhatsAppNumber from "./WhatsAppNumber";
import IosPrivacyPolicy from "./IosPrivacyPolicy";
import IosTermsAndConditions from "./IosTermsAndConditions";
const { TabPane } = Tabs;

const AboutIndex = () => {
  return (
    <div className="form-table-container">
      <div className="form-body-container">
        <Tabs size="large">
          <TabPane tab="About Us" key="1">
            <AboutUs />
          </TabPane>
          <TabPane tab="Privacy Policy" key="2">
            <PrivacyPolicy />
          </TabPane>
          <TabPane tab="Terms & Conditions" key="3">
            <TermsAndConditions />
          </TabPane>
          <TabPane tab="Bidding Terms & Conditions" key="4">
            <BiddingTermsAndConditions />
          </TabPane>
          <TabPane tab="WhatsApp Number" key="5">
            <WhatsAppNumber />
          </TabPane>
          <TabPane tab="Ios Privacy Policy" key="6">
            <IosPrivacyPolicy />
          </TabPane>
          <TabPane tab="Ios Terms & Conditions" key="7">
            <IosTermsAndConditions />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AboutIndex;
