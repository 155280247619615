import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};


export const addAnnouncement = async (payload) => {
  const data = {
    description: payload.description,
    is_active: payload.is_active,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/announcement`,
    { token: encodedData },
    axiosConfig
  );
};

export const fetchAnnouncements = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/announcement?token=${encodedData}`,
    axiosConfig
  );
};

export const editAnnouncement = async (payload) => {
  const data = {
    id: payload.id,
    description: payload.description,
    is_active: payload.is_active,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/announcement/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteAnnouncement = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/announcement/delete?token=${encodedData}`,
    axiosConfig
  );
};
