import React , {useEffect , useState , useContext} from 'react'
import './login.scss'
import logo from '../../assets/images/logo.png'
import { Button, Form, Input } from 'antd'
import {BiUser,BiLockAlt} from 'react-icons/bi'
import { CancelButton, FormPrimaryButton, LoadingButton, PrimaryButton } from '../custom_components/customButton'
import { useNavigate } from 'react-router-dom'
import { login, verifyOTP } from '../stores/api_calls/authentications'
import { UserContext } from '../stores/contexts/userContext'
import Kjur from '../stores/utils/jwt';
import { colorScheme } from '../contants/variables'
import { newPassword, requestOtpForResetPassword } from '../stores/api_calls/users'
import { success } from '../contants/snackbars'

const ForgotPassword = () => {

    let navigate = useNavigate()
    const [form,setForm] = useState({})
    const [step , setStep] = useState(0)
    const [userConfig ,setUserConfig] = useContext(UserContext)
    const [callFailed,setCallFailed] = useState({isError:false , message:""})
    const [callLoading , setCallLoading] = useState(false)
    const [credentials , setCredential] = useState({username:"" , otp:"" , id :""})

    const handleRequestOtpForResetPassword = (values) => {

        const data = {
            isEmail : true,
            creds : values.email
        }

        const checkAcc = requestOtpForResetPassword(data)

        checkAcc.then((res)=>{
            if(res.data.success){
                setCredential({...credentials , username : values.email})
                setCallFailed({...callFailed , isError:false , message:""})
                setCallLoading(false)
                setStep(1)
            }
        }).catch((error)=>{
            setCallFailed({...callFailed , isError:true , message:error.response.data.server_response})
            setCallLoading(false)
        })
    }

    const onSubmitOTP = (values) => {

        const data = {
            username : credentials.username,
            otp : values.otp
        }

        const onVerifyOTP = verifyOTP(data)

        onVerifyOTP.then((res)=>{
            if(res.data.success){
                const data = Kjur.decode(res.data.token)
                setCredential({...credentials , otp : values.otp , id:data._id})
                setCallFailed({...callFailed , isError:false , message:""})
                setCallLoading(false)
                setStep(2)
            }
        }).catch((error)=>{
            setCallFailed({...callFailed , isError:true , message:error.response.data.server_response})
            setCallLoading(false)
        })
    }

    const onResend = () => {
        // onSubmitOTP(credentials)
        const info = {
            email: credentials.username
        }
        handleRequestOtpForResetPassword(info);
    }

    const onResetPassword = (values) => {

        const data = {
            id:credentials.id,
            password : values.password,
            confirmPassword : values.confirmPassword
        }

        const resetPassword = newPassword(data)

        resetPassword.then((res) => {
            if(res.data.success){
                success("Password changed. Redirecting to login page.")
                navigate("../login", { replace: true })
            }
        }).catch((error)=>{
            setCallFailed({...callFailed , isError:true , message:error.response.data.server_response?.password[0] ?? "Server Error"})
            // console.log(error.response.data.server_response.password[0])
            setCallLoading(false)
        })
    }

    return (
            <div className='body-container'>
                <div className='login-container'>
                    <div className='logo-container'>
                        <img src={logo}/>
                    </div>

                    <div className='form-container'>
                        {step===0?
                            <Form
                                onFinish={handleRequestOtpForResetPassword}
                            >
                                <h2 style={{fontSize: '1.25em', color: '#222', textAlign: 'center', marginBottom: 20}}>Reset Password</h2>
                                <Form.Item
                                    name="email"
                                    rules={[{required:true , message:'Please input your Email'}]}
                                >
                                    <Input
                                        prefix={<BiUser/>}
                                        placeholder="Email"
                                        size='large'
                                    />
                                </Form.Item>

                                {callFailed.isError? <p className='error-text'>{callFailed.message}</p> :null}

                                <div className='forgot-container'>
                                    <p className='forgot-password-hlink' onClick={()=>navigate("../login", { replace: true })}>Login</p>
                                </div>
                                
                                <Form.Item>
                                    {callLoading?
                                        <LoadingButton/>
                                        :<FormPrimaryButton color={colorScheme.primary} label="Next"/>
                                    }
                                </Form.Item>
                            </Form>
                            :step===1?
                            <Form
                                onFinish={onSubmitOTP}
                            >
                                <h2 style={{fontSize: '1.25em', color: '#222', textAlign: 'center', marginBottom: 20}}>Reset Password</h2>
                                <p className='otp-header'>We have send an <strong>OTP</strong> to your registered email</p>
                                <div
                                    className='otp-form-container'
                                >
                                    <Form.Item
                                        name="otp"
                                        rules={[{required:true , message:'Please input your OTP'}]}
                                        style={{flex:1}}
                                    >
                                        <Input
                                            // prefix={<BiUser/>}
                                            placeholder="OTP"
                                            size='large'
                                        />
                                    </Form.Item>

                                    <div style={{width:'100px'}}>
                                        <PrimaryButton
                                            color={colorScheme.primary} 
                                            label="Resend"
                                            onClick={onResend}
                                        />
                                    </div>
                                </div>

                                {callFailed.isError? <p className='error-text'>{callFailed.message}</p> :null}

                                <Form.Item>
                                    {callLoading?
                                        <LoadingButton/>
                                        :<FormPrimaryButton color={colorScheme.primary} label="Verify OTP"/>
                                    }
                                </Form.Item>
                                <CancelButton bgColor={colorScheme.offwhite} textColor={colorScheme.black} label="Go Back" onClick={()=>setStep(0)}/>
                            </Form>

                            :<Form
                                onFinish={onResetPassword}
                            >
                                <h2 style={{fontSize: '1.25em', color: '#222', textAlign: 'center', marginBottom: 20}}>Set Your Password</h2>
                                <Form.Item
                                    name="password"
                                    rules={[{required:true , message:'Please input your new password'}]}
                                >
                                    <Input
                                        prefix={<BiLockAlt/>}
                                        type="password"
                                        placeholder="Password"
                                        size='large'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    rules={[{ required: true, message: 'Please confirm your password' }]}
                                >
                                    <Input.Password
                                        prefix={<BiLockAlt/>}
                                        type="password"
                                        placeholder="Confirm Password"
                                        size='large'
                                    />
                                </Form.Item>

                                {callFailed.isError? <p className='error-text'>{callFailed.message}</p> :null}
                                
                                <Form.Item>
                                    {callLoading?
                                        <LoadingButton/>
                                        :<FormPrimaryButton color={colorScheme.primary} label="Continue"/>
                                    }
                                </Form.Item>
                                <CancelButton bgColor={colorScheme.offwhite} textColor={colorScheme.black} label="Go Back" onClick={()=>setStep(1)}/>
                            </Form>
                        }
                        
                    </div>
                </div>
            </div>
    )
};

export default ForgotPassword;
