import { Tooltip } from 'antd';
import React from 'react';
import { colorScheme } from '../contants/variables';
import './custom.scss';

export const ChipsWithTooltips = (props) => {
    const {dataArray} = props

    const text = dataArray.join(" , ")

    return (
        !dataArray.length?null
        :<Tooltip
            placement="left" 
            color={colorScheme.white}
            title={()=>(
                <div className='chip-tooltip-container'>
                    {dataArray.map((el , idx)=>
                        <p key={idx}>{el}</p>
                    )}
                </div>
            )}
        >
            <div className='chip-permission-container'>
                <div className='chip-text'>
                    <p>{text}</p>
                </div>
                    
                {dataArray.length > 1 ?
                    <div className='chip-count'>
                        <span>+{dataArray.length - 1}</span>
                    </div>
                :null}
                    
            </div>
        </Tooltip>
    );
};

