import axios from "axios";
import Kjur from "../utils/jwt";
import moment from "moment";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchPlateNumbers = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-plate?token=${encodedData}`,
    axiosConfig
  );
};

export const addPlateNumber = async (data) => {
  const transformedData = {
    car_plate_owner_name: data.fullname,
    contact_no: parseInt(data.contactNumber),
    email: data.email,
    car_plate_number: data.plateNumber,
    price: parseInt(data.price),
    inquiry_type: data.inquiryType,
    status: data.status
  };

  const encodedData = Kjur.encode(transformedData);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-plate`,
    { token: encodedData },
    axiosConfig
  );
};

export const deletePlateNumber = async (id) => {
  const data = {
    id,
  };

  const encodedData = Kjur.encode(data);

  // delete method doesnt do request boyd, it should be params like get
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-plate/delete?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};

export const verifyPlateNumber = async (id) => {
  const data = {
    id,
  };


  const encodedData = Kjur.encode(data);


  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-plate/verify?token=${encodedData}`,
    { token: encodedData },
    axiosConfig
  );
};


 export const editPlateNumber = async (data) => {
    const transformedData = {
      car_plate_owner_name: data.fullname,
      contact_no: parseInt(data.contactNumber),
      email: data.email,
      car_plate_number: data.plateNumber,
      price: parseInt(data.price),
      inquiry_type: data.inquiryType,
      status: data.status,
      id: data.id,
      is_sold: data.isSold
    };

    const encodedData = Kjur.encode(transformedData);

   return await axios.put(
     `${process.env.REACT_APP_API_BASE_URL}/admin/car-plate/update`,
     { token: encodedData },
     axiosConfig
   );
 };
  