import axios from "axios";
import Kjur from "../utils/jwt";
import moment from "moment";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchBanks = async (data) => {
  const obj = {
    page: data.page ?? 1,
    limit: data.limit ?? 10,
    sort_by: "desc",
    sort_order: "date_created",
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bank?token=${encodedData}`,
    axiosConfig
  );
};

export const addBank = async (bank) => {
  const data = {
    name: bank.name,
    image_id: bank.image_id,
    emails: bank.emails,
    interest: bank.rate,
    exclusive: bank.exclusive,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bank`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteBank = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bank/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const editBank= async (bank) => {
  const data = {
    id: bank.id,
    name: bank.name,
    image_id: bank.image_id,
    emails: bank.emails,
    interest: bank.rate,
    exclusive: bank.exclusive,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bank/update`,
    { token: encodedData },
    axiosConfig
  );
};

