import {
  Input,
  Modal,
  Select,
  Space,
  DatePicker,
  Divider,
  Row,
  Col,
} from "antd";
import React, { useRef, useState, useEffect } from "react";
import "./banner.scss";
import { FaUpload } from "react-icons/fa";
import { uploadImage } from "../../stores/api_calls/upload";
import { error, success } from "../../contants/snackbars";
import { addBanner, editBanner } from "../../stores/api_calls/bannerlists";
import moment from "moment";
import { validateBannerForm } from "../../stores/helpers/bannerFormValidation";
import { IconButton } from "../../custom_components/customButton";
import {
  bannerTypes,
  colorScheme,
  adsLocations,
} from "../../contants/variables";

const BannerForm = (props) => {
  const { isEdit, data, onCancel, visible, fetchData, tablePagination } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const fileUploader = useRef(null);
  const [selectedFile, setSelectedFile] = useState({ file: null, url: "" });
  const [form, setForm] = useState({
    bannerId: "",
    bannerName: "",
    bannerType: "Hero-Banner",
    url: "https://",
    clientName: "",
    startDate: moment(),
    endDate: moment(),
    imageUrl: "",
    imageId: "",
    adsLocation: "",
    timer: null
  });

  useEffect(() => {
    if (isEdit) {
      setForm({
        ...form,
        bannerId: data.id,
        bannerName: data.name,
        bannerType: data.banner_type,
        url: data.link,
        clientName: data.client_name,
        startDate: data.start_date,
        endDate: data.end_date,
        imageId: data.banner_image_urls._id["$oid"],
        imageUrl: data.banner_image_urls.metadata.image_url,
      });
    } else {
      setForm({
        ...form,
        imageUrl: selectedFile.url,
      });
    }
  }, [isEdit]);

  const refetchLists = () => {
    const pagination = {
      page: tablePagination.current,
      limit: tablePagination.pageSize,
      keyword: "",
    };
    fetchData(pagination);
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleDropdownChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const onDateFieldChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleOpenGallery = () => {
    fileUploader.current.click();
  };

  const handleImageChange = (e) => {
    setSelectedFile({ ...selectedFile, file: null, url: "" });
    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile({ ...selectedFile, file: file, url: url });
      setForm({ ...form, imageUrl: url });
    }
  };

  const onClose = () => {
    setSelectedFile({ ...selectedFile, file: null, url: "" });
    setForm({
      ...form,
      bannerName: "",
      bannerType: "Hero-Banner",
      url: "https://",
      clientName: "",
      startDate: moment(),
      endDate: moment(),
      imageUrl: "",
    });
    onCancel();
  };

  const onSubmit = () => {
    setSubmitLoading(true);
    const upload = uploadImage(selectedFile.file);
    var data = form;

    upload
      .then((res) => {
        if (res.data.success) {
          data.imageId = res.data.data._id;

          const createBanner = addBanner(data);

          createBanner
            .then((res) => {
              if (res.data.success) {
                refetchLists();
                success("New Banner Added Successfully");
                onClose();
                setSubmitLoading(false);
                // console.log(res.data.data)
              }
            })
            .catch((e) => {
              error(
                `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
              );
            });
        }
      })
      .catch((e) => {
        error(e.response.data.server_response);
      });
  };

  const onUpdate = () => {
    var data = form;

    if (selectedFile.file) {
      const upload = uploadImage(selectedFile.file);
      upload
        .then((res) => {
          if (res.data.success) {
            data.imageId = res.data.data._id;
            onUpdateBanner(data);
          }
        })
        .catch((e) => {
          error(e.response.data.server_response);
        });
    } else {
      onUpdateBanner(data);
    }
  };

  const onUpdateBanner = (data) => {
    setSubmitLoading(true);
    const updateBanner = editBanner(data);

    updateBanner
      .then((res) => {
        if (res.data.success) {
          refetchLists();
          success("Banner Updated Successfully");
          onClose();
          setSubmitLoading(false);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const generateSizing = (type, multiplier) => {
    if (type === "Hero-Banner") {
      return `${300 * multiplier} length x ${1520 * multiplier} width pixels`;
    } else if (type === "Strip-Banner") {
      return `${100 * multiplier} length x ${1200 * multiplier} width pixels`;
    } else if (type === "Popout-Long") {
      return `${100 * multiplier} length x ${1520 * multiplier} width pixels`;
    } else if (type === "Popout-Rectangle") {
      return `${400 * multiplier} length x ${600 * multiplier} width pixels`;
    } else if (type === "Vertical-Banner") {
      return `${500 * multiplier} length x ${300 * multiplier} width pixels`;
    } else {
      return `${300 * multiplier} length x ${300 * multiplier} width pixels`;
    }
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEdit ? "Edit" : "Create"} Ad Banner`}
        visible={visible}
        // width={700}
        onOk={isEdit ? onUpdate : onSubmit}
        okButtonProps={{ disabled: !validateBannerForm(form) }}
        onCancel={onClose}
        okText={`${isEdit ? "Save" : "Submit"}`}
        confirmLoading={submitLoading}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="form-container">
              <p className="form-label">
                Banner Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.bannerName}
                onChange={(text) => handleFieldChange("bannerName", text)}
                placeholder="Please type Banner Name"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Link Url <span>*</span> :{" "}
              </p>
              <Input
                value={form.url}
                onChange={(text) => handleFieldChange("url", text)}
                placeholder="Please type link url"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Client Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.clientName}
                onChange={(text) => handleFieldChange("clientName", text)}
                placeholder="Please type Client Name"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Start Date <span>*</span> :{" "}
              </p>
              <Space>
                <div className="datepicker">
                  <DatePicker
                    style={{ width: "100%" }}
                    value={moment(form.startDate)}
                    onChange={(date, dateString) =>
                      onDateFieldChange("startDate", dateString)
                    }
                  />
                </div>
              </Space>
            </div>

            {visible ? (
              <div className="form-container">
                <p className="form-label">
                  End Date <span>*</span> :{" "}
                </p>
                <Space>
                  <div className="datepicker">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={moment(form.endDate)}
                      onChange={(date, dateString) =>
                        onDateFieldChange("endDate", dateString)
                      }
                    />
                  </div>
                </Space>
              </div>
            ) : null}

            <div className="form-container">
              <p className="form-label">
                Ads Location <span>*</span> :{" "}
              </p>
              <Select
                value={form.adsLocation}
                onChange={(value) => handleDropdownChange("adsLocation", value)}
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {adsLocations.map((el, i) => (
                  <Select.Option key={i} value={el.value}>
                    {`${el.label}`}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="form-container">
              <p className="form-label">
                Timer <span>*</span> :{" "}
              </p>
              <Input
                value={form.timer}
                onChange={(text) => handleFieldChange("timer", text)}
                placeholder="Timer"
              />
            </div>
          </Col>
          <Col span={24}>
            {form.bannerType !== "" ? (
              <div>
                <p style={{ margin: "5px 0x", color: colorScheme.red }}>
                  {`To avoid stretching of image in the website. The image should not exceed 2MB(megabyte) and has to be (.jpg , .png) with a minimum size and same ratio of :  ${generateSizing(
                    form.bannerType,
                    1
                  )} 
                                `}
                </p>
                <p
                  style={{
                    margin: "5px 0x",
                    color: colorScheme.green,
                    display: "flex",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>
                    Example & Valid Sizing :
                  </span>
                  <span>
                    {generateSizing(form.bannerType, 1.2)} <br />
                    {generateSizing(form.bannerType, 1.5)} <br />
                    {generateSizing(form.bannerType, 2)} <br />
                  </span>
                </p>
              </div>
            ) : (
              <p style={{ margin: "5px 0x", color: colorScheme.red }}>
                Kindly select a banner type to see image requirements
              </p>
            )}

            <div className="form-container">
              <p className="form-label">
                Banner Type <span>*</span> :{" "}
              </p>
              <Select
                showSearch
                value={form.bannerType || undefined}
                onChange={(value) => handleDropdownChange("bannerType", value)}
                placeholder="Search to Select"
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {bannerTypes.map((banner, i) => (
                  <Select.Option key={i} value={banner.value}>
                    {banner.label}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="form-container">
              <p className="form-label">
                Banner Image <span>*</span> :{" "}
              </p>
              <div className="upload-container" onClick={handleOpenGallery}>
                <FaUpload />
                <p className="banner-p">UPLOAD BANNER PHOTO</p>
              </div>

              <input
                ref={fileUploader}
                id="getFile"
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </div>

            <div className="image-preview-container">
              <img src={form.imageUrl} />
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default BannerForm;
