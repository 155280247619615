import React, { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoInformationCircleSharp } from "react-icons/io5";
import {
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import moment from "moment";
import { colorScheme } from "../../contants/variables";
import {
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import { addCoe, editCoe, fetchCoeById } from "../../stores/api_calls/coes";
import { error, success } from "../../contants/snackbars";
import Kjur from "../../stores/utils/jwt";
import { useLocation, useNavigate } from "react-router-dom";

const FieldSuffix = (props) => {
  const { TooltipData } = props;
  return (
    <Tooltip
      title={() => {
        return <TooltipData />;
      }}
      placement="bottomRight"
    >
      <IoInformationCircleSharp
        color={colorScheme.primary}
        style={{ fontSize: "20px" }}
      />
    </Tooltip>
  );
};

const CoeForm = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(loc.search);

  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState({
    biddingDate: moment(),
    coeData: [],
    id: null,
  });
  const [requestLoading, setRequestLoading] = useState(false);

  const categoryDefaultValue = {
    coe_category: "Category A",
    coe_label: "CARS ≤ 1600cc & 130bhp, or 110kW",
    quota_premium: "",
    change: "",
    prevailing_quota_premium: {
      value: "",
      date: moment().format("YYYY-MM-DD"),
    },
    quota: "",
    bids_received: "",
    price_indicator: "",
  };

  const [categoryA, setCategoryA] = useState({
    ...categoryDefaultValue,
    coe_category: "Category A",
    coe_label: "CARS ≤ 1,600cc & 130bhp, or 110kW",
  });

  const [categoryB, setCategoryB] = useState({
    ...categoryDefaultValue,
    coe_category: "Category B",
    coe_label: "CARS > 1,600cc or 130bhp, or 110kW",
  });

  const [categoryC, setCategoryC] = useState({
    ...categoryDefaultValue,
    coe_category: "Category C",
    coe_label: "GOODS VEHICLE & BUS",
  });

  const [categoryD, setCategoryD] = useState({
    ...categoryDefaultValue,
    coe_category: "Category D",
    coe_label: "MOTORCYCLE",
  });

  const [categoryE, setCategoryE] = useState({
    ...categoryDefaultValue,
    coe_category: "Category E",
    coe_label: "OPEN-ALL EXCEPT MOTOCYCLE",
  });

  const handleFieldChange = (name, e, category) => {
    if (!category) {
      setForm({ ...form, [name]: e.target.value });
      return;
    }
    if (category === "A") {
      if (name === "value" || name === "date") {
        setCategoryA({
          ...categoryA,
          prevailing_quota_premium: {
            ...categoryA.prevailing_quota_premium,
            [name]: name === "date" ? e : e.target.value,
          },
        });
        return;
      }

      setCategoryA({ ...categoryA, [name]: e.target.value });
    }

    if (category === "B") {
      if (name === "value" || name === "date") {
        setCategoryB({
          ...categoryB,
          prevailing_quota_premium: {
            ...categoryB.prevailing_quota_premium,
            [name]: name === "date" ? e : e.target.value,
          },
        });
        return;
      }

      setCategoryB({ ...categoryB, [name]: e.target.value });
    }

    if (category === "C") {
      if (name === "value" || name === "date") {
        setCategoryC({
          ...categoryC,
          prevailing_quota_premium: {
            ...categoryC.prevailing_quota_premium,
            [name]: name === "date" ? e : e.target.value,
          },
        });
        return;
      }

      setCategoryC({ ...categoryC, [name]: e.target.value });
    }

    if (category === "D") {
      if (name === "value" || name === "date") {
        setCategoryD({
          ...categoryD,
          prevailing_quota_premium: {
            ...categoryD.prevailing_quota_premium,
            [name]: name === "date" ? e : e.target.value,
          },
        });
        return;
      }

      setCategoryD({ ...categoryD, [name]: e.target.value });
    }

    if (category === "E") {
      if (name === "value" || name === "date") {
        setCategoryE({
          ...categoryE,
          prevailing_quota_premium: {
            ...categoryE.prevailing_quota_premium,
            [name]: name === "date" ? e : e.target.value,
          },
        });
        return;
      }

      setCategoryE({ ...categoryE, [name]: e.target.value });
    }
  };

  const handleDropdownChange = (name, value, category) => {
    if (category === "A") {
      setCategoryA({ ...categoryA, [name]: value });
    }
    if (category === "B") {
      setCategoryB({ ...categoryB, [name]: value });
    }
    if (category === "C") {
      setCategoryC({ ...categoryC, [name]: value });
    }
    if (category === "D") {
      setCategoryD({ ...categoryD, [name]: value });
    }
    if (category === "E") {
      setCategoryE({ ...categoryE, [name]: value });
    }
  };

  const handleSubmit = () => {
    setRequestLoading(true);
    const params = {
      bidding_date: moment(form.biddingDate).format("YYYY-MM-DD"),
      coe_data: [categoryA, categoryB, categoryC, categoryD, categoryE],
    };
    const createCoe = addCoe(params);
    createCoe
      .then((res) => {
        if (res.data.success) {
          success("COE Created Successfully");

          resetForm();
          setRequestLoading(false);
          navigate({
            pathname: "../coe",
          });
        }
      })
      .catch((e) => {
        error("Failed to Add COE");
        setRequestLoading(false);
      });
  };

  const handleUpdate = () => {
    setRequestLoading(true);
    const params = {
      id: form.id,
      bidding_date: moment(form.biddingDate).format("YYYY-MM-DD"),
      coe_data: [categoryA, categoryB, categoryC, categoryD, categoryE],
    };
    const updateCoe = editCoe(params);
    updateCoe
      .then((res) => {
        if (res.data.success) {
          success("COE Updated Successfully");
          resetForm();
          setRequestLoading(false);
          navigate({
            pathname: "../coe",
          });
        }
      })
      .catch((e) => {
        error(`Opps! Update failed. Please try again later`);
        setRequestLoading(false);
      });
  };

  const resetForm = () => {
    const emptyData = {
      quota_premium: "",
      change: "",
      prevailing_quota_premium: {
        value: "",
        date: moment().format("YYYY-MM-DD"),
      },
      quota: "",
      bids_received: "",
      price_indicator: "",
    };
    setCategoryA({
      ...categoryA,
      ...emptyData,
    });
    setCategoryB({
      ...categoryB,
      ...emptyData,
    });
    setCategoryC({
      ...categoryC,
      ...emptyData,
    });
    setCategoryD({
      ...categoryD,
      ...emptyData,
    });
    setCategoryE({
      ...categoryE,
      ...emptyData,
    });

    setForm({ ...form, id: null, biddingDate: moment() });
  };

  useEffect(() => {
    if (location.href.includes("/edit")) {
      fetchCoe();
      setIsEdit(true);
    }
  }, []);

  const fetchCoe = () => {
    const payload = { id: params.get("id") };
    const showCoe = fetchCoeById(payload);
    showCoe
      .then((res) => {
        if (res.data.success) {
          const parsedData = res.data.data;
          setForm({
            ...form,
            biddingDate: parsedData.bidding_date,
            id: parsedData.id,
          });
          setCategoryA(
            parsedData.coe_data.find(
              (item) => item.coe_category === "Category A"
            )
          );
          setCategoryB(
            parsedData.coe_data.find(
              (item) => item.coe_category === "Category B"
            )
          );
          setCategoryC(
            parsedData.coe_data.find(
              (item) => item.coe_category === "Category C"
            )
          );
          setCategoryD(
            parsedData.coe_data.find(
              (item) => item.coe_category === "Category D"
            )
          );
          setCategoryE(
            parsedData.coe_data.find(
              (item) => item.coe_category === "Category E"
            )
          );
        }
      })
      .catch((e) => {
        console.log(e);
        error("Failed to Add COE");
      });
  };
  return (
    <div className="rental-form-container">
      <div className={`table-title-icon ${!isEdit ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEdit ? "add" : "edit"}`}>
        {!isEdit ? "Add COE" : `Edit COE`}
      </p>

      <div className="field-container">
        <p className="field-label">
          Bidding Date <span>*</span>:{" "}
        </p>
        <Space>
          <div className="datepicker">
            <DatePicker
              format="DD-MMM-YYYY"
              style={{ width: "410px" }}
              value={moment(form.biddingDate)}
              onChange={(date, dateString) =>
                setForm({ ...form, biddingDate: dateString })
              }
            />
          </div>
        </Space>
      </div>

      <Divider />

      <div className="header-container">
        <p className="header">Category A</p>
        <Row gutter={[15, 15]}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota Premium <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryA.quota_premium}
                placeholder="Quota Premium"
                onChange={(text) =>
                  handleFieldChange("quota_premium", text, "A")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Change <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryA.change}
                placeholder="Change"
                onChange={(text) => handleFieldChange("change", text, "A")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Price Indicator <span>*</span> :{" "}
              </p>

              <Select
                value={categoryA.price_indicator}
                onChange={(value) =>
                  handleDropdownChange("price_indicator", value, "A")
                }
                style={{ width: "100%" }}
                placeholder="Select Indicator"
                optionFilterProp="children"
              >
                {[
                  { value: "up", label: "Up" },
                  { value: "down", label: "Down" },
                  { value: "neutral", label: "Neutral" },
                ].map((el, i) => (
                  <Select.Option key={i} value={el?.value}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          el.value === "up"
                            ? "red"
                            : el?.value === "down"
                            ? "green"
                            : "black",
                      }}
                    >{`${
                      el?.value === "up"
                        ? "↑"
                        : el?.value === "down"
                        ? "↓"
                        : "- "
                    } ${el?.label}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Value <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryA.prevailing_quota_premium.value}
                placeholder="Value"
                onChange={(text) => handleFieldChange("value", text, "A")}
                suffix={
                  <FieldSuffix
                    TooltipData={() => (
                      <p style={{ fontSize: "9px" }}>
                        Prevailing Quota Premium (PQP)
                        <br />
                        Amount to be paid for COE renewal. Computed from 3-month
                        moving avarage of COE prices.
                      </p>
                    )}
                  />
                }
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[15, 15]} style={{ marginTop: 16 }}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Date <span>*</span>:{" "}
              </p>

              <Space>
                <div className="datepicker">
                  <DatePicker
                    format="DD-MMM-YYYY"
                    style={{ width: "100%" }}
                    value={moment(categoryA.prevailing_quota_premium.date)}
                    onChange={(date, dateString) =>
                      handleFieldChange("date", dateString, "A")
                    }
                  />
                </div>
              </Space>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryA.quota}
                placeholder="Quota"
                onChange={(text) => handleFieldChange("quota", text, "A")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Bids Received <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryA.bids_received}
                placeholder="Bids Received"
                onChange={(text) =>
                  handleFieldChange("bids_received", text, "A")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Description <span>*</span> :{" "}
              </p>
              <Input
                value={categoryA.coe_label}
                placeholder="coe_label"
                onChange={(text) => handleFieldChange("coe_label", text, "A")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Divider />

      <div className="header-container">
        <p className="header">Category B</p>
        <Row gutter={[15, 15]}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota Premium <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryB.quota_premium}
                placeholder="Quota Premium"
                onChange={(text) =>
                  handleFieldChange("quota_premium", text, "B")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Change <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryB.change}
                placeholder="Change"
                onChange={(text) => handleFieldChange("change", text, "B")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Price Indicator <span>*</span> :{" "}
              </p>

              <Select
                value={categoryB.price_indicator}
                onChange={(value) =>
                  handleDropdownChange("price_indicator", value, "B")
                }
                style={{ width: "100%" }}
                placeholder="Select Indicator"
                optionFilterProp="children"
              >
                {[
                  { value: "up", label: "Up" },
                  { value: "down", label: "Down" },
                  { value: "neutral", label: "Neutral" },
                ].map((el, i) => (
                  <Select.Option key={i} value={el?.value}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          el.value === "up"
                            ? "red"
                            : el?.value === "down"
                            ? "green"
                            : "black",
                      }}
                    >{`${
                      el?.value === "up"
                        ? "↑"
                        : el?.value === "down"
                        ? "↓"
                        : "- "
                    } ${el?.label}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Value <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryB.prevailing_quota_premium.value}
                placeholder="Value"
                onChange={(text) => handleFieldChange("value", text, "B")}
                suffix={
                  <FieldSuffix
                    TooltipData={() => (
                      <p style={{ fontSize: "9px" }}>
                        Prevailing Quota Premium (PQP)
                        <br />
                        Amount to be paid for COE renewal. Computed from 3-month
                        moving avarage of COE prices.
                      </p>
                    )}
                  />
                }
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[15, 15]} style={{ marginTop: 16 }}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Date <span>*</span>:{" "}
              </p>

              <Space>
                <div className="datepicker">
                  <DatePicker
                    format="DD-MMM-YYYY"
                    style={{ width: "100%" }}
                    value={moment(categoryB.prevailing_quota_premium.date)}
                    onChange={(date, dateString) =>
                      handleFieldChange("date", dateString, "B")
                    }
                  />
                </div>
              </Space>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryB.quota}
                placeholder="Quota"
                onChange={(text) => handleFieldChange("quota", text, "B")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Bids Received <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryB.bids_received}
                placeholder="Bids Received"
                onChange={(text) =>
                  handleFieldChange("bids_received", text, "B")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Description <span>*</span> :{" "}
              </p>
              <Input
                value={categoryB.coe_label}
                placeholder="coe_label"
                onChange={(text) => handleFieldChange("coe_label", text, "B")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Divider />

      <div className="header-container">
        <p className="header">Category C</p>
        <Row gutter={[15, 15]}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota Premium <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryC.quota_premium}
                placeholder="Quota Premium"
                onChange={(text) =>
                  handleFieldChange("quota_premium", text, "C")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Change <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryC.change}
                placeholder="Change"
                onChange={(text) => handleFieldChange("change", text, "C")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Price Indicator <span>*</span> :{" "}
              </p>

              <Select
                value={categoryC.price_indicator}
                onChange={(value) =>
                  handleDropdownChange("price_indicator", value, "C")
                }
                style={{ width: "100%" }}
                placeholder="Select Indicator"
                optionFilterProp="children"
              >
                {[
                  { value: "up", label: "Up" },
                  { value: "down", label: "Down" },
                  { value: "neutral", label: "Neutral" },
                ].map((el, i) => (
                  <Select.Option key={i} value={el?.value}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          el.value === "up"
                            ? "red"
                            : el?.value === "down"
                            ? "green"
                            : "black",
                      }}
                    >{`${
                      el?.value === "up"
                        ? "↑"
                        : el?.value === "down"
                        ? "↓"
                        : "- "
                    } ${el?.label}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Value <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryC.prevailing_quota_premium.value}
                placeholder="Value"
                onChange={(text) => handleFieldChange("value", text, "C")}
                suffix={
                  <FieldSuffix
                    TooltipData={() => (
                      <p style={{ fontSize: "9px" }}>
                        Prevailing Quota Premium (PQP)
                        <br />
                        Amount to be paid for COE renewal. Computed from 3-month
                        moving avarage of COE prices.
                      </p>
                    )}
                  />
                }
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[15, 15]} style={{ marginTop: 16 }}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Date <span>*</span>:{" "}
              </p>

              <Space>
                <div className="datepicker">
                  <DatePicker
                    format="DD-MMM-YYYY"
                    style={{ width: "100%" }}
                    value={moment(categoryC.prevailing_quota_premium.date)}
                    onChange={(date, dateString) =>
                      handleFieldChange("date", dateString, "C")
                    }
                  />
                </div>
              </Space>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryC.quota}
                placeholder="Quota"
                onChange={(text) => handleFieldChange("quota", text, "C")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Bids Received <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryC.bids_received}
                placeholder="Bids Received"
                onChange={(text) =>
                  handleFieldChange("bids_received", text, "C")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Description <span>*</span> :{" "}
              </p>
              <Input
                value={categoryC.coe_label}
                placeholder="coe_label"
                onChange={(text) => handleFieldChange("coe_label", text, "C")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Divider />

      <div className="header-container">
        <p className="header">Category D</p>
        <Row gutter={[15, 15]}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota Premium <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryD.quota_premium}
                placeholder="Quota Premium"
                onChange={(text) =>
                  handleFieldChange("quota_premium", text, "D")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Change <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryD.change}
                placeholder="Change"
                onChange={(text) => handleFieldChange("change", text, "D")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Price Indicator <span>*</span> :{" "}
              </p>

              <Select
                value={categoryD.price_indicator}
                onChange={(value) =>
                  handleDropdownChange("price_indicator", value, "D")
                }
                style={{ width: "100%" }}
                placeholder="Select Indicator"
                optionFilterProp="children"
              >
                {[
                  { value: "up", label: "Up" },
                  { value: "down", label: "Down" },
                  { value: "neutral", label: "Neutral" },
                ].map((el, i) => (
                  <Select.Option key={i} value={el?.value}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          el.value === "up"
                            ? "red"
                            : el?.value === "down"
                            ? "green"
                            : "black",
                      }}
                    >{`${
                      el?.value === "up"
                        ? "↑"
                        : el?.value === "down"
                        ? "↓"
                        : "- "
                    } ${el?.label}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Value <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryD.prevailing_quota_premium.value}
                placeholder="Value"
                onChange={(text) => handleFieldChange("value", text, "D")}
                suffix={
                  <FieldSuffix
                    TooltipData={() => (
                      <p style={{ fontSize: "9px" }}>
                        Prevailing Quota Premium (PQP)
                        <br />
                        Amount to be paid for COE renewal. Computed from 3-month
                        moving avarage of COE prices.
                      </p>
                    )}
                  />
                }
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[15, 15]} style={{ marginTop: 16 }}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Date <span>*</span>:{" "}
              </p>

              <Space>
                <div className="datepicker">
                  <DatePicker
                    format="DD-MMM-YYYY"
                    style={{ width: "100%" }}
                    value={moment(categoryD.prevailing_quota_premium.date)}
                    onChange={(date, dateString) =>
                      handleFieldChange("date", dateString, "D")
                    }
                  />
                </div>
              </Space>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryD.quota}
                placeholder="Quota"
                onChange={(text) => handleFieldChange("quota", text, "D")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Bids Received <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryD.bids_received}
                placeholder="Bids Received"
                onChange={(text) =>
                  handleFieldChange("bids_received", text, "D")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Description <span>*</span> :{" "}
              </p>
              <Input
                value={categoryD.coe_label}
                placeholder="coe_label"
                onChange={(text) => handleFieldChange("coe_label", text, "D")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Divider />

      <div className="header-container">
        <p className="header">Category E</p>
        <Row gutter={[15, 15]}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota Premium <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryE.quota_premium}
                placeholder="Quota Premium"
                onChange={(text) =>
                  handleFieldChange("quota_premium", text, "E")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Change <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryE.change}
                placeholder="Change"
                onChange={(text) => handleFieldChange("change", text, "E")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Price Indicator <span>*</span> :{" "}
              </p>

              <Select
                value={categoryE.price_indicator}
                onChange={(value) =>
                  handleDropdownChange("price_indicator", value, "E")
                }
                style={{ width: "100%" }}
                placeholder="Select Indicator"
                optionFilterProp="children"
              >
                {[
                  { value: "up", label: "Up" },
                  { value: "down", label: "Down" },
                  { value: "neutral", label: "Neutral" },
                ].map((el, i) => (
                  <Select.Option key={i} value={el?.value}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          el.value === "up"
                            ? "red"
                            : el?.value === "down"
                            ? "green"
                            : "black",
                      }}
                    >{`${
                      el?.value === "up"
                        ? "↑"
                        : el?.value === "down"
                        ? "↓"
                        : "- "
                    } ${el?.label}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Value <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryE.prevailing_quota_premium.value}
                placeholder="Value"
                onChange={(text) => handleFieldChange("value", text, "E")}
                suffix={
                  <FieldSuffix
                    TooltipData={() => (
                      <p style={{ fontSize: "9px" }}>
                        Prevailing Quota Premium (PQP)
                        <br />
                        Amount to be paid for COE renewal. Computed from 3-month
                        moving avarage of COE prices.
                      </p>
                    )}
                  />
                }
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[15, 15]} style={{ marginTop: 16 }}>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                PQP Date <span>*</span>:{" "}
              </p>

              <Space>
                <div className="datepicker">
                  <DatePicker
                    format="DD-MMM-YYYY"
                    style={{ width: "100%" }}
                    value={moment(categoryE.prevailing_quota_premium.date)}
                    onChange={(date, dateString) =>
                      handleFieldChange("date", dateString, "E")
                    }
                  />
                </div>
              </Space>
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Quota <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryE.quota}
                placeholder="Quota"
                onChange={(text) => handleFieldChange("quota", text, "E")}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Bids Received <span>*</span> :{" "}
              </p>
              <Input
                type="number"
                value={categoryE.bids_received}
                placeholder="Bids Received"
                onChange={(text) =>
                  handleFieldChange("bids_received", text, "E")
                }
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="field-container">
              <p className="field-label">
                Description <span>*</span> :{" "}
              </p>
              <Input
                value={categoryE.coe_label}
                placeholder="coe_label"
                onChange={(text) => handleFieldChange("coe_label", text, "E")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Divider />
      <div className="button-container">
        <div className="button">
          {requestLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton
              color={colorScheme.primary}
              label={isEdit ? "Update Coe" : "Add Coe"}
              onClick={isEdit ? handleUpdate : handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CoeForm;
