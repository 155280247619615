import { Col, Input, Row, Select } from 'antd'
import React from 'react'
import { rentalTypes, rentalMinMonth } from '../../contants/variables'
import './rentals.scss'

const RentalTypes = (props) => {

    const {form, handleDropdownChange , handleFieldChange} = props

    return (
        <Row gutter={[15,15]}>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Rental Type <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.rentalType || undefined}
                        onChange={(value)=>handleDropdownChange('rentalType' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select."
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {rentalTypes.map((rental,i)=>
                            <Select.Option key={i}  value={rental.value}>{rental.label}</Select.Option>
                        )}
                    </Select>
                </div>
            </Col>
            {form.rentalType==='short_term' || form.rentalType==='all'?
                <>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Daily Pricing  : </p>
                            <Input
                                value={form.shortDaily}
                                onChange={text=>handleFieldChange('shortDaily' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Weekends Pricing  : </p>
                            <Input
                                value={form.shortWeekend}
                                onChange={text=>handleFieldChange('shortWeekend' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Weekly Pricing  : </p>
                            <Input
                                value={form.shortWeekly}
                                onChange={text=>handleFieldChange('shortWeekly' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Monthly Pricing  : </p>
                            <Input
                                value={form.shortMonthly}
                                onChange={text=>handleFieldChange('shortMonthly' , text)}
                            />
                        </div>
                    </Col>
                </>
            :null}

            {form.rentalType==='long_term' || form.rentalType==='all'?
                <>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>1 Year Lease  : </p>
                            <Input
                                value={form.long1Yr}
                                onChange={text=>handleFieldChange('long1Yr' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>2 Years Lease  : </p>
                            <Input
                                value={form.long2Yr}
                                onChange={text=>handleFieldChange('long2Yr' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>3 Years Lease  : </p>
                            <Input
                                value={form.long3Yr}
                                onChange={text=>handleFieldChange('long3Yr' , text)}
                            />
                        </div>
                    </Col>
                    {/* <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>4 Years Lease  : </p>
                            <Input
                                value={form.long4Yr}
                                onChange={text=>handleFieldChange('long4Yr' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>5 Years Lease  : </p>
                            <Input
                                value={form.long5Yr}
                                onChange={text=>handleFieldChange('long5Yr' , text)}
                            />
                        </div>
                    </Col> */}
                </>
            :null}

            {form.rentalType==='private_hire' || form.rentalType==='all'?
                <>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Weekly Pricing  : </p>
                            <Input
                                value={form.hireWeekly}
                                onChange={text=>handleFieldChange('hireWeekly' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Minimum Months  : </p>
                            <Select
                                showSearch
                                value = {form.hireMinMonths || undefined}
                                onChange={(value)=>handleDropdownChange('hireMinMonths' , value)}
                                style={{ width: '100%' }}
                                placeholder="Search to Select."
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {rentalMinMonth.map((exp,i)=>
                                    <Select.Option key={i}  value={exp}>{exp}</Select.Option>
                                )}
                            </Select>
                            {/* <Input
                                value={form.hireMinMonths}
                                onChange={text=>handleFieldChange('hireMinMonths' , text)}
                            /> */}
                        </div>
                    </Col>
                </>
            :null}

        {form.rentalType==='wedding_car'|| form.rentalType==='all'?
                <>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>6 Hours Pricing  : </p>
                            <Input
                                value={form.wedding6hr}
                                onChange={text=>handleFieldChange('wedding6hr' , text)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-fields'>
                            <p className='form-field-label'>12 Hours Pricing  : </p>
                            <Input
                                value={form.wedding12hr}
                                onChange={text=>handleFieldChange('wedding12hr' , text)}
                            />
                        </div>
                    </Col>
                </>
            :null}
        </Row>
    )
}

export default RentalTypes