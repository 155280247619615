class Validator {
    constructor(value = '') {
      this.value = value.trim();
      this.error = '';
    }
  
    Required() {
      if (this.value === '') {
        if(this.error === '') {
            this.error = 'This field is required.';
        }
      }
      return this;
    }
  
    Max(length) {
      if (this.value.length > length) {
        if(this.error === '') {
            this.error = `Value exceeds maximum length of ${length}.`;
        }
      }
      return this;
      
    }
  
    Email() {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;;
      if (!emailRegex.test(this.value)) {
        if(this.error === '') {
            this.error = `Invalid email address.`;
        }
      }
      return this;
    }
  
    Min(length) {
      if (this.value.length < length) {
        if(this.error === '') {
            this.error = `Value is less than minimum length of ${length}.`;
        }
      }
      return this;
    }

    SingaporeMobileNumber() {
        const regex = /^[89]\d{7}$/;
        if (!regex.test(this.value)) {
            if(this.error === '') {
                this.error = `Invalid phone number.`;
            }
        }
        return this;
    }
  
    IsNumber() {
      if (isNaN(this.value)) {
        if(this.error === '') {
            this.error = 'Value is not a number.';
        }
      }
      return this;
    }

  }

  export default Validator;