import { Tabs } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import CarDetails from "./CarDetails";
import "./carform.scss";
import moment from "moment";
import {
  fetchBrands,
  fetchCarDetails,
  fetchCities,
  fetchModels,
  fetchPackages,
} from "../../stores/api_calls/carlists";
import UploadCarImages from "./UploadCarImages";
import PreviewCar from "./PreviewCar";
import { useLocation } from "react-router-dom";
import Kjur from "../../stores/utils/jwt";
import {
  validateProductForm,
  validateUpload,
} from "../../stores/helpers/productFormValidation";
import {
  fuelTypes,
  ownerIdTypes,
  vehicleTypes,
} from "../../contants/variables";
import { fetchUsers } from "../../stores/api_calls/users";
import { AccessContext } from "../../stores/contexts/accessContext";

const { TabPane } = Tabs;

const CarFormIndex = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isEdit, setIsEdit] = useState(false);
  // const [roles,permissions] = useContext(AccessContext)
  const [cities, setCities] = useState({ loading: false, data: [] });
  const [brands, setBrands] = useState({ loading: false, data: [] });
  const [dealers, setDealers] = useState({ loading: false, data: [] });
  const [packages, setPackages] = useState([]);
  const [models, setModels] = useState({ loading: false, data: [] });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [urlsHolder, setUrlsHolder] = useState([]);
  const [form, setForm] = useState({
    dealerId: ``,
    dealerMobileNumber: "",
    dealerEmail: "",
    direct_seller: false,
    displayMobile: 0,
    enableInquiry: 0,
    packageId: "",
    plateNumber: "",
    ownerIdType: "",
    ownerId: "",
    isManualInput: 0,
    registrationDate: moment(),
    engineCap: "",
    omv: "",
    arf: "",
    coe: "",
    coeExpiryDate: moment(),
    numberOfOwners: "",
    vehicleType: "",
    carBrand: "",
    carModel: "",
    askingPrice: "",
    manufacturedYear: "",
    cityId: "",
    cityName: "",
    regionName: "",
    address: "",
    isOffPeak: false,
    offPeakOption: 0,
    transmission: "",
    fuelType: "",
    mileage: "",
    features: "",
    accessories: "",
    description: "",
    carName: "",
    condition: "",
    isRental: false,
    vehicleNumber: "",
    vehicleUnitNumber: "",
    vehicleReferenceNumber: "",
    vehicleRegistrationCardNumber: "",
    imageUrls: [],
    deregValue: "",
    depreciationValue: "",
    role: "",
    curbWeight: "",
    roadTax: "",
    power: "",
    dealerFirstName: "",
    dealerLastName: "",
    color: "",
    youtubeVideo: "",
    tiktokVideo: "",
    isCommercialVehicle: "",
    isVerified: false,
    isSendEmail: false,
  });
  const [enableTab, setEnableTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (form) {
      const value = validateProductForm(form);
      if (value) {
        setEnableTab(1);
      } else {
        setEnableTab(0);
      }
    }
  }, [form]);

  useEffect(() => {
    if ((selectedFiles || form.imageUrls) && enableTab === 1) {
      const value = validateUpload(selectedFiles, form.imageUrls);
      if (value) {
        setEnableTab(2);
      } else {
        setEnableTab(1);
      }
    }
  }, [selectedFiles, form.imageUrls, enableTab]);

  useEffect(() => {
    getCities();
    getBrands();
    getPackages();
    getDealers();

    if (location.pathname.includes("/edit")) {
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      getCarDetails();
    }
  }, [isEdit]);

  useEffect(() => {
    if (form.carBrand && !isEdit) {
      setModels({ ...models, loading: true, data: [] });
      setForm({ ...form, carModel: "" });
      getModels();
    } else if (form.carBrand && isEdit) {
      getModels();
    }
  }, [form.carBrand]);

  useEffect(() => {
    if (!form.isOffPeak) {
      setForm({ ...form, offPeakOption: 0 });
    }
  }, [form.isOffPeak]);

  useEffect(() => {
    if (form.cityId && isLoading) {
      cities.data.forEach((city) => {
        if (city._id === form.cityId) {
          setForm({
            ...form,
            cityName: city.city,
            regionName: city.region_name,
          });
        }
      });
    }
  }, [form.cityId, isLoading]);

  useEffect(() => {
    if (cities.data.length && isEdit) {
      cities.data.forEach((city) => {
        if (
          city.city === form.cityName
        ) {
          // console.log(city._id);
          setForm({ ...form, cityId: city._id });
        }
      });
    }
  }, [cities.data, isLoading]);

  // console.log(dealers)

  useEffect(() => {
    if (form.dealerId) {
      dealers.data.forEach((dealer) => {
        if (dealer._id === form.dealerId) {
          setForm({
            ...form,
            dealerMobileNumber: dealer.user_contact_numbers
              ?.filter((el) => el.is_active === true)
              .map((el) => el.number),
            dealerEmail: dealer.user_contact_emails
              ?.filter((el) => el.is_active === true)
              .map((el) => el.email),
            role: dealer.user_role,
          });
        }
      });
    }
  }, [form.dealerId]);

  const getCarDetails = async () => {
    setIsLoading(true)
    const onGetCarDetails = fetchCarDetails(params.get("id"));
    onGetCarDetails
      .then((res) => {
        if (res.data.success) {
          const parsedData = res.data.data;
          console.log('product');
          console.log(parsedData);
          setForm({
            ...form,
            id: parsedData._id,
            packageId: parsedData.selected_ads_id,
            plateNumber: parsedData.car_plate_number,
            dealerId: parsedData.advertisement_contact_details?._id ?? "",
            role:
              parsedData.advertisement_contact_details?.role_name ?? "Dealer",
            dealerEmail:
              parsedData.advertisement_contact_details?.user_contact_emails
                ?.email,
            dealerMobileNumber:
              parsedData.advertisement_contact_details?.user_contact_numbers
                ?.number,
            fullname: parsedData.advertisement_contact_details?.name,
            direct_seller: parsedData.advertisement_contact_details?.direct_seller,
            ownerIdType: parsedData.owner_type,
            ownerId: parsedData.owner_id,
            registrationDate:
              parsedData.registration_date === "N/A" ||
              !parsedData.registration_date
                ? null
                : parsedData.registration_date,
            engineCap: parsedData.product_cc,
            omv: parsedData.omv,
            arf: parsedData.arf,
            coe: parsedData.coe,
            coeExpiryDate: parsedData.coe_expiry_date,
            numberOfOwners: parsedData.number_of_owners,
            vehicleType: parsedData.vehicle_type,
            carBrand: parsedData.product_brand?._id ?? "",
            carModel: parsedData.product_brand_model?._id ?? "",
            askingPrice: parsedData.product_price,
            manufacturedYear: parsedData.product_edition,
            cityName: parsedData?.product_pickup_location?.city,
            regionName: parsedData?.product_pickup_location?.region_name,
            address: parsedData?.product_pickup_location?.street,
            isOffPeak: parsedData.is_off_peak_car,
            transmission: parsedData.product_transmission,
            fuelType: parsedData.fuel_type,
            mileage: parsedData.product_mileage,
            features: parsedData.vehicle_features,
            accessories: parsedData.accessories,
            description: parsedData.product_description,
            carName: parsedData.product_name,
            condition: parsedData.product_condition,
            isRental: parsedData.product_is_rental,
            vehicleNumber: parsedData.product_vehicle_number,
            vehicleUnitNumber: parsedData.product_vehicle_unit_number,
            vehicleReferenceNumber: parsedData.product_reference_number,
            vehicleRegistrationCardNumber:
              parsedData.product_vehicle_registration_card_number,
            imageUrls: parsedData.product_image_urls,
            deregValue: parsedData.dereg_value,
            depreciationValue: parsedData.depreciation_value,
            curbWeight: parsedData.curb_weight,
            roadTax: parsedData.road_tax,
            power: parsedData.power,
            color: parsedData.color,
            youtubeVideo: parsedData.youtube_video,
            tiktokVideo: parsedData.tiktok_video,
            isCommercialVehicle: parsedData.is_commercial_vehicle,
            // isVerified: parsedData.is_verified,
            // isSendEmail: parsedData.is_send_email ?? false,
          });
          setIsLoading(false)
        }
      })
      .catch((e) => console.log(e));
  };

  const getDealers = () => {
    setDealers({ ...dealers, loading: true });
    const pagination = {
      page: 1,
      limit: 100000,
      keyword: "",
      // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
      roleIds: ["619e5d55e7040000a2007b08", "619e5d67e7040000a2007b09"],
      isVerified: true,
    };

    const users = fetchUsers(pagination);
    users
      .then((res) => {
        if (res.data.success) {
          setDealers({ ...dealers, loading: false, data: res.data.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCities = () => {
    setCities({ ...cities, loading: true });
    const onGetCities = fetchCities();
    onGetCities
      .then((res) => {
        if (res.data.success) {
          setCities({ ...cities, loading: false, data: res.data.data });
          if (isEdit) {
            res.data.data.forEach((city) => {
              if (
                city.city === form.cityName
              ) {
                setForm({ ...form, cityId: city._id });
              }
            });
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getBrands = () => {
    const pagination = {
      page: 1,
      limit: 10000,
    };
    setBrands({ ...brands, loading: true });
    const onGetBrands = fetchBrands(pagination);
    onGetBrands
      .then((res) => {
        if (res.data.success) {
          setBrands({ ...brands, loading: false, data: res.data.data });
        }
      })
      .catch((e) => console.log(e));
  };

  const getModels = () => {
    const onGetModels = fetchModels(form.carBrand);
    onGetModels
      .then((res) => {
        if (res.data.success) {
          setModels({ ...models, loading: false, data: res.data.data });
        }
      })
      .catch((e) => console.log(e));
  };

  const getPackages = () => {
    const packages = fetchPackages();
    packages
      .then((res) => {
        if (res.data.success) {
          setPackages(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="form-table-container">
      <div className={`table-title-icon ${!isEdit ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEdit ? "add" : "edit"}`}>
        {!isEdit ? "Create Car Ad" : `Edit Car Ad`}
      </p>

      <div className="form-body-container">
        <Tabs size="large">
          <TabPane tab="Car Details Form" key="1">
            <CarDetails
              form={form}
              setForm={setForm}
              cities={cities}
              brands={brands}
              models={models}
              dealers={dealers}
              packages={packages}
              isEdit={isEdit}
              vehicleTypes={vehicleTypes}
              fuelTypes={fuelTypes}
              ownerIdTypes={ownerIdTypes}
            />
          </TabPane>
          <TabPane tab="Upload Images" key="2" disabled={enableTab < 1}>
            <UploadCarImages
              isEdit={isEdit}
              form={form}
              setForm={setForm}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              urlsHolder={urlsHolder}
              setUrlsHolder={setUrlsHolder}
            />
          </TabPane>
          <TabPane tab="Car Ad Preview" key="3" disabled={enableTab < 2}>
            <PreviewCar
              isEdit={isEdit}
              form={form}
              selectedFiles={selectedFiles}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default CarFormIndex;
