import { Modal } from 'antd'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment'
import {MdShare} from 'react-icons/md'
import './custom.scss'

const ArticlePreviewModal = (props) => {
    const {visible , onCancel , form , editorContent}=props

    console.log(editorContent)

    return (
        <Modal
            title="Article Preview"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className='preview-modal article'
            width={700}
        >
            <div className='view-article-container'>
                <div className='article-header'>
                    <div className='article-thumbnail'>
                        <img src={form.articleThumbnail}/>
                    </div>
                    <p className='article-title'>{form.articleTitle}</p>
                    <div className='article-info'>
                        <p>
                            <span className='section'>{form.articleSection}</span> | by
                            <span className='author'> {form.articleAuthor}</span> | 
                            <span className='date'> {moment().format('LL')}</span>
                        </p>
                        <div className='article-share'>
                            <MdShare/>
                            <p>Share this article</p>
                        </div>
                    </div>
                </div>
                
                <Editor editorState={editorContent} readOnly toolbar={{options:[]}}/>

                <div className='article-tags'>
                    <p>Article Tags</p>
                    {form.articleTagsArray?.map((tag,idx)=>
                        <div key={idx} className='tag-container'>
                            <p>{tag}</p>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ArticlePreviewModal
