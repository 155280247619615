import React , {useState , useEffect} from 'react'
import { Table , Input, Spin, Select} from 'antd';
import './ad.scss'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment'
import {FaBuysellads} from 'react-icons/fa'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle, MdOutlineFilterAlt} from 'react-icons/md';
import AdForm from '../forms/banners/AdForm';
import { colorScheme, pageTypes, bannerTypes, bannerStatusTypes } from '../contants/variables';
import { deleteBanner, fetchBanners } from '../stores/api_calls/bannerlists';
import { error, success } from '../contants/snackbars';
import BannerPreviewModal from '../custom_components/bannerPreviewModal';


const LandingAdLists = () => {

    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState([])
    const [isEdit , setIsEdit] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({showDelete:false , showForm:false , showPreview:false ,  data:{}});
    const [pageType, setPageType] = useState(null);
    const [bannerType, setBannerType] = useState(null);
    const [bannerStatusType, setBannerStatusType] = useState(null);
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const dateNow = moment();

    const columns = [
        {
            title: 'Banner Name',
            dataIndex: 'bannerName',
            // sorter: true,
            render: (text ,record) => `${record.name}`,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            render: (text ,record) => `${record.client_name}`,
        },
        {
            title: 'Banner Type',
            dataIndex: 'bannerType',
            render: (text ,record) => `${record.banner_type}`,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            render: (text ,record) => moment(record.start_date).format("YYYY-MM-DD"),
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            render: (text ,record) => moment.utc(record.end_date).local().format("YYYY-MM-DD"),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text ,record) => (moment(record.end_date) - dateNow) > 0 ? 
            <span 
            style={{backgroundColor:"lightgreen",padding:"3px 5px", borderRadius:5}}
            >Active</span> : 
            <span
            style={{backgroundColor:"red",padding:"3px 5px", color:"#ffffff", borderRadius:5}}
            >Expired</span>,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
            render: (text ,record) => `${record.created_by}`,
        },
        {
            title: 'Updated By',
            dataIndex: 'updateddBy',
            render: (text ,record) => `${record.updated_by??"Not Yet Edited"}`,
        },
        {
            title:'Actions',
            dataIndex:'actions',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button view' tootTipTitle="View Banner" Icon={<RiEye2Line/>} onClick={()=>onPreview(record.banner_image_urls)}/>
                    <IconButton className='icon-button edit' tootTipTitle="Edit Banner" Icon={<RiEdit2Fill/>} onClick={()=>onOpenForm('edit' , record)}/>
                    <IconButton className='icon-button delete' tootTipTitle="Delete Banner" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize, bannerType, pageType, bannerStatusType])

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch,
            banner_type: bannerType,
            page_type: pageType,
            status: bannerStatusType
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const banners = fetchBanners(data)

        banners.then((res)=>{
            if(res.data.success){
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const removeBanner = deleteBanner(showModal.data.id)
        removeBanner.then((res)=>{
            if(res.data.success){
                success("Banner Deleted Successfully")
                setShowModal({...showModal , showDelete:false , data:{}})
                refetchLists()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onOpenForm = (action , record) => {
        if(action==="add"){
            setIsEdit(false)
            setShowModal({...showModal , showForm:true})
        }else{
            setIsEdit(true)
            setShowModal({...showModal , showForm:true , data:record})
        }
    }

    const onPreview = (image) => {
        setShowModal({...showModal , showPreview:true , data :{url:image.metadata.image_url}})
    }

    const onSubmitForm = () => {
        setShowModal({...showModal , showForm:false , data:{}})
    }

    return (
        <div className='banner-table-container'>
            <div className='table-title-icon primary'>
                <FaBuysellads/>
            </div>
            <p className='table-title-text primary'>Banner Ad Lists</p>

            <div
                className='search-container'
            >
                <Select
                  value={pageType}
                  onChange={(value) => setPageType(value)}
                  style={{ width: "100%" }}
                  placeholder="Select page type"
                  optionFilterProp="children"
                  optionHeight={40}
                  optionLineHeight={40}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {pageTypes.map((el, i) => (
                    <Select.Option key={i} value={el.value}>
                      {`${el.label}`}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  value={bannerType}
                  onChange={(value) => setBannerType(value)}
                  style={{ width: "100%" }}
                  placeholder="Select banner type"
                  optionFilterProp="children"
                  optionHeight={40}
                  optionLineHeight={40}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {bannerTypes.map((el, i) => (
                    <Select.Option key={i} value={el.value}>
                      {`${el.label}`}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  value={bannerStatusType}
                  onChange={(value) => setBannerStatusType(value)}
                  style={{ width: "100%" }}
                  placeholder="Select status"
                  optionFilterProp="children"
                  optionHeight={40}
                  optionLineHeight={40}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {bannerStatusTypes.map((el, i) => (
                    <Select.Option key={i} value={el.value}>
                      {`${el.label}`}
                    </Select.Option>
                  ))}
                </Select>
                <Input
                    style={{width:300, marginLeft: 10}}
                    size='large'
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
                <div className='add-button-container'>
                    <PrimaryButton Icon={<MdAddCircle/>} color={colorScheme.primary} label="Create Ad Banner" onClick={()=>onOpenForm('add')}/>
                </div>
                
            </div>
            
            <div className='table-div'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>
            
            {
                showModal.showForm &&
                <AdForm
                    isEdit={isEdit}
                    visible={showModal.showForm}
                    onSubmit={onSubmitForm}
                    onCancel={()=>{
                        setShowModal({...showModal , showForm:false , data:{}})
                        setIsEdit(false)
                    }}
                    data={showModal.data}
                    fetchData={fetchData}
                    tablePagination={tablePagination}
                />
            }
            
            {
                showModal.showPreview &&
                <BannerPreviewModal
                    visible={showModal.showPreview}
                    onCancel={()=>setShowModal({...showModal , showPreview:false , data:{}})}
                    imageUrl={showModal.data.url}
                />
            }
            
            <CustomDeleteModal
                title="Delete Banner Ad"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.name}` , type:"banner ad"}}
            />

        </div>
    )
}

export default LandingAdLists
