import { Modal , Row , Col , Divider, Carousel } from 'antd'
import React , {useRef , useState} from 'react'
import './custom.scss'
import {MdOutlineArrowBackIos , MdOutlineArrowForwardIos} from 'react-icons/md';
import {FaCalendarDay , FaTachometerAlt , FaCarCrash ,FaCar, FaUsers} from 'react-icons/fa';
import {GiGearStickPattern , GiGears} from 'react-icons/gi';
import {IoMdPin} from'react-icons/io';
import NoImage from './noImage';

const CustomProductView = (props) => {

    const {data , title , onCancel , visible} = props
    // const [slide, setSlide] = useState(0);
    const slider = useRef();

    const next = () =>{
        slider.current.next()
    }

    const prev = () =>{
        slider.current.prev()
    }


    return (
        <Modal
            title={title}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className='preview-modal'
        >
            {!Object.entries(data).length? null
            :<div className='product-container'>
                <div className='image-container'>
                    {!data.product_image_urls.length?
                        <NoImage/>
                        :
                        <Carousel
                            ref={ref => {
                                slider.current = ref;
                            }}
                        >
                            {data.product_image_urls.map((image , i)=>
                                <img key={i} src={image.metadata.image_url}/>
                            )}
                        </Carousel>
                    }
                </div>

                <div className='header-name-container'>
                    <div>
                        <p className='type'>{data?.vehicle_type}</p>
                        <p className='name'>{data?.product_name}</p>
                    </div>
                    <div>
                        <div className='slider-icon-container'>
                            <div className='slider-icon' onClick={prev}>
                                <MdOutlineArrowBackIos/>
                            </div>
                            <div className='slider-icon' onClick={next}>
                                <MdOutlineArrowForwardIos/>
                            </div>
                        </div>

                        <p className='price'>S$ {data?.product_price?.toLocaleString()}</p>
                    </div>
                </div>

                <div className='overview'>
                    <p className='header-section'>OVERVIEW</p>
                    <p>{data?.product_description}</p>
                </div>

                <Divider/>

                <div className='overview'>
                    <p className='header-section'>TECHNICAL SPECIFICATION</p>
                    <Row gutter={[10,10]}>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <FaCalendarDay/>
                                <div>
                                    <p className='tech-spec-label'>MFR. DATE</p>
                                    <p className='tech-spec-value'>{data?.product_edition}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <FaTachometerAlt/>
                                <div>
                                    <p className='tech-spec-label'>MILEAGE</p>
                                    <p className='tech-spec-value'>{data?.product_mileage}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <GiGearStickPattern/>
                                <div>
                                    <p className='tech-spec-label'>TRANSMISSION</p>
                                    <p className='tech-spec-value'>{data?.product_transmission}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <GiGears/>
                                <div>
                                    <p className='tech-spec-label'>ENGINE CAP</p>
                                    <p className='tech-spec-value'>{data?.product_cc}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <FaCar/>
                                <div>
                                    <p className='tech-spec-label'>BODY TYPE</p>
                                    <p className='tech-spec-value'>{data?.vehicle_type}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <FaCarCrash/>
                                <div>
                                    <p className='tech-spec-label'>CONDITION</p>
                                    <p className='tech-spec-value'>{data?.product_condition}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div className='tech-specs-container'>
                                <IoMdPin/>
                                <div>
                                    <p className='tech-spec-label'>LOCATION</p>
                                    <p className='tech-spec-value'>{data?.product_pickup_location?.city} , {data?.product_pickup_location?.region_name}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='tech-specs-container'>
                                <FaUsers/>
                                <div>
                                    <p className='tech-spec-label'>OWNERS</p>
                                    <p className='tech-spec-value'>{data?.number_of_owners}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='spec-container'>
                                <div className='price-icon'><p>COE</p></div>
                                <p className='price-value'>S$ {data?.coe.toLocaleString()}</p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='spec-container'>
                                <div className='price-icon'><p>ARF</p></div>
                                <p className='price-value'>S$ {data?.arf?.toLocaleString()}</p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='spec-container'>
                                <div className='price-icon'><p>OMV</p></div>
                                <p className='price-value'>S$ {data?.omv?.toLocaleString()}</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider/>

                <div className='overview'>
                    <p className='header-section'>FEATURES</p>
                    <p>{data?.vehicle_features}</p>
                </div>

                <Divider/>

                <div className='overview'>
                    <p className='header-section'>ACCESSORIES</p>
                    <p>{data?.accessories}</p>
                </div>
            </div>
            }
        </Modal>
    )
}

export default CustomProductView
