import axios from 'axios'
import Kjur from '../utils/jwt';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`
    }
};

export const fetchSections = async (section) => {

    const data = {
        keyword: section.keyword,
        page: section.page,
        limit: section.limit
    }

    const encodedData = Kjur.encode(data)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/section?token=${encodedData}`,
        axiosConfig
    )
}

export const addSection = async (name) => {
    const data = {
        section_name:name,
    }

    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/section`,
        {token:encodedData},
        axiosConfig
    )
}

export const editSection = async (section) => {
    const data = {
        id:section.sectionId,
        section_name:section.sectionName,
    }

    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/section/update`,
        {token:encodedData},
        axiosConfig
    )
}

export const deleteSection = async (id) => {
    const data = {
        id:id
    }

    const encodedData = Kjur.encode(data)

    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/section/delete?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchArticles = async (article) => {

    const data = {
        keyword: article.keyword,
        page: article.page,
        limit: article.limit
    }

    if(article.sectionId === "0"){
        delete data.article_section_id
    }else{
        data.article_section_id = article.sectionId
    }

    const encodedData = Kjur.encode(data)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/news?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchArticle = async (id) => {
    const data = {
        id : id
    }

    const encodedData = Kjur.encode(data)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/news/show?token=${encodedData}`,
        axiosConfig
    )
}


export const addArticle = async (article , thumbnailUrl) => {

    const data = {
        article_title: article.articleTitle,
        article_author : article.articleAuthor,
        article_section_id : article.articleSection,
        article_tags : article.articleTagsArray,
        article_thumbnail : thumbnailUrl,
        article_body : article.articleBody,
        article_youtube_video: article.youtubeVideo,
    }

    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/news`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const editArticle = async (article , thumbnailUrl) => {

    const data = {
        article_id : article.articleId,
        article_title: article.articleTitle,
        article_author : article.articleAuthor,
        article_section_id : article.articleSection,
        article_tags : article.articleTagsArray,
        article_thumbnail : thumbnailUrl,
        article_body : article.articleBody,
        article_youtube_video: article.youtubeVideo,
    }

    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/news/update`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const deleteArticle = async (id) => {
    const data = {
        _id : id
    }

    const encodedData = Kjur.encode(data)

    // delete method doesnt do request boyd, it should be params like get
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/news/delete?token=${encodedData}`, 
        // {token:encodedData},
        axiosConfig 
    )
}