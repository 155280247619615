import { Col, Input, Row } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React , {useState , useEffect} from 'react'
import {IoMdCloseCircle} from 'react-icons/io';
import { error, success } from '../contants/snackbars';
import { colorScheme } from '../contants/variables';
import { CancelButton, PrimaryButton } from '../custom_components/customButton';
import {sendSMS} from '../stores/api_calls/upload'
import './trigger.scss'

const Trigger = () => {

    const [form , setForm] = useState({
        message:"",
        activeCountry:"sg",
        phoneNumber : "",
        numberArray:[]
    })

    const handleFieldChange = (name , e) => {
        if(name=='phoneNumber'){
            setForm({...form , [name] : e})
        }else{
            setForm({...form , [name] : e.target.value})
        }
    }

    const onEnter = (e) => {
        if(e.key==="Enter"){
            setForm({...form , phoneNumber:"", activeCountry:'sg', numberArray:[...form.numberArray , `+${form.phoneNumber}`]})
        }
    }

    const deleteContact = (value) => {
        const filteredArray = form.numberArray.filter((el) => el!==value)
        setForm({...form , numberArray:filteredArray})
    }

    const onSend = () => {
        const sms = sendSMS(form)

        sms.then((res)=>{
            if(res.data.success){
                success("Message Sent")
            }
        }).catch((e)=>{
            error(e.response.message)
        })
    }

    return (
        <div className='trigger-container'>
            <div className='form-container'>

                <Row gutter={[35,35]}>
                    <Col span={11}>
                        <p className='form-label'>Message <span>*</span> : </p>
                
                        <div className='form-div'>
                            <Input.TextArea 
                                value={form.message}
                                rows={8}
                                onChange={(text)=>handleFieldChange('message',text)}
                                placeholder='Please type Message'
                                // onKeyDown={onEnter}
                            />
                        </div>
                    </Col>
                    <Col span={13}>
                        <p className='form-label'>Phone Number <span>*</span> : </p>
                
                        <div className='form-div'>
                            {/* <Input 
                                value={form.phoneNumber}
                                onChange={(text)=>handleFieldChange('phoneNumber',text)}
                                placeholder='Please type Phone Number'
                                onKeyDown={onEnter}
                            /> */}
                            <PhoneInput
                                country={form.activeCountry}
                                value={form.phoneNumber}
                                onChange={(e)=>handleFieldChange('phoneNumber',e)}
                                placeholder='Please type Phone Number'
                                onKeyDown={onEnter}
                            />
                        </div>
                        <p className='form-sub-label'>Note : Press "Enter" after each phone number to add on the list</p>
                        

                        <div className='numbers-container'>
                            <Row gutter={[15,15]}>
                                {form.numberArray.map((el,i)=>
                                    <Col key={i} span={8}>
                                        <div className='phone-chip'>
                                            <IoMdCloseCircle onClick={()=>deleteContact(el)}/>
                                            <p>{el}</p>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className='btn-div'>
                    <div className='btn'> 
                        <PrimaryButton 
                            label="SEND SMS" 
                            color={colorScheme.primary} 
                            onClick={onSend}
                            disabled={form.message&&form.numberArray.length?false:true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trigger