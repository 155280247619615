import axios from "axios";
import Kjur from "../utils/jwt";
import moment from "moment";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchCities = async () => {
  const obj = {
    iso2: "SG",
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/list_of_cities?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchBrands = async (data) => {
  const obj = { page: data.page, limit: data.limit, keyword: data.keyword };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands?token=${encodedData}`,
    axiosConfig
  );
};

export const addBrand = async (brand) => {
  const data = {
    name: brand.name,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands`,
    { token: encodedData },
    axiosConfig
  );
};
export const editBrand = async (brand) => {
  const data = {
    name: brand.name,
    id: brand.id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands/update`,
    { token: encodedData },
    axiosConfig
  );
};
export const deleteBrand = async (brandId) => {
  const data = {
    id: brandId,
  };

  const encodedData = Kjur.encode(data);

  // delete method doesnt do request boyd, it should be params like get
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands/delete?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};

export const fetchModels = async (id) => {
  const obj = { brand_id: id };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands/models/get-brand-models?token=${encodedData}`,
    axiosConfig
  );
};

export const addModel = async (model) => {
  const data = {
    brand_id: model.brandId,
    name: model.name,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands/models`,
    { token: encodedData },
    axiosConfig
  );
};

export const editModel = async (model) => {
  const data = {
    id: model.modelId,
    brand_id: model.brandId,
    name: model.modelName,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands/models/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteModel = async (modelId) => {
  const data = {
    id: modelId,
  };

  const encodedData = Kjur.encode(data);

  // delete method doesnt do request boyd, it should be params like get
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/brands/models/delete?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};

export const fetchVerifiedCars = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
    product_condition: data.productCondition,
    is_commercial_vehicle: data.is_commercial_vehicle ?? null,
  };

  if (data.is_commercial_vehicle == null) {
    delete obj.is_commercial_vehicle;
  }

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/verified-car-list?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchUnverifiedCars = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
    product_condition: data.productCondition,
    is_commercial_vehicle: data.is_commercial_vehicle ?? null,
  };

  if (obj.is_commercial_vehicle == null) {
    delete obj.is_commercial_vehicle;
  }
  // console.log(obj);
  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/unverified-car-list?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchCarDetails = async (id) => {
  const obj = { id };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/show?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchSoldCars = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/sold-car-list?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchDraftCars = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/draft-car-list?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchPackages = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/advertisement_packages`,
    axiosConfig
  );
};

export const addCarProduct = async (form, imagesId) => {
  const data = {
    details_type: true,
    advertiser_id: form.dealerId,
    dealer_id: form.dealerId,
    car_plate_number: form.plateNumber,
    owner_type: form.ownerIdType,
    owner_id: form.ownerId,
    is_off_peak_car: form.isOffPeak,
    fuel_type: form.fuelType,
    vehicle_features: form.features,
    accessories: form.accessories,
    registration_date: moment(form.registrationDate).format("YYYY-MM-DD"),
    omv: form.omv,
    arf: form.arf,
    coe: form.coe,
    coe_expiry_date: moment(form.coeExpiryDate).format("YYYY-MM-DD"),
    number_of_owners: form.numberOfOwners,
    vehicle_type: form.vehicleType,
    product_brand_id: form.carBrand,
    product_brand_model_id: form.carModel,
    product_name: form.carName,
    product_vehicle_number: form.vehicleNumber,
    product_vehicle_unit_number: form.vehicleUnitNumber,
    product_reference_number: form.vehicleReferenceNumber,
    product_vehicle_registration_card_number:
      form.vehicleRegistrationCardNumber,
    product_price: form.askingPrice,
    product_edition: form.manufacturedYear,
    product_transmission: form.transmission,
    product_cc: form.engineCap,
    product_mileage: form.mileage,
    product_condition: form.condition,
    product_is_rental: form.rental,
    product_description: form.description,
    product_pickup_location: {
      street: form.address,
      country: "SG",
      region_name: form.regionName,
      city: form.cityName,
    },
    selected_ads_id: form.packageId,
    product_image_id: imagesId,
    dereg_value: form.deregValue,
    depreciation_value: form.depreciationValue,
    curb_weight: form.curbWeight,
    road_tax: form.roadTax,
    power: form.power,
    is_rental: false,
    color: form.color,
    youtube_video: form.youtubeVideo,
    tiktok_video: form.tiktokVideo,
    is_commercial_vehicle: form.isCommercialVehicle ?? null,
    is_verified_product: form.isVerified ?? null,
    is_send_email: form.isSendEmail ?? null,
  };

  if (form.dealerId === "0" || form.dealerId === "1") {
    delete data.advertiser_id;
    data.first_name = form.dealerFirstName;
    data.last_name = form.dealerLastName || "NA";
    data.number = form.dealerMobileNumber;
    data.email = form.dealerEmail;
    data.is_other = true;
    data.direct_seller = form.dealerId === "0" ? false : true;
  }

  if (!form.dealerEmail) {
    delete data.email;
  }
  
  if (form.isVerified == false) {
    delete data.is_verified_product;
  }

  if(form.carBidding) {
    data.car_bidding = form.carBidding;
    data.start_bid_date = moment(form.startBidDate).format("YYYY-MM-DD HH:mm");
    data.end_bid_date = moment(form.endBidDate).format("YYYY-MM-DD HH:mm");
    data.latest_bid_price = form.askingPrice;
    data.no_reserve = form.noReserve;
  }

  if(form.newCategory) {
    data.new_category = form.newCategory;
  }

  // console.log("DATA," ,data)

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products`,
    { token: encodedData },
    axiosConfig
  );
};

export const savedCarProduct = async (form, imagesId) => {
  const data = {
    details_type: true,
    advertiser_id: form.dealerId,
    car_plate_number: form.plateNumber,
    owner_type: form.ownerIdType,
    owner_id: form.ownerId,
    is_off_peak_car: form.isOffPeak,
    fuel_type: form.fuelType,
    vehicle_features: form.features,
    accessories: form.accessories,
    registration_date: moment(form.registrationDate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    omv: form.omv,
    arf: form.arf,
    coe: form.coe,
    coe_expiry_date: moment(form.coeExpiryDate).format("YYYY-MM-DD HH:mm:ss"),
    number_of_owners: form.numberOfOwners,
    vehicle_type: form.vehicleType,
    product_brand_id: form.carBrand,
    product_brand_model_id: form.carModel,
    product_name: form.carName,
    product_vehicle_number: form.vehicleNumber,
    product_vehicle_unit_number: form.vehicleUnitNumber,
    product_reference_number: form.vehicleReferenceNumber,
    product_vehicle_registration_card_number:
      form.vehicleRegistrationCardNumber,
    product_price: form.askingPrice,
    product_edition: form.manufacturedYear,
    product_transmission: form.transmission,
    product_cc: form.engineCap,
    product_mileage: form.mileage,
    product_condition: form.condition,
    product_is_rental: form.rental,
    product_description: form.description,
    product_pickup_location: {
      street: form.address,
      country: "SG",
      region_name: form.regionName,
      city: form.cityName,
    },
    selected_ads_id: form.packageId,
    product_image_id: imagesId,
    curb_weight: form.curbWeight,
    road_tax: form.roadTax,
    power: form.power,
    color: form.color,
    youtube_video: form.youtubeVideo,
    tiktok_video: form.tiktokVideo,
    is_commercial_vehicle: form.isCommercialVehicle,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/products`,
    { token: encodedData },
    axiosConfig
  );
};

export const updateCarProduct = async (form, imagesId) => {
  const data = {
    id: form.id,
    details_type: true,
    advertiser_id: form.dealerId,
    car_plate_number: form.plateNumber,
    owner_type: form.ownerIdType,
    owner_id: form.ownerId,
    is_off_peak_car: form.isOffPeak,
    fuel_type: form.fuelType,
    vehicle_features: form.features,
    accessories: form.accessories,
    registration_date: moment(form.registrationDate).format("YYYY-MM-DD"),
    omv: form.omv,
    arf: form.arf,
    coe: form.coe,
    coe_expiry_date: moment(form.coeExpiryDate).format("YYYY-MM-DD"),
    number_of_owners: form.numberOfOwners,
    vehicle_type: form.vehicleType,
    product_brand_id: form.carBrand,
    product_brand_model_id: form.carModel,
    product_name: form.carName,
    product_vehicle_number: form.vehicleNumber,
    product_vehicle_unit_number: form.vehicleUnitNumber,
    product_reference_number: form.vehicleReferenceNumber,
    product_vehicle_registration_card_number:
      form.vehicleRegistrationCardNumber,
    product_price: form.askingPrice,
    product_edition: form.manufacturedYear,
    product_transmission: form.transmission,
    product_cc: form.engineCap,
    product_mileage: form.mileage,
    product_condition: form.condition,
    product_is_rental: form.rental,
    product_description: form.description,
    product_pickup_location: {
      street: form.address,
      country: "SG",
      region_name: form.regionName,
      city: form.cityName,
    },
    selected_ads_id: form.packageId,
    product_image_id: imagesId,
    id: form.id,
    dereg_value: form.deregValue,
    depreciation_value: form.depreciationValue,
    curb_weight: form.curbWeight,
    road_tax: form.roadTax,
    power: form.power,
    is_rental: false,
    date_verified: moment().format("YYYY-MM-DD HH:mm:ss"),
    color: form.color,
    youtube_video: form.youtubeVideo,
    tiktok_video: form.tiktokVideo,
    is_commercial_vehicle: form.isCommercialVehicle ?? null,
    is_verified_product: form.isVerified ?? null,
    is_send_email: form.isSendEmail ?? null,
  };

  if(form.isCommercialVehicle == false) {
    data.is_commercial_vehicle = null;
  }

  if(form.carBidding) {
    data.car_bidding = form.carBidding;
    data.start_bid_date = moment(form.startBidDate).format("YYYY-MM-DD HH:mm:ss");
    data.end_bid_date = moment(form.endBidDate).format("YYYY-MM-DD HH:mm:ss");
    data.latest_bid_price = form.askingPrice;
    data.no_reserve = form.noReserve;
  }

  console.log("DATA," ,data)

  if(form.isVerified == false) {
    delete data.is_verified_product;
  }

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const updateNewCarProduct = async (form, imagesId) => {
  const data = {
    id: form.id,
    details_type: true,
    advertiser_id: form.dealerId,
    car_plate_number: form.plateNumber,
    owner_type: form.ownerIdType,
    owner_id: form.ownerId,
    is_off_peak_car: form.isOffPeak,
    fuel_type: form.fuelType,
    specifications: form.specifications,
    vehicle_features: form.features,
    accessories: form.accessories,
    registration_date: moment(form.registrationDate).format("YYYY-MM-DD"),
    omv: form.omv,
    arf: form.arf,
    coe: form.coe,
    coe_expiry_date: moment(form.coeExpiryDate).format("YYYY-MM-DD"),
    number_of_owners: form.numberOfOwners,
    vehicle_type: form.vehicleType,
    product_brand_id: form.carBrand,
    product_brand_model_id: form.carModel,
    product_name: form.carName,
    product_vehicle_number: form.vehicleNumber,
    product_vehicle_unit_number: form.vehicleUnitNumber,
    product_reference_number: form.vehicleReferenceNumber,
    product_vehicle_registration_card_number:
      form.vehicleRegistrationCardNumber,
    product_price: form.askingPrice,
    product_edition: form.manufacturedYear,
    product_transmission: form.transmission,
    product_cc: form.engineCap,
    product_mileage: form.mileage,
    product_condition: form.condition,
    product_is_rental: form.rental,
    product_description: form.description,
    product_pickup_location: {
      street: form.address,
      country: "SG",
      region_name: form.regionName,
      city: form.cityName,
    },
    selected_ads_id: form.packageId,
    product_image_id: imagesId,
    id: form.id,
    dereg_value: form.deregValue,
    depreciation_value: form.depreciationValue,
    curb_weight: form.curbWeight,
    road_tax: form.roadTax,
    power: form.power,
    is_rental: false,
    date_verified: moment().format("YYYY-MM-DD"),
    color: form.color,
    youtube_video: form.youtubeVideo,
    tiktok_video: form.tiktokVideo,
    built_in: form.builtIn,
    launch_year: form.launchYear,
    no_of_bidding: form.noOfBidding,
    brochures: form.brochures,
    description_formatted: form.desc,
    features_formatted: form.feat,
    specs_formatted: form.specs,
    long_ad_image_1: form.longAdImage1,
    new_category: form.newCategory,
    // long_ad_image_2: form.longAdImage2,
    is_verified_product: form.isVerified ?? null,
    is_send_email: form.isSendEmail ?? null,
  };

  console.log("DATA," ,data)

  if(form.isVerified == false) {
    delete data.is_verified_product;
  }

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/update-new-car`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteCarProduct = async (product_id) => {
  const encodedData = Kjur.encode({ id: product_id });
  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/delete`,
    { token: encodedData },
    axiosConfig
  );
};

// export const deletedOldCarProduct = async () => {
//   return await axios.get(
//     `${process.env.REACT_APP_API_BASE_URL}/products/delete-old-product`,
//     axiosConfig
//   );
// };

export const verifyCarProduct = async (product_id) => {
  const encodedData = Kjur.encode({ id: product_id });
  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/verify`,
    { token: encodedData },
    axiosConfig
  );
};

export const markAsSoldCarProduct = async (product_id) => {
  const encodedData = Kjur.encode({ id: product_id });
  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/sell`,
    { token: encodedData },
    axiosConfig
  );
};

export const fetchVerifiedCarBids = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
    product_condition: data.productCondition,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/verified-car-bid-list?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchUnverifiedCarBids = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
    product_condition: data.productCondition,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/unverified-car-bid-list?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchSoldCarBids = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/sold-car-bid-list?token=${encodedData}`,
    axiosConfig
  );
};