import axios from 'axios'
import Kjur from '../utils/jwt';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`
    }
};

export const fetchEmails = async (data) => {

    const obj = {
        email: data.email ?? null,
        latest_car_subscribe: data.latest_car_subscribe ?? null,
        // is_active: data.is_active ?? null,
        page:data.page,
        limit:data.limit,
        keyword:data.keyword
    }

    const encodedData = Kjur.encode(obj)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/marketing-emails?token=${encodedData}`,
        axiosConfig
    )
}

export const addEmail = async (marketingEmail) => {

    const data = {
        email: marketingEmail.email,
        latest_car_subscribe: marketingEmail.latestCarSubscribe,
    }
    // console.log(data);
    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/marketing-emails`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const editEmail = async (marketingEmail) => {

    const data = {
        id:marketingEmail.emailId,
        email: marketingEmail.email,
        latest_car_subscribe: marketingEmail.latestCarSubscribe,
        // is_active: marketingEmail.isActive,
    }
    
    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/marketing-emails/update`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const deleteEmail = async (emailId) => {
    const data = {
        id: emailId
    }

    const encodedData = Kjur.encode(data)

    // delete method doesnt do request boyd, it should be params like get
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/marketing-emails/delete?token=${encodedData}`, 
        // {token:encodedData},
        axiosConfig 
    )
}