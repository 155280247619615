import React, { useState, useEffect } from "react";
import { Input, Spin, Table } from "antd";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import { MdAddCircle } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import "./faq.scss";
import { deleteCareer, fetchCareers } from "../stores/api_calls/careers";
import { RiEye2Line, RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import moment from "moment";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { colorScheme } from "../contants/variables";
import { error, success } from "../contants/snackbars";
import { useNavigate, createSearchParams } from "react-router-dom";
import CareerPreviewModal from "../custom_components/careerPreviewModal";
import Kjur from "../stores/utils/jwt";
import { deleteCoe, fetchCoes } from "../stores/api_calls/coes";
import CoeForm from "../forms/coe/CoeForm";
import { deleteFaq, fetchFaqs } from "../stores/api_calls/faqs";
import FaqForm from "../forms/faq/FaqForm";

const FaqIndex = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState({
    visible: false,
    data: {},
    isEditing: false,
  });
  const [tableData, setTableData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: "",
    defaultPageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `Total ${total} items`,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    const pagination = {
      page: 1,
      limit: 10,
    };

    fetchData(pagination);
  }, []);

  const fetchData = (data) => {
    setIsLoading(true);

    const faqs = fetchFaqs(data);
    faqs
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          setTableData(res.data.data);
          setIsLoading(false);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const addFaqSuccess = () => {
    const pagination = {
      page: 1,
      limit: 10,
    };

    fetchData(pagination);
    setShowForm({ visible: false, data: {}, isEditing: false });
  };

  const handleDeleteFaq = (record) => {
    setShowDeleteModal({ ...showDeleteModal, visible: true, data: record });
  };

  const onDeleteSubmit = () => {
    const filteredData = tableData.filter(
      (el) => el.id !== showDeleteModal.data.id
    );

    const onDelete = deleteFaq(showDeleteModal.data.id);
    onDelete
      .then((res) => {
        if (res.data.success) {
          success("Career deleted successfully.");
          setTableData(filteredData);
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {} });
        }
      })
      .catch((e) => {
        error(`${e.response.data.server_response}`);
      });
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },

    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Career"
            Icon={<RiEdit2Fill />}
            onClick={() =>
              setShowForm({ isEditing: true, visible: true, data: record })
            }
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Career"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => handleDeleteFaq(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="career-table-container">
      <div className="table-title-icon primary">
        <FaTags />
      </div>
      <p className="table-title-text primary">Manage FAQ</p>
      <div className="search-container">
        <div className="add-button-container">
          <PrimaryButton
            Icon={<MdAddCircle />}
            color={colorScheme.primary}
            label="Add FAQ"
            onClick={() =>
              setShowForm({ visible: true, data: {}, isEditing: false })
            }
          />
        </div>
      </div>

      <div className="table-div">
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          pagination={tablePagination}
          onChange={() => {}}
          bordered
          sticky
        />
      </div>

      <FaqForm
        visible={showForm.visible}
        isEditing={showForm.isEditing}
        data={showForm.data}
        onCancel={() =>
          setShowForm({ visible: false, data: {}, isEditing: false })
        }
        addFaqSuccess={addFaqSuccess}
      />

      <CustomDeleteModal
        title={`Delete FAQ`}
        visible={showDeleteModal.visible}
        onSubmit={onDeleteSubmit}
        onCancel={() => {
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {} });
        }}
        data={{ itemName: `${showDeleteModal.data.answer}`, type: "faq" }}
      />
    </div>
  );
};

export default FaqIndex;
