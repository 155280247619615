import { notification } from "antd"

export const success = (message) => {
    return notification.success({
        message: message,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
}

export const error = (message) => {
    return notification.error({
        message: message,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
}