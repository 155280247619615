import axios from "axios";
import Kjur from "../utils/jwt";
import moment from "moment";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchFaqs = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/faq?token=${encodedData}`,
    axiosConfig
  );
};

export const addFaq = async (data) => {
  const transformedData = {
    question: data.question,
    answer: data.answer,
  };

  const encodedData = Kjur.encode(transformedData);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/faq`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteFaq = async (id) => {
  const data = {
    id,
  };

  const encodedData = Kjur.encode(data);

  // delete method doesnt do request boyd, it should be params like get
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/faq/delete?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};

export const editFaq = async (data) => {
  const transformedData = {
    id: data.id,
    question: data.question,
    answer: data.answer,
  };

  const encodedData = Kjur.encode(transformedData);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/faq/update`,
    { token: encodedData },
    axiosConfig
  );
};
