import {
  Input,
  Modal,
  Select,
  Space,
  DatePicker,
  Radio,
  Divider,
  Checkbox,
  Row,
  Col,
} from "antd";
import React, { useRef, useState, useEffect } from "react";
import "./styles.scss";
import { error, success } from "../../contants/snackbars";
import { editComment } from "../../stores/api_calls/bid-comments";

const BidCommentForm = (props) => {
  const { isEdit, data, onCancel, visible, fetchData, tablePagination, verifyStatus } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form, setForm] = useState({
    commentText: '',
    amount: '',
    commentType: '',
    replyTo: null,
    productId: "",
    productName: "",
    isVerified: false,
  });
  console.log(data);
  useEffect(() => {
    if (isEdit) {
      setForm({
        ...form,
        commentId: data._id,
        commentText: data.comment_text,
        amount: data.amount,
        commentType: data.comment_type,
        replyTo: data.reply_to,
        productId: data.product_id,
        productName: data.product_details.product_name,
        isVerified: data.is_verified,
      });
    }
  }, [isEdit]);

  const refetchLists = () => {
    const pagination = {
      page: tablePagination.current,
      limit: tablePagination.pageSize,
      keyword: "",
      is_verified: verifyStatus,
    };
    fetchData(pagination);
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  // const onCheckboxChange = (name, e) => {
  //   setForm({
  //     ...form,
  //     [name]: e.target.checked,
  //   });
  // };

  // const handleDropdownChange = (name, value) => {
  //   setForm({ ...form, [name]: value });
  // };

  // const onDateFieldChange = (name, value) => {
  //   setForm({ ...form, [name]: value });
  // };

  // const handleOptionChange = (name, e) => {
  //   setForm({ ...form, [name]: e.target.value })
  // };

  const onClose = () => {
    setForm({
      ...form,
      commentText: '',
      amount: '',
      commentType: '',
      replyTo: null,
      productId: "",
      productName: "",
      isVerified: false,
    });
    onCancel();
  };

  const onSubmit = () => {
    setSubmitLoading(true);
    var data = form;

    onCreateBanner(data);
  };

  const onCreateBanner = (data) => {
    console.log(data);
    // const createBanner = addComment(data);

    // createBanner
    //   .then((res) => {
    //     if (res?.data?.success) {
    //       refetchLists();
    //       success("New Comment Added Successfully");
    //       onClose();
    //       setSubmitLoading(false);
    //     }
    //   })
    //   .catch((e) => {
    //     error(
    //       `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
    //     );
    //   });
  }

  const onUpdate = () => {
    var data = form;

    onUpdateComment(data);
  };

  const onUpdateComment = (data) => {
    setSubmitLoading(true);
    const updateComment = editComment(data);

    updateComment
      .then((res) => {
        if (res.data.success) {
          refetchLists();
          success("Bidding Comment Updated Successfully");
          onClose();
          setSubmitLoading(false);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEdit ? "Edit" : "Create"} Bid Comment`}
        visible={visible}
        // width={700}
        onOk={isEdit ? onUpdate : onSubmit}
        onCancel={onClose}
        okText={`${isEdit ? "Save" : "Submit"}`}
        confirmLoading={submitLoading}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="form-container">
              <p className="form-label">
                Product Name <span>*</span> :{" "}
              </p>
              <Input
                disabled={true}
                value={form.productName}
                placeholder="Please type Product Name"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Comment Text :{" "}
              </p>
              <Input
                value={form.commentText}
                onChange={(text) => handleFieldChange("commentText", text)}
                placeholder="Please type Comment Text"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Comment Type <span>*</span> :{" "}
              </p>
              <Input
                disabled={true}
                value={form.commentType}
                onChange={(text) => handleFieldChange("commentType", text)}
                placeholder="Please type Comment Type"
              />
            </div>

          </Col>
          
          {/* <Col span={24}>
            <div className="form-container">
              <Checkbox
                valuePropName="checked"
                checked={form.isVerified}
                onChange={(e) => onCheckboxChange("isVerified", e)}
              >
                Is Verified
              </Checkbox>
            </div>
          </Col> */}
        </Row>
      </Modal>
    </div>
  );
};

export default BidCommentForm;