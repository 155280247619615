import React from 'react'
import { FaCarCrash } from 'react-icons/fa'
import './custom.scss';

const NoImage = () => {
    return (
        <div
            className='no-image-container'
        >   
            <p>OPPS!</p>
            <FaCarCrash/>
            <p>Image not found.</p>
        </div>
    )
}

export default NoImage
