import React, { useEffect, useState } from "react";
import { Input, Row, Col } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { success, error } from "../contants/snackbars";
import {
  LoadingButton,
  PrimaryButton,
} from "../custom_components/customButton";
import { editTerms, fetchTerm } from "../stores/api_calls/about";
import { colorScheme } from "../contants/variables";

const TermsAndConditions = () => {
  const [form, setForm] = useState({
    title: "",
    description: null,
    id: "63e77f8050b58a73213823e4",
  });
  const [requestLoading, setRequestLoading] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    const updateContent = fetchTerm({ id: "63e77f8050b58a73213823e4" });
    updateContent
      .then((res) => {
        if (res.data.success) {
          const contentState = convertFromRaw(res.data.data.description);
          const newContent = EditorState.createWithContent(contentState);
          setEditorState(newContent);
          setForm({ ...form, title: res.data.data.title });
        }
      })
      .catch((e) => {
        error("Failed to retrieve content");
      });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const handleFieldChange = (name, e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setRequestLoading(true);
    // the raw state, stringified
    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const transformedData = form;
    transformedData.description = JSON.parse(rawDraftContentState);

    const updateContent = editTerms(transformedData);
    updateContent
      .then((res) => {
        if (res.data.success) {
          success("Successfully updated");
          setRequestLoading(false);
        }
      })
      .catch((e) => {
        error("Failed to save changes");
        setRequestLoading(false);
      });
  };

  return (
    <div>
      <h1>Terms And Conditions</h1>
      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Title <span>*</span> :{" "}
            </p>
            <Input
              value={form.title}
              onChange={(text) => handleFieldChange("title", text)}
            />
          </div>
        </Col>
        <Col span={24}>
          <div className="editor-container">
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                inline: { inDropdown: false },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </Col>

        <div className="btn-div" style={{ marginTop: 24 }}>
          <div className="btn">
            {requestLoading ? (
              <LoadingButton />
            ) : (
              <PrimaryButton
                label="Save Changes"
                color={colorScheme.primary}
                onClick={() => onSubmit()}
                disabled={!form.title && !form.description}
              />
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default TermsAndConditions;
