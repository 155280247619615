import { Checkbox, Input, Modal } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { error, success } from "../../contants/snackbars";
import { addPlan, editPlan } from "../../stores/api_calls/packages";
import { generateInclusionLabel } from "../../stores/helpers/packageFormHelpers";

import { UserContext } from "../../stores/contexts/userContext";
import { addPlateNumber } from "../../stores/api_calls/plateNumbers";
import { addCoe, editCoe } from "../../stores/api_calls/coes";
import { addFaq, editFaq } from "../../stores/api_calls/faqs";

const { TextArea } = Input;

const FaqForm = ({ visible, onCancel, addFaqSuccess, isEditing, data }) => {
  const [requestLoading, setRequestLoading] = useState(false);
  const [form, setForm] = useState({
    question: "",
    answer: "",
  });

  useEffect(() => {
    if (isEditing) {
      setForm({
        ...form,
        id: data.id,
        question: data.question,
        answer: data.answer,
      });
    } else {
      setForm({
        question: "",
        answer: "",
      });
    }
  }, [isEditing]);

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const onSubmit = () => {
    const createFaq = addFaq(form);
    createFaq
      .then((res) => {
        if (res.data.success) {
          success("FAQ Created Successfully");
          addFaqSuccess();
          setRequestLoading(false);
        }
      })
      .catch((e) => {
        error("Failed to Add FAQ");
        setRequestLoading(false);
      });
  };

  const onUpdate = () => {
    const updateFaq = editFaq(form);
    updateFaq
      .then((res) => {
        if (res.data.success) {
          success("FAQ Updated Successfully");
          addFaqSuccess();
          setRequestLoading(false);
        }
      })
      .catch((e) => {
        error(`Opps! Update failed. Please try again later`);
      });
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEditing ? "Edit" : "Add"} FAQ`}
        visible={visible}
        onOk={isEditing ? onUpdate : onSubmit}
        onCancel={onCancel}
        okText={`${isEditing ? "Save" : "Submit"}`}
      >
        <div className="field-container">
          <p className="field-label">Question: </p>
          <Input
            value={form.question}
            placeholder="Question"
            onChange={(text) => handleFieldChange("question", text)}
          />
        </div>

        <div className="field-container">
          <p className="field-label">Answer: </p>
          <TextArea
            rows={4}
            value={form.answer}
            placeholder="Type Answer"
            onChange={(text) => handleFieldChange("answer", text)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default FaqForm;
