import { Input, Modal } from 'antd'
import React , {useState , useEffect} from 'react'
import { error, success } from '../contants/snackbars'
import { sendSMS } from '../stores/api_calls/upload'
import './custom.scss'

const CustomSMSModal = (props) => {

    const {visible  , onCancel , data}=props
    const [loading , setLoading] = useState(false) 
    const [form , setForm] = useState({
        message:"",
        phoneNumber : "",
        numberArray:[]
    })

    useEffect(()=>{
        if(data){
            setForm(({
                ...form,
                message:`${data.product_name} - Plate No:${data.car_plate_number} \nMessage : \n`,
                phoneNumber : data.advertisement_contact_details?.user_contact_numbers.number,
                numberArray: [data.advertisement_contact_details?.user_contact_numbers.number]
            }))
        }
    },[data])

    const onSubmit = () => {
        setLoading(true)

        const sms = sendSMS(form)

        console.log(form)

        sms.then((res)=>{
            if(res.data.success){
                success("Message Sent")
                setLoading(false)
                onCancel()
            }
        }).catch((e)=>{
            error(e.response.message)
        })
    }

    const handleFieldChange = (name , e) => {
        setForm({
            ...form,
            [name] : e.target.value
        })
    }

    return (
        <Modal
            className='smsModal'
            title={`Send SMS to ${data.advertisement_contact_details?.name}`}
            visible={visible}
            onOk={()=>onSubmit()}
            okButtonProps={{ disabled:  form.message&&form.numberArray?.length ? false : true }}
            confirmLoading={loading}
            onCancel={onCancel}
            okText="Send"
        >
            

            <div className='message-container'>
                <p>Message *</p>
                <Input.TextArea
                    rows={8}
                    value={form.message}
                    onChange={text=>handleFieldChange('message' , text)}
                />
            </div>


            <p>This SMS will send to following numbers below;</p>
            <p style={{ margin:0 }} >
                {form?.phoneNumber}
            </p>
            
        </Modal>
    )
}

export default CustomSMSModal
