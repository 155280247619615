import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchCarWorkshops = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshops?token=${encodedData}`,
    axiosConfig
  );
};

export const addCarWorkShop = async (payload) => {
  console.log(payload);
  const data = {
    workshop_image_id: payload.imagesIds,
    car_workshop_name: payload.name,
    car_workshop_address: payload.address,
    coordinates: {
      latitude: "",
      longitude: "",
    },
    operating_hours: {
      opening_time: payload.opening_time,
      closing_time: payload.closing_time,
    },
    email: payload.email,
    contact_numbers: [payload.contact],
    website: payload.website,
    facebook: payload.facebook,
    more_outlets: [
      // {
      //   address:
      //     "8 Kaki Bukit Avenue 4 #05-35 Premier @ Kaki Bukit S(555555)",
      //   operating_hours: {
      //     opening_time: "08:00 am",
      //     closing_time: "05:00 pm",
      //   },
      //   contact_numbers: ["09287666301"],
      // },
      // {
      //   address:
      //     "8 Kaki Bukit Avenue 4 #05-35 Premier @ Kaki Bukit S(555555)",
      //   operating_hours: {
      //     opening_time: "08:00 am",
      //     closing_time: "05:00 pm",
      //   },
      //   contact_numbers: ["09287666301"],
      // },
    ],
    specialties: "",
    categories: payload.categories,
    brands: payload.brands,
    description: payload.description,

    open_till_late: payload.open_till_late,
    open_on_sundays: payload.open_on_sundays,
    customer_lounge: payload.customer_lounge,
    accepts_credit_card: payload.accepts_credit_card,
    installment_plan: payload.installment_plan,
    door_step_service: payload.door_step_service,
    workshop_logo: null,
    tiktok_video: "",
    youtube_video: "",
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshops`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteCarWorkShop = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshops/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchCategories = async (section) => {
  const data = {
    keyword: section.keyword,
    page: section.page,
    limit: section.limit,
  };

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-categories?token=${encodedData}`,
    axiosConfig
  );
};

export const addCategory = async (name) => {
  const data = {
    name: name,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-categories`,
    { token: encodedData },
    axiosConfig
  );
};

export const editCategory = async (section) => {
  const data = {
    id: section.sectionId,
    name: section.sectionName,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-categories/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteCategory = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-categories/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchBrands = async (section) => {
  const data = {
    keyword: section.keyword,
    page: section.page,
    limit: section.limit,
  };

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-brands?token=${encodedData}`,
    axiosConfig
  );
};

export const addBrand = async (name) => {
  const data = {
    name: name,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-brands`,
    { token: encodedData },
    axiosConfig
  );
};

export const editBrand = async (section) => {
  const data = {
    id: section.sectionId,
    name: section.sectionName,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-brands/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteBrand = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-workshop-brands/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchAccessories = async (id) => {
  const obj = { car_workshop_id: id };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories?token=${encodedData}`,
    axiosConfig
  );
};

export const addAccessory = async (payload) => {
  
  const data = {
    car_workshop_id: payload.car_workshop_id,
    title: payload.title,
    price: payload.price,
    condition: payload.condition,
    categories: payload.categories,
    accessory_image_id: payload.imagesIds,
    suitable_for: payload.suitable_for,
    tiktok_video: "",
    youtube_video: "",
    description: payload.description,
  };

  console.log(data);

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories`,
    { token: encodedData },
    axiosConfig
  );
};

export const editAccessory = async () => {
  return null;
};

export const deleteAccessory = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories/delete?token=${encodedData}`,
    axiosConfig
  );
};
