import { Row , Col, Input, Modal } from 'antd';
import React , {useState , useEffect} from 'react';
import { IconButton } from '../../custom_components/customButton';
import { RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri';
import { error, success } from '../../contants/snackbars';
import { addInclusion, deleteInclusion, editInclusion } from '../../stores/api_calls/packages';

const InclusionForm = (props) => {

    const {inclusions , setInclusions , visible ,  onCancel} = props
    const [deleteConfirm , setDeleteConfirm] = useState(false)
    const [isEdit , setIsEdit] = useState(false)
    const [form , setForm] = useState({
        inclusionId:"",
        inclusionName:"",
        inclusionCode:"",
    })

    const onClose = () => {
        setIsEdit(false)
        setForm({...form , inclusionName:"" , inclusionId:""})
        onCancel()
    }

    const handleFieldChange = (e) => {
        setForm({...form , inclusionName : e.target.value})
    }

    const onEdit = (item) => {
        setIsEdit(true)
        setForm({...form , inclusionName : item.name , inclusionId:item._id , inclusionCode:item.code})
    }

    const onDelete = (item) => {
        setForm({...form , inclusionId : item._id})
        setDeleteConfirm(true)
    }

    const onDeleteConfirm = (confirm) => {
        if(confirm){
            const removeInclusion = deleteInclusion(form.inclusionId)
            removeInclusion.then((res)=>{
                if(res.data.success){
                    const filteredData = inclusions.filter((el)=>el._id!==form.inclusionId)
                    setInclusions(filteredData)
                    success("Section deleted successfully.")
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }
        setDeleteConfirm(false)
    }

    const handleEnter = (e) => {
        if(e.key==="Enter"){

            const generateCode = form.inclusionName.replaceAll(" " , "_").toLowerCase()

            if(isEdit){

                const data = {
                    id:form.inclusionId,
                    code:generateCode,
                    name:form.inclusionName
                }

                const updateInclusion = editInclusion(data)
                updateInclusion.then((res)=>{
                    if(res.data.success){
                        const name = form.inclusionName
                        const updatedData = inclusions.map(el => (el._id === form.inclusionId ? {...el, name } : el))
                        setInclusions(updatedData)
                        success("Section updated successfully.")
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
                })
            }else{
                
                const data = {
                    code:generateCode,
                    name:form.inclusionName
                }

                const createInclusion = addInclusion(data)
                createInclusion.then((res)=>{
                    if(res.data.success){
                        setInclusions([...inclusions , res.data.data])
                        success("Section added successfully.")
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
                })
            }
            setIsEdit(false)
            setForm({...form , inclusionName:"" , inclusionId:""})
        }
    }

    return (
        <div>
            <Modal
                className='formModal'
                title={`Manage Inclusions`}
                visible={visible}
                onCancel={()=>onClose()}
                footer={null}
            >
                <div style={{marginBottom:"10px"}}>
                    <p>Press "Enter" to {isEdit? "update" : "create new"} inclusion</p>
                    <Input
                        placeholder='Input Section Name Here'
                        value={form.inclusionName}
                        onChange={(e)=>handleFieldChange(e)}
                        onKeyDown={(e)=>handleEnter(e)}
                    />
                </div>

                {deleteConfirm?
                    <p className='confirm-delete'>
                        Are you sure you want to delete this inclusion? <span className='yes' onClick={()=>onDeleteConfirm(true)}>Yes</span> or <span className='no' onClick={()=>onDeleteConfirm(false)}>No</span>
                    </p>
                :null}

                {inclusions.map((inclusion , idx)=>
                    <div key={idx} className="inclusion-container">
                        <Row>
                            <Col span={20}>
                                {inclusion.name}
                            </Col>
                            <Col>
                                <div className='icon-container'>
                                    <IconButton 
                                        className='icon-button edit' 
                                        tootTipTitle="Edit Inclusion" 
                                        Icon={<RiEdit2Fill/>} 
                                        onClick={()=>onEdit(inclusion)} 
                                    />
                                    <IconButton 
                                        className='icon-button delete' 
                                        tootTipTitle="Delete Inclusion" 
                                        Icon={<RiDeleteBin2Fill/>} 
                                        onClick={()=>onDelete(inclusion)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
        </div>
    )
}

export default InclusionForm
