import { Modal , Input} from 'antd';
import React , {useState , useEffect} from 'react';
import { error, success } from '../../contants/snackbars';
import { addModel, editModel } from '../../stores/api_calls/carlists';
import './brands.scss';

export const ModelAddForm = (props) => {

    const {onCancel , visible ,  getModels , data , refetchLists} = props 
    const [submitLoading , setSubmitLoading] = useState(false)
    const [form ,setForm] = useState({
        modelName:""
    }) 

    const handleFieldChange = (name , e) => {
        setForm({
            ...form,
            [name]:e.target.value
        })
    }

    const onSubmit = () => {
        setSubmitLoading(true)
        const newData = {
            brandId:data._id,
            name:form.modelName
        }

        const model = addModel(newData)

        model.then((res)=>{
            if(res.data.success){
                getModels()
                refetchLists()
                success("Model Added Successfully")
                setSubmitLoading(false)
                onClose()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onClose = () =>{
        onCancel()
        setForm({...form , modelName : ""})
    }

    return (
        <div>
            <Modal
                className='formModal'
                title={`Add Model`}
                visible={visible}
                onOk={onSubmit}
                okButtonProps={{ disabled:  form.modelName !== "" ? false : true  }}
                onCancel={onClose}
                okText={`Create`}
                confirmLoading={submitLoading}
            >

                <div className='form-container'>
                    <p className='form-label'>Model Name <span>*</span> : </p>
                    <Input 
                        value={form.modelName}
                        onChange={(text)=>handleFieldChange('modelName',text)}
                        placeholder='Please type Model Name'
                    />
                </div>
            </Modal>
        </div>
    )
}

export const ModelEditForm = (props) => {

    const {data , onCancel , visible ,  getModels} = props 
    const [submitLoading , setSubmitLoading] = useState(false)
    const [form ,setForm] = useState({
        modelName:""
    }) 

    useEffect(()=>{
        if(data){
            setForm({...form , modelName : data.name})
        }
    },[data])

    const handleFieldChange = (name , e) => {
        setForm({
            ...form,
            [name]:e.target.value
        })
    }

    const onSubmit = () => {
        setSubmitLoading(true)
        const item = {
            brandId : data.brand_id,
            modelId : data._id,
            modelName : form.modelName
        }

        const model = editModel(item)

        model.then((res)=>{
            if(res.data.success){
                getModels()
                success("Model Updated Successfully")
                setSubmitLoading(true)
                onClose()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onClose = () =>{
        onCancel()
    }

    return (
        <div>
            <Modal
                className='formModal'
                title={`Edit Model`}
                visible={visible}
                onOk={onSubmit}
                okButtonProps={{ disabled:  form.modelName !== "" ? false : true  }}
                onCancel={onClose}
                okText={`Update`}
                confirmLoading={submitLoading}
            >

                <div className='form-container'>
                    <p className='form-label'>Model Name <span>*</span> : </p>
                    <Input 
                        value={form.modelName}
                        onChange={(text)=>handleFieldChange('modelName',text)}
                        placeholder='Please type Model Name'
                    />
                </div>
            </Modal>
        </div>
    )
}
