import { Row , Col, Input, Modal } from 'antd';
import React , {useState , useEffect} from 'react';
import { IconButton } from '../../custom_components/customButton';
import { RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri';
import { error, success } from '../../contants/snackbars';
import { deleteClient, fetchClients , addClient, editClient } from '../../stores/api_calls/clients';

const ClientForm = (props) => {

    const {visible , onCancel , clients , setClients} = props
    const [isEdit ,setIsEdit] = useState(false)
    const [deleteConfirm , setDeleteConfirm] = useState(false)
    const [searchText , setSearchText] = useState("")
    const [pagination , setPagination] = useState({
        current: 1,
        pageSize: 1000,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const [form , setForm] = useState({
        clientId:"",
        clientName:"",
        articleCount:0,
        isBiddingClient: true,
    })

    useEffect(()=>{
        fetchData()
    },[])

    const fetchData = () => {
        const filter = {
            keyword : searchText,
            page : pagination.current,
            limit:pagination.pageSize,
            is_bidding_client: true,
        }
        const getClients = fetchClients(filter)

        getClients.then((res)=>{
            if(res.data.success){
                // const clientsArr = clients.concat(res.data.data)
                // console.log(res.data);
                setClients(res.data.data)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const handleFieldChange = (e) => {
        setForm({...form , clientName : e.target.value})
    }

    const onEdit = (item) => {
        setIsEdit(true)
        setForm({...form , clientName : item.name , clientId:item._id})
    }

    const onDelete = (item) => {
        setForm({...form , clientId : item._id})
        setDeleteConfirm(true)
    }

    const onDeleteConfirm = (confirm) => {
        if(confirm){
            const removeClient = deleteClient(form.clientId)
            removeClient.then((res)=>{
                if(res.data.success){
                    const filteredData = clients.filter((el)=>el._id!==form.clientId)
                    setClients(filteredData)
                    success("Client deleted successfully.")
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }
        setDeleteConfirm(false)
    }

    const handleEnter = (e) => {
        if(e.key==="Enter"){
            if(isEdit){
                const updateClient = editClient(form)
                updateClient.then((res)=>{
                    if(res.data.success){
                        // console.log(res.data.data)
                        const name = form.clientName
                        const updatedData = clients.map(el => (el._id === form.clientId ? {...el, name } : el))
                        setClients(updatedData)
                        success("Section updated successfully.")
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
                })
            }else{
                const createClient = addClient(form.clientName, form.isBiddingClient)
                createClient.then((res)=>{
                    if(res.data.success){
                        setClients([...clients , res.data.data])
                        success("Client added successfully.")
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
                })
            }
            setIsEdit(false)
            setForm({...form , clientName:"" , clientId:""})
            fetchData()
        }
    }

    return (
        <div>
            <Modal
                className='formModal'
                title={`Manage Bidding Dealer Company`}
                visible={visible}
                onCancel={onCancel}
                footer={null}
                width={700}
            >
                <div>
                    <div style={{marginBottom:"10px"}}>
                        <p>Press "Enter" to {isEdit? "update" : "create new"} bidding dealer company</p>
                        <Input
                            placeholder='Input agency name here'
                            value={form.clientName}
                            onChange={(e)=>handleFieldChange(e)}
                            onKeyDown={(e)=>handleEnter(e)}
                        />
                    </div>

                    {deleteConfirm?
                        <p className='confirm-delete'>
                            Are you sure you want to delete this company? Deleting this agency will also delete all dealers inside it. <span className='yes' onClick={()=>onDeleteConfirm(true)}>Yes</span> or <span className='no' onClick={()=>onDeleteConfirm(false)}>No</span>
                        </p>
                    :null}
                    
                    <Row gutter={[10,10]}>
                        <Col span={8}>
                            <p>Company Name</p>
                        </Col>
                        <Col span={6}>
                            <p style={{textAlign:'center'}}>No. of Dealers</p>
                        </Col>
                        <Col span={6}>
                            <p style={{textAlign:'center'}}>Total Listed Car</p>
                        </Col>
                        <Col span={4}>
                            <p style={{textAlign:'center'}}>Actions</p>
                        </Col>
                    </Row>

                    {clients.map((client,index)=>
                        <div key={index} className='section-container'>
                            
                            <Row gutter={[10,10]}>
                                <Col span={8}>
                                    <p>{client.name}</p>
                                </Col>
                                <Col span={6}>
                                    <p style={{textAlign:'center'}}>{client.number_of_dealers}</p>
                                </Col>
                                <Col span={6}>
                                    <p style={{textAlign:'center'}}>{client.total_car_listed}</p>
                                </Col>
                                <Col span={4}>
                                    <div className='icon-container'>
                                        <IconButton 
                                            className='icon-button edit' 
                                            tootTipTitle="Edit Client" 
                                            Icon={<RiEdit2Fill/>} 
                                            onClick={()=>onEdit(client)} 
                                        />
                                        <IconButton 
                                            className='icon-button delete' 
                                            tootTipTitle="Delete Client" 
                                            Icon={<RiDeleteBin2Fill/>} 
                                            onClick={()=>onDelete(client)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default ClientForm
