import { Modal } from 'antd'
import React from 'react'
import './custom.scss'

const BannerPreviewModal = (props) => {
    const {visible , onCancel , imageUrl}=props

    // console.log(imageUrl)

    return (
        <Modal
            title="Banner Preview"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className='preview-modal banner'
        >
            <div className='view-banner-container'>
                <img src={imageUrl}/>
            </div>
        </Modal>
    )
}

export default BannerPreviewModal
