import { Button, Spin, Tooltip } from 'antd'
import React from 'react'
import { colorScheme } from '../contants/variables'

export const FormPrimaryButton = (props) => {

    const {color , label} = props

    return (
        <Button 
            htmlType="submit" 
            style={{
                background:color,
                borderColor:color,
                color:colorScheme.white,
                width:'100%',
                textTransform:'uppercase',
                fontWeight:'500',
                letterSpacing:'0.1em',
                height:'40px'
            }}
        >
            {label}
        </Button>
    )
}

export const PrimaryButton = (props) => {

    const {color , label , onClick , Icon , disabled} = props

    return (
        <Button 
            onClick={onClick}
            disabled={disabled}
            style={{
                background:!disabled ? color : colorScheme.lightgray,
                borderColor:!disabled ? color : colorScheme.gray,
                color:colorScheme.white,
                width:'100%',
                textTransform:'uppercase',
                fontWeight:'500',
                letterSpacing:'0.1em',
                height:'40px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}
        >
            {Icon ?? null}
            {label}
        </Button>
    )
}

export const LoadingButton = (props) => {
    const {label} = props
    return(
        <Button
            style={{
                background:colorScheme.offwhite,
                borderColor:colorScheme.lightgray,
                textColor:colorScheme.black,
                width:'100%',
                textTransform:'uppercase',
                fontWeight:'500',
                letterSpacing:'0.1em',
                height:'40px'
            }}
            disabled
        >
            {label?? <>Loading...Please wait</>} <Spin style={{marginLeft:"10px"}}/>
        </Button>
    )
}

export const CancelButton = (props) => {

    const {bgColor , textColor ,  label , onClick} = props

    return (
        <Button 
            onClick={onClick}
            style={{
                background:bgColor,
                borderColor:bgColor,
                textColor:textColor,
                width:'100%',
                textTransform:'uppercase',
                fontWeight:'500',
                letterSpacing:'0.1em',
                height:'40px'
            }}
        >
            {label}
        </Button>
    )
}

export const IconButton = (props) => {
    const {tootTipTitle , className, onClick , Icon} = props

    return (
        <Tooltip
            placement="top" 
            title={tootTipTitle}
        >
            <div className={className} onClick={onClick}>
                {Icon}
            </div>
        </Tooltip>
    )
}

