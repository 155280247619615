import axios from 'axios'
import Kjur from '../utils/jwt';

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90'
    }
};

export const login = async (data) => {

    const obj = {
        username:data.username,
        password: data.password
    }

    const encodedData = Kjur.encode(obj)

    console.log(encodedData)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/login`, 
        {token:encodedData},
        axiosConfig
        
    )
}

export const logout = async () => {

    const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))
    axiosConfig.headers['Authorization'] = `Bearer ${bearerToken}`

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/logout`, 
        {},
        axiosConfig
        
    )
}


export const verifyOTP = async (data) => {
    

    const obj = {
        username:data.username,
        otp:+data.otp,
        with_token:true
    }

    const encodedData = Kjur.encode(obj)


    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/verify-login-otp`, 
        {token:encodedData},
        axiosConfig
    )
}
