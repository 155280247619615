import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};


// export const addComment = async (payload) => {
//   const data = {
//     description: payload.description,
//     is_active: payload.is_active,
//   };

//   const encodedData = Kjur.encode(data);

//   return await axios.post(
//     `${process.env.REACT_APP_API_BASE_URL}/admin/bid-comment`,
//     { token: encodedData },
//     axiosConfig
//   );
// };

export const fetchComments = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bid-comment?token=${encodedData}`,
    axiosConfig
  );
};

export const editComment = async (payload) => {
  const data = {
    id: payload.commentId,
    comment_text: payload.commentText,
    amount: payload.amount ?? null,
    // is_verified: payload.isVerified ?? false,
    comment_type: payload.commentType,
    product_id: payload.productId ?? null,
    // reply_to: payload.replyTo ?? null,
    // upvotes: payload.upvotes ?? null,
    // flags: payload.flags ?? null,
    // verified_by: payload.verifiedBy ?? null,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bid-comment/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteComment = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bid-comment/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const verifyComment = async (comment_id) => {
  const encodedData = Kjur.encode({ id: comment_id });
  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/bid-comment/verify`,
    { token: encodedData },
    axiosConfig
  );
};
