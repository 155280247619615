import React from 'react';
import './splash.scss';

export const NoAccessPage = () => {
  return (
      <div className='splash-container'>

          <h3>YOU DO NOT HAVE ACCESS TO THIS PAGE, KINDLY CONTACT YOUR SUPER ADMIN</h3>

      </div>
  );
};

export const NotFoundPage = () => {
    return (
        <div className='splash-container'>
  
            <h3>ERROR 404 : PAGE NOT FOUND</h3>
  
        </div>
    );
};

export const NoData = ({message}) => {
    return (
        <div 
            className='splash-no-data'
        >
  
            <h3>{message}</h3>
  
        </div>
    );
};
  

