import React, { useState } from "react";
import { Table, Spin } from "antd";
import { IconButton } from "../custom_components/customButton";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { deleteAccessory } from "../stores/api_calls/car-workshop";

const Accessories = ({ accessories, fetchAccessories }) => {
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: accessories.total,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });

  const handleTableChange = (pagination) => {
    setTablePagination(pagination);
  };
    
    const handleDeleteAccessory = (id) => {
      deleteAccessory(id);
      fetchAccessories(tablePagination);
    };

  const columns = [
    {
      title: "",
      dataIndex: "images",
      width: "80px",
      render: (text, record) => (
        <div className="user-role-div">
          <img
            src={record?.images[0]?.metadata?.image_url}
            style={{ width: 50, height: 50 }}
            alt="image"
          />
        </div>
      ),
    },
    {
      title: "Accessory name",
      dataIndex: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "10%",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Accessory"
            Icon={<RiEdit2Fill />}
          />

          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Car Accessory"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => handleDeleteAccessory(record.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={accessories.data}
        pagination={tablePagination}
        loading={{
          indicator: <Spin size="large" />,
          spinning: accessories.isLoading,
        }}
        onChange={handleTableChange}
        bordered
        sticky
      />
    </>
  );
};

export default Accessories;
