export const validateCompanyForm = (data) =>{

    const requiredFields = [
        "firstName",
        "lastName",
        "companyName",
        "phoneNumbers",
        // "emailAdds",
        "companyStreet",
        "cityId",
    ]

    var validFieldCount = 0
    
    requiredFields.forEach((field)=>{
        if(data[field] && data[field].length){
            validFieldCount = validFieldCount + 1
            // console.log(field , true)
        }else{
            // console.log(field , false)
        }
    })

    if(validFieldCount===requiredFields.length){
        return true
    }else{
        return false
    }
}

