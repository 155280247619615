import { Modal, Input, Checkbox, Col, Row, Radio, Select, Button } from "antd";
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect, useRef } from "react"; 
import { error, success } from "../../contants/snackbars";
import { colorScheme } from "../../contants/variables";
import { addUser, editUser } from "../../stores/api_calls/users";
import { validateUserForm } from "../../stores/helpers/userHelpers";
import { IoMdCloseCircle } from "react-icons/io";
import "./dealer.scss";
import { BsPlusCircle } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import {  RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { FaUpload, FaRegFilePdf } from "react-icons/fa";
import { uploadImage } from "../../stores/api_calls/upload";
import { RiCloseCircleFill } from "react-icons/ri";
import { fetchRentalClients } from "../../stores/api_calls/rentals";
import { IconButton } from "../../custom_components/customButton";
import Validator from "../../stores/utils/validator";

const DealerForm = (props) => {
  const {
    isEdit,
    data,
    onCancel,
    visible,
    refetchLists,
    roles,
    permissions,
    clients,
    isDealer,
  } = props;
  const fileUploader = useRef(null);
  const fileUploader2 = useRef(null);
  const fileUploader3 = useRef(null);
  const fileUploader4 = useRef(null);
  const [selectedFile, setSelectedFile] = useState({ file: null, url: "" });
  const [selectedFile2, setSelectedFile2] = useState({ file: null, url: "" });
  const [selectedFile3, setSelectedFile3] = useState({ file: null, url: "" });
  const [selectedFile4, setSelectedFile4] = useState({ file: null, url: "" });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [visiblePhotoViewer, setVisiblePhotoViewer] = useState({photoTitle: "", photoUrl: ""});
  const [agentError, setAgentError] = useState([]);
  const [inputError, setInputError] = useState({});

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    clientId: "",
    email: "",
    roleId: "",
    contactNumbers: [],
    password: "P@ssw0rd",
    agents: [],
    address: "",
    imageUrl: "",
    dealerBannerImgUrl: "",
    isBiddingUser: true,
  });
  
  const [activePermission, setActivePermission] = useState([]);
  const [companies, setCompanies] = useState({ loading: false, data: [] });
  useEffect(() => {
    if (isEdit) {
      setForm({
        ...form,
        firstName: data.user_first_name,
        lastName: data.user_last_name,
        clientId: data.client_id,
        email: (data.user_contact_emails && data.user_contact_emails[0]) ? data.user_contact_emails[0].email : '-',
        // contactNumbers: data.user_contact_numbers.map((item) => item.number),
        contactNumbers: data.user_contact_numbers?.filter((el) => el.number).map((el) => el.number),
        address: data.address ?? "",
        agents: (data?.agents && data?.agents.length > 0) ? data.agents.filter(item => item.fullName !== '' && item.contactNumber !== '') : [],
        imageUrl: data.users_profile_cover_url,
        dealerBannerImgUrl: data.dealer_banner_image_url,
        newCarBannerImgUrl: data.new_car_banner_image_url,
        dealerInventoryLogo: data.dealer_inventory_logo,
        cover_image: data.users_profile_cover_url,
        dealer_banner_image: data.dealer_banner_image_url,
        new_car_banner_image: data.new_car_banner_image_url,
        dealer_inventory_logo: data.dealer_inventory_logo,
        companyId: data.rental_company_id,
        isBiddingUser: data.is_bidding_user ?? false,
      });

      setActivePermission(data.user_permissions);
    } else {
      setForm({
        ...form,
        firstName: "",
        lastName: "",
        email: "",
        clientId: "",
        contactNumber: "",
        contactNumbers: [],
        roleId: "619e5d67e7040000a2007b09",
        isBiddingUser: true,
      });
      setActivePermission([]);
    }

  }, [isEdit]);

  const handleFieldChange = (name, e) => {
    const value = e.target.value;
    if(name === "contactNumberValue") {
      if (/^\d*$/.test(value)) {
        setForm({ ...form, [name]: value })
      }
    } else if (name === "contactNumbers") {
      setForm({ ...form, [name]: e });
    } else {
      setForm({ ...form, [name]: value });
    }
  };


  const handleDropdownChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const onCheckboxChange = (permission, e) => {
    if (e.target.checked) {
      setActivePermission([...activePermission, permission]);
    } else {
      const filteredData = activePermission.filter((e) => e !== permission);
      setActivePermission(filteredData);
    }
  };

  const onClose = () => {
    setActivePermission([]);
    onCancel();
  };

  const onSubmit = async () => {
    setSubmitLoading(true);

    if(!validateAllFormFields()) {
      setSubmitLoading(false);
      return;
    }

    const user = { ...form };
    user.contactNumber = `+${form.contactNumber}`;
    user.permissions = activePermission;
    user.user_contact_emails = data.user_contact_emails;
    user.user_contact_numbers = form.contactNumbers;
    user.agents = form.agents;
    user.address = form.address;
    user.rental_company_id = form.companyId;
    user.isBiddingUser = form.isBiddingUser;

    const handleUpload = async (file, key) => {
      if (file.file) {
        try {
          const res = await uploadImage(file.file);
          // const res = await uploadImage(file.file, 1, data._id);
          if (res.data.success) {
            user[key] = res?.data?.data?.metadata?.image_url || null;
          }
        } catch (e) {
          setSubmitLoading(false);
          error(e.response.data.server_response);
        }
      }
    };

    try {
      if (!isEdit) {
        roles.forEach((el) => {
          if (
            (el.name === "Dealer" && isDealer) ||
            (el.name === "Advertiser" && !isDealer)
          ) {
            user.roleId = el._id;
          }
        });

        await handleUpload(selectedFile, "cover_image");
        await handleUpload(selectedFile2, "dealer_banner_image");
        await handleUpload(selectedFile3, "new_car_banner_image");
        await handleUpload(selectedFile4, "dealer_inventory_logo");

        handleAddUser(user);
      } else {
        user.id = data._id;

        await handleUpload(selectedFile, "cover_image");
        await handleUpload(selectedFile2, "dealer_banner_image");
        await handleUpload(selectedFile3, "new_car_banner_image");
        await handleUpload(selectedFile4, "dealer_inventory_logo");

        handleUpdateUser(user);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleAddUser = (user) => {
    const createUser = addUser(user, isDealer);
    createUser
      .then((res) => {
        
        if (res.data.success) {
          success("User Added");
          refetchLists();
          setSubmitLoading(false);
          resetForm();
          onClose();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        const errorResponse = error.response.data;
        if(errorResponse.error_code === 422) {
          const validationError = Object.keys(errorResponse.server_response).reduce((result, key) => {
            result[key] = errorResponse.server_response[key][0]
            return result;
          }, {});

          const emailError = getValuesByWildcardKey(validationError, 'user_contact_emails*');
          setInputError(previous => {
            return {...previous, email: emailError}
          });

          const contactError = getValuesByWildcardKey(validationError, 'user_contact_numbers*');
          setInputError(previous => {
            return {...previous, contact: contactError}
          });

        } else {
          error(
            `Opps! ${errorResponse.server_response} . Fetched Failed. Please try again later`
          );
        }
      });
  };

  const handleUpdateUser = (user) => {

    roles.forEach((el) => {
      if (el.name === data.user_role) {
        user.roleId = el._id;
      }
    });

    const updateUser = editUser(user);
    updateUser
      .then((res) => {
        if (res.data.success) {
          success("User Updated");
          refetchLists();
          setSubmitLoading(false);
          onClose();
          resetForm();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        const errorResponse = error.response.data;
        if(errorResponse.error_code === 422) {
          const validationError = Object.keys(errorResponse.server_response).reduce((result, key) => {
            result[key] = errorResponse.server_response[key][0]
            return result;
          }, {});

          const emailError = getValuesByWildcardKey(validationError, 'user_contact_emails*');
          setInputError(previous => {
            return {...previous, email: emailError}
          });

          const contactError = getValuesByWildcardKey(validationError, 'user_contact_numbers*');
          setInputError(previous => {
            return {...previous, contact: contactError}
          });

        } else {
          error(
            `Opps! ${errorResponse.server_response} . Fetched Failed. Please try again later`
          );
        }
      });
  };

  function getValuesByWildcardKey(obj, pattern) {
    // Convert the wildcard pattern to a regular expression
    const regex = new RegExp(`^${pattern.replace(/\*/g, '.*')}$`);
  
    // Find keys that match the pattern and return their values
    return Object.keys(obj)
      .filter(key => regex.test(key))
      .reduce((result, key) => {
        return obj[key];
      }, '');
  }

  const resetForm = () => {
    setForm({
      firstName: "",
      lastName: "",
      clientId: "",
      email: "",
      roleId: "",
      contactNumbers: [],
      password: "P@ssw0rd",
    });
    setSelectedFile({ file: "", url: "" });
    setSelectedFile2({ file: "", url: "" });
  };

  const handleAddContactNumber = (e) => {
    if (e.key === "Enter") {
      if(isValidSingaporePhoneNumber(e.target.value)) {
        setForm({
          ...form,
          contactNumberValue: "",
          contactNumbers: [...form.contactNumbers, e.target.value],
        });
      }
    }
  };

  function validateAllFormFields() {
    let validForm = true;

    if(!validateEmail()) {
      validForm = false;
    }

    if(!validateFirstName()) {
      validForm = false;
    }

    if(!validateLastName()) {
      validForm = false;
    }

    if(!validateContacts()) {
      validForm = false;
    }

    if(!validatePermissions()) {
      validForm = false;
    }

    if(isDealer) {
      if(!validateAddress()) {
        validForm = false;
      }
  
      if(!validateClientId()) {
        validForm = false;
      }
  
      if(!validateAgents()) {
        validForm = false;
      }

    }

    return validForm;
  }

  function validateAgents() {
    const agent_errors = getAgentErrors(form.agents);
    const filterAgentErrors = agent_errors.filter(item => item.fullName !== '' || item.contactNumber !== '');
    if(filterAgentErrors.length !== 0){
      setAgentError(agent_errors);
      return false;
    }

    return true;
  }

  function validateContacts() {
    if(form.contactNumbers.length === 0) {
      setInputError(previous => {
        return {...previous, contact: 'At least one contact number is required.'}
      })
      return false;
    } else {
      setInputError(previous => {
        return {...previous, contact: ''}
      })
      return true;
    }
  }

  function validatePermissions() {
    if(activePermission.length === 0) {
      setInputError(previous => {
        return {...previous, permission: 'At least one permission is required.'}
      })
      return false;
    } else {
      setInputError(previous => {
        return {...previous, permission: ''}
      })
      return true;
    }
  }

  function validateEmail() {
    const {value, error} = new Validator(form.email).Required().Email();
    setInputError(previous => {
      return {...previous, email: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function validateFirstName() {
    const {value, error} = new Validator(form.firstName).Required().Max(20);
    setInputError(previous => {
      return {...previous, firstName: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function validateLastName() {
    const {value, error} = new Validator(form.lastName).Required().Max(20);
    setInputError(previous => {
      return {...previous, lastName: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function validateAddress() {
    const {value, error} = new Validator(form.address).Required().Max(100);
    setInputError(previous => {
      return {...previous, address: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function validateClientId() {
    const {value, error} = new Validator(form.clientId).Required();
    setInputError(previous => {
      return {...previous, clientId: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function isValidSingaporePhoneNumber(phoneNumber) {
    const regex = /^[89]\d{7}$/;
    return regex.test(phoneNumber);
  }

  const deleteNumber = (number) => {
    const filteredArray = form.contactNumbers.filter((el) => el !== number);
    setForm({
      ...form,
      contactNumbers: filteredArray,
    });
  };

  const handleUpdateAgent = (key, value, index) => {
    if(key === 'contactNumber') {
      if (value.length < 9) {
        const newAgents = form.agents.map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        );

        setForm(previous => {
          return {...previous, agents: newAgents};
        });
      }
    } else {
      const newAgents = form.agents.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      );
  
      setForm(previous => {
        return {...previous, agents: newAgents};
      });
    }
  };

  
  const handleCheckAgentInput = (key, value, index) => {
    let errors = [...agentError];
    if(errors[index]) {
      if(key === 'contactNumber') {        
        if(isValidSingaporePhoneNumber(value)) {
          errors[index].contactNumber = '';
        }
      } else {
        if(value !== '') {
          errors[index].fullName = '';
        }
      }

      setAgentError(errors);
    }
  };

  useEffect(() => {
    onGetCompanies();
  }, []);

  const addAgent = () => {
    const agents = [...form.agents];
    if(agents.length === 0) {
      setForm({
        ...form,
        agents: [...form.agents, { fullName: "", contactNumber: "" }],
      });
    } else {
      const emptyAgents = getAgentErrors(agents);

      if(emptyAgents.filter(item => item.fullName !== '' || item.contactNumber !== '').length === 0) {
        setForm({
          ...form,
          agents: [...form.agents, { fullName: "", contactNumber: "" }],
        });
      }
      setAgentError(emptyAgents);
    }
  };

  const getAgentErrors = (agents) => {
    const emptyAgents = agents.map((item, index) => {
      const {fullName, contactNumber} = item;
      let agentError = {fullName: '', contactNumber: ''};
      if(fullName === '') {
        agentError.fullName = 'Full name is required.'
      }

      if(!isValidSingaporePhoneNumber(contactNumber)) {
        agentError.contactNumber = 'Invalid contact number.'
      }
      return agentError;
    });

    return emptyAgents;
  }

  const handleRemoveAgent = (index) => {
    const agents = [...form.agents];
    const agentErrors = [...agentError];
    const updateAgents = agents.filter((_, i) => i !== index);
    setForm({
      ...form,
      agents: updateAgents,
    });

    const updateAgentErrors = agentErrors.filter((_, i) => i !== index);
    setAgentError(updateAgentErrors);
  }

  const handleOpenGallery = () => {
    fileUploader.current.click();
  };
  const handleOpenGallery2 = () => {
    fileUploader2.current.click();
  };
  const handleOpenGallery3 = () => {
    fileUploader3.current.click();
  };
  const handleOpenGallery4 = () => {
    fileUploader4.current.click();
  };

  const handleImageChange = (e) => {
    setSelectedFile({ ...selectedFile, file: null, url: "" });

    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile({ ...selectedFile, file: file, url: url });
      setForm({ ...form, imageUrl: url });
    }
  };

  const handleImageChange2 = (e) => {
    setSelectedFile2({ ...selectedFile2, file: null, url: "" });

    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile2({ ...selectedFile2, file: file, url: url });
      setForm({ ...form, dealerBannerImgUrl: url });
    }
  };

  const handleImageChange3 = (e) => {
    setSelectedFile3({ ...selectedFile3, file: null, url: "" });

    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile3({ ...selectedFile3, file: file, url: url });
      setForm({ ...form, newCarBannerImgUrl: url });
    }
  };

  const handleImageChange4 = (e) => {
    setSelectedFile4({ ...selectedFile4, file: null, url: "" });

    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile4({ ...selectedFile4, file: file, url: url });
      setForm({ ...form, dealerInventoryLogo: url });
    }
  };

  const onGetCompanies = () => {
    setCompanies({ ...companies, loading: true });
    const obj = { keyword: "", limit: 10000, page: 1 };
    const getCompanies = fetchRentalClients(obj);
    getCompanies
      .then((res) => {
        if (res.data.success) {
          setCompanies({ ...companies, loading: true, data: res.data.data });
        }
      })
      .catch((e) => console.log(e));
  };

  const downloadAttachedFile = async (url, name) => {
    const fileExtension = getFileExtension(url);
    const mimeType = fileExtension === 'pdf' ? 'application/pdf' : 'image/' + fileExtension;
    const imgBlog = await fetch(url)
                    .then((res) => res.arrayBuffer())
                    .then((buffer) => new Blob([buffer], {type: mimeType}));

    const link = document.createElement("a");
    link.href = URL.createObjectURL(imgBlog);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  const getFileExtension = (url) => {
    try {
      const lastDotPosition = url.lastIndexOf('.');
      if (lastDotPosition === -1) return '';
      return url.substring(lastDotPosition + 1);
    } catch (e) {
      return '';
    }
  }

  function __renderErrorMessage(message) {
    return(
      <div className="form-container error-content">
        <p className="form-label"></p>
        <p style={{width: '100%', color: 'red'}}><small>{message}</small></p>
      </div>
    );
  }

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEdit ? "Edit" : "Add"} ${
          isDealer ? "BIDDING Dealer" : "BIDDING Direct Seller"
        }`}
        visible={visible}
        onOk={onSubmit}
        okButtonProps={{ disabled: submitLoading }}
        onCancel={onClose}
        okText={`${isEdit ? "Save" : "Submit"}`}
        width={'800px'}
        confirmLoading={submitLoading}
      >
        <div className="form-container">
          <p className="form-label">
            First Name <span>*</span> :{" "}
          </p>
          <Input
            value={form.firstName}
            onChange={(text) => handleFieldChange("firstName", text)}
            placeholder="Please type First Name"
          />
        </div>
        { inputError.firstName && __renderErrorMessage(inputError.firstName) }
        <div className="form-container">
          <p className="form-label">
            Last Name <span>*</span> :{" "}
          </p>
          <Input
            value={form.lastName}
            onChange={(text) => handleFieldChange("lastName", text)}
            placeholder="Please type Last Name"
          />
        </div>
        { inputError.lastName && __renderErrorMessage(inputError.lastName) }

        {isDealer && (
          <>
            <div className="form-container">
              <p className="form-label">
                Dealer Company Name <span>*</span> :{" "}
              </p>
              <Select
                showSearch
                value={form.clientId || undefined}
                onChange={(value) => handleDropdownChange("clientId", value)}
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {clients.map((el, i) => (
                  <Select.Option key={i} value={el._id}>
                    {`${el.name}`}
                  </Select.Option>
                ))}
              </Select>
            </div>
            { inputError.clientId && __renderErrorMessage(inputError.clientId) }
            <div className="form-container">
              <p className="form-label">
                Company Registration Type : 
              </p>
              <Input
                value={(data?.dealer_company_info && data?.dealer_company_info?.registrationType) ? data?.dealer_company_info?.registrationType : 'Corporate'}
                onChange={(text) => {}}
                placeholder=""
                disabled={true}
              />
            </div>
          </>
        )}

        <div className="form-container contact-number">
          <p className="form-label">
            Contact Numbers <span>*</span> :{" "}
          </p>
          <Input
            placeholder='Press "Enter" after each contact number.'
            value={form.contactNumberValue}
            onChange={(text) => handleFieldChange("contactNumberValue", text)}
            onKeyDown={(text) => handleAddContactNumber(text)}
            maxLength={8}
          />
        </div>
        <div className="form-container description">
          <p className="form-label"></p>
          <p style={{width: '100%'}}>
            <small>Please insert mobile number only start with <b>8</b> or <b>9</b>.</small>
          </p>
        </div>
        {
          form?.contactNumbers.length > 0 ?
          <div className="form-container tags-container">
            <p className="form-label"></p>
            <div style={{width: '100%'}}>
              {form.contactNumbers?.map((number, i) => (
                <div key={i} className="tag-container">
                  <IoMdCloseCircle onClick={() => deleteNumber(number)} />
                  <p>{number}</p>
                </div>
              ))}
            </div>
          </div>
          :
          <>
            {__renderErrorMessage('At least one contact number is required.')}
          </>
        }
        
        <div className="form-container">
          <p className="form-label">
            Email Address <span>*</span> :{" "}
          </p>
          <Input
            value={form.email}
            onChange={(text) => handleFieldChange("email", text)}
            placeholder="Please type Valid Email Address"
            readOnly={isEdit}
          />
        </div>
        { inputError.email && __renderErrorMessage(inputError.email) }

        {/* {isDealer && (
          <div className="form-container">
            <p className="form-label">
              Rental Company <span></span> :{" "}
            </p>
            <Select
              showSearch
              value={form.companyId || undefined}
              onChange={(value) => handleDropdownChange("companyId", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value={null}>
                -
              </Select.Option>
              {companies.data.map((el, i) => (
                <>
                  <Select.Option key={i} value={el._id}>
                    {`${el.company_name}`}
                  </Select.Option>
                </>
              ))}
            </Select>
          </div>
        )} */}

        {isDealer && (
          <>
            <div className="form-container">
              <p className="form-label">
                Address <span>*</span> :{" "}
              </p>
              <Input
                value={form.address}
                onChange={(text) => handleFieldChange("address", text)}
                placeholder="Address"
              />
            </div>
            {inputError.address && __renderErrorMessage(inputError.address)}
          </>
        )}

        <hr style={{margin: '20px 0'}} />

        {isDealer && (
          <div>
            <p className="permision-label">Sales Advisor: </p>

            {form?.agents?.map((item, i) => (
              <Row style={{ marginBottom: "10px" }} key={i} gutter={[5, 5]}>
                <Col span={11}>
                  <small>Full name <span style={{color: 'red'}}>*</span></small>
                  <Input
                    value={item.fullName}
                    onChange={(e) =>
                      handleUpdateAgent("fullName", e.target.value, i)
                    }
                    onBlur={(e) => handleCheckAgentInput("fullName", e.target.value, i)}
                    placeholder="Fullname"
                  />
                  {
                    agentError[i]?.fullName && <small style={{color: 'red'}}>{agentError[i]?.fullName}</small>
                  }
                </Col>
                <Col span={11}>
                  <small>Contact number <span style={{color: 'red'}}>*</span> (Start with 8 or 9).</small>
                  <Input
                    value={item.contactNumber}
                    onChange={(e) =>
                      handleUpdateAgent("contactNumber", e.target.value, i)
                    }
                    onBlur={(e) => handleCheckAgentInput("contactNumber", e.target.value, i)}
                    placeholder="Contact Number"
                  />
                  {
                    agentError[i]?.contactNumber && <small style={{color: 'red'}}>{agentError[i]?.contactNumber}</small>
                  }
                </Col>
                <Col span={2} style={{alignSelf: 'start', marginTop: '25px'}}>
                  <IconButton
                      className="icon-button edit"
                      tootTipTitle="Remove Agent"
                      Icon={<RiCloseCircleFill size={24} />}
                      onClick={() => handleRemoveAgent(i)}
                    />
                </Col>
              </Row>
            ))}
            <Button
              style={{ marginTop: "5px", marginBottom: "15px" }}
              onClick={addAgent}
              type="primary"
            >
              Add Sales Advisor
            </Button>
          </div>
        )}

        <div>
          <p className="permision-label">
            Permissions <span>(Check at least one) *</span> :{" "}
          </p>

          {isDealer ? (
            <Row gutter={[5, 5]}>
              {permissions
                .filter((el) => el.user_type !== "admin" && el.code == "bid_car_listing")
                .map((permission, idx) => (
                  <Col span={12} key={idx}>
                    <div
                      className="permission-checklist"
                      key={idx}
                      style={{
                        background: activePermission.includes(permission.code)
                          ? colorScheme.lightgray
                          : colorScheme.offwhite,
                      }}
                    >
                      <Checkbox
                        valuePropName="checked"
                        value={permission}
                        checked={activePermission.includes(permission.code)}
                        onChange={(e) => onCheckboxChange(permission.code, e)}
                      >
                        {permission.name}
                      </Checkbox>
                    </div>
                  </Col>
                ))}
            </Row>
          ) : (
            <Row gutter={[5, 5]}>
              {permissions
                .filter((el) => el.user_type !== "admin" && el.code == "bid_car_listing")
                .map((permission, idx) => (
                  <Col span={12} key={idx}>
                    <div
                      className="permission-checklist"
                      key={idx}
                      style={{
                        background: activePermission.includes(
                          permission.code
                        )
                          ? colorScheme.lightgray
                          : colorScheme.offwhite,
                      }}
                    >
                      <Checkbox
                        valuePropName="checked"
                        value={permission}
                        checked={activePermission.includes(permission.code)}
                        onChange={(e) =>
                          onCheckboxChange(permission.code, e)
                        }
                      >
                        {permission.name}
                      </Checkbox>
                    </div>
                  </Col>
                ))}
            </Row>
          )}
        </div>

        <div style={{ marginTop: 24 }} />
        {isDealer && (
          <>
            <div>
              <p style={{ margin: "5px 0x", color: colorScheme.red }}>
                {`To avoid stretching of image in the website. The image has to be (.jpg , .png) with 1200 pixels width`}
              </p>
            </div>

            <div className="form-container">
              <p className="form-label">Profile Banner Image: </p>
              <div className="upload-container" onClick={handleOpenGallery}>
                <FaUpload />
                <p className="banner-p">UPLOAD BANNER PHOTO</p>
              </div>

              <input
                ref={fileUploader}
                id="getFile"
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </div>

            {form.imageUrl && (
              <div className="image-preview-container">
                <div
                  className="removeIcon"
                  onClick={() => setForm({ ...form, imageUrl: "" })}
                >
                  <RiCloseCircleFill size={24} />
                </div>
                <img src={form.imageUrl} />
              </div>
            )}

            <div style={{ marginTop: 24 }} />

            {/* <div className="form-container">
              <p className="form-label">Used Car Banner Ad: </p>
              <div className="upload-container" onClick={handleOpenGallery2}>
                <FaUpload />
                <p className="banner-p">UPLOAD BANNER PHOTO</p>
              </div>

              <input
                ref={fileUploader2}
                id="getFile"
                type="file"
                hidden
                onChange={handleImageChange2}
              />
            </div>

            {form.dealerBannerImgUrl && (
              <div className="image-preview-container">
                <div
                  className="removeIcon"
                  onClick={() => setForm({ ...form, dealerBannerImgUrl: "" })}
                >
                  <RiCloseCircleFill size={24} />
                </div>
                <img src={form.dealerBannerImgUrl} />
              </div>
            )} */}

            {/* <div style={{ marginTop: 24 }} />

            <div className="form-container">
              <p className="form-label">New Car Banner Ad: </p>
              <div className="upload-container" onClick={handleOpenGallery3}>
                <FaUpload />
                <p className="banner-p">UPLOAD BANNER PHOTO</p>
              </div>

              <input
                ref={fileUploader3}
                id="getFile"
                type="file"
                hidden
                onChange={handleImageChange3}
              />
            </div>

            {form.newCarBannerImgUrl && (
              <div className="image-preview-container">
                <div
                  className="removeIcon"
                  onClick={() => setForm({ ...form, newCarBannerImgUrl: "" })}
                >
                  <RiCloseCircleFill size={24} />
                </div>
                <img src={form.newCarBannerImgUrl} />
              </div>
            )} */}

            {/* <div style={{ marginTop: 24 }} /> */}

            <div className="form-container">
              <p className="form-label">Dealer Inventory Logo: </p>
              <div className="upload-container" onClick={handleOpenGallery4}>
                <FaUpload />
                <p className="banner-p">UPLOAD BANNER PHOTO</p>
              </div>

              <input
                ref={fileUploader4}
                id="getFile"
                type="file"
                hidden
                onChange={handleImageChange4}
              />
            </div>

            {form.dealerInventoryLogo && (
              <div className="image-preview-container">
                <div
                  className="removeIcon"
                  onClick={() => setForm({ ...form, dealerInventoryLogo: "" })}
                >
                  <RiCloseCircleFill size={24} />
                </div>
                <img src={form.dealerInventoryLogo} />
              </div>
            )}

            
            {
              isEdit &&
              <>
                <div className="attached-container">
                  <p className="form-label"> Acra Business Profile: </p>
                  {(data?.dealer_company_info && data?.dealer_company_info?.acraBusinessProfile) && (
                    <div style={{display: 'flex', alignItems: 'end'}}>
                    {
                      data?.dealer_company_info?.acraBusinessProfile.toLowerCase().endsWith('.pdf') ?
                      <a className="attached-preview-container" href={data?.dealer_company_info?.acraBusinessProfile} target="_blank" > <FaRegFilePdf size={50} /></a>
                      :
                      <div className="attached-preview-container" onClick={() => setVisiblePhotoViewer({photoTitle: 'Acra Business Profile', photoUrl: data?.dealer_company_info?.acraBusinessProfile})}>
                        <img style={{height: '100%', width: '100%'}} src={data?.dealer_company_info?.acraBusinessProfile} />
                      </div>
                    }
                      <Button
                          style={{ margin: "0 0 0 15px" }}
                          onClick={() => {downloadAttachedFile(data?.dealer_company_info?.acraBusinessProfile, `(${data.user_client})-acra-business-profile`)}}
                          type="primary"
                          size="small"
                          icon={<DownloadOutlined />}
                        >
                        Download
                      </Button>
                      
                    </div>
                  )}
                </div>

                <div className="attached-container">
                  <p className="form-label"> Motor Trade Insurance: </p>
                  {(data?.dealer_company_info && data?.dealer_company_info?.motorTradeInsurance) && (
                    <div style={{display: 'flex', alignItems: 'end'}}>
                      {
                        data?.dealer_company_info?.motorTradeInsurance.toLowerCase().endsWith('.pdf') ?
                        <a className="attached-preview-container" href={data?.dealer_company_info?.motorTradeInsurance} target="_blank" > <FaRegFilePdf size={50} /></a>
                        :
                        <div className="attached-preview-container" onClick={() => setVisiblePhotoViewer({photoTitle: 'Motor Trade Insurance', photoUrl: data?.dealer_company_info?.motorTradeInsurance})}>
                          <img style={{height: '100%', width: '100%'}} src={data?.dealer_company_info?.motorTradeInsurance} />
                        </div>
                      }
                      <Button
                          style={{ margin: "0 0 0 15px" }}
                          onClick={() => {downloadAttachedFile(data?.dealer_company_info?.motorTradeInsurance, `(${data.user_client})-motor-trade-insurance`)}}
                          type="primary"
                          size="small"
                          icon={<DownloadOutlined />}
                        >
                        Download
                      </Button>
                    </div>
                  )}
                </div>

                <div className="attached-container">
                  <p className="form-label"> Name Card: </p>
                  {(data?.dealer_company_info && data?.dealer_company_info?.nameCard) && (
                    <div style={{display: 'flex', alignItems: 'end'}}>
                      {
                        data?.dealer_company_info?.nameCard.toLowerCase().endsWith('.pdf') ?
                        <a className="attached-preview-container" href={data?.dealer_company_info?.nameCard} target="_blank" > <FaRegFilePdf size={50} /></a>
                        :
                        <div className="attached-preview-container" onClick={() => setVisiblePhotoViewer({photoTitle: 'Name Card', photoUrl: data?.dealer_company_info?.nameCard})}>
                          <img style={{height: '100%', width: '100%'}} src={data?.dealer_company_info?.nameCard} />
                        </div>
                      }
                      <Button
                          style={{ margin: "0 0 0 15px" }}
                          onClick={() => {downloadAttachedFile(data?.dealer_company_info?.nameCard, `(${data.user_client})-name-card`)}}
                          type="primary"
                          size="small"
                          icon={<DownloadOutlined />}
                        >
                        Download
                      </Button>
                    </div>
                  )}
                </div>

                <div className="attached-container">
                  <p className="form-label"> Authorized Letter: </p>
                  {(data?.dealer_company_info && data?.dealer_company_info?.authorizedLetter) && (
                    <div style={{display: 'flex', alignItems: 'end'}}>
                    {
                      data?.dealer_company_info?.authorizedLetter.toLowerCase().endsWith('.pdf') ?
                      <a className="attached-preview-container" href={data?.dealer_company_info?.authorizedLetter} target="_blank" > <FaRegFilePdf />Pdf File </a>
                      :
                      <div className="attached-preview-container" onClick={() => setVisiblePhotoViewer({photoTitle: 'Authorized Letter', photoUrl: data?.dealer_company_info?.authorizedLetter})}>
                        <img style={{height: '100%', width: '100%'}} src={data?.dealer_company_info?.authorizedLetter} />
                      </div>
                      }
                      <Button
                          style={{ margin: "0 0 0 15px" }}
                          onClick={() => {downloadAttachedFile(data?.dealer_company_info?.nameCard, `(${data.user_client})-authorized-letter`)}}
                          type="primary"
                          size="small"
                          icon={<DownloadOutlined />}
                        >
                        Download
                      </Button>
                      
                    </div>
                  )}
                </div>              
              </>
            }

          </>
        )}
      </Modal>
      <Modal
        className="photo-viewer-modal"
        title={visiblePhotoViewer.photoTitle}
        visible={visiblePhotoViewer.photoTitle !== ""}
        onCancel={() => setVisiblePhotoViewer({photoTitle: '', photoUrl: ''})}
        width={'100%'}
        footer={null}
      >
        <div className="photo-viewer-modal-content">
          <img src={visiblePhotoViewer.photoUrl} alt="Attachment Photo" />
        </div>
      </Modal>
    </div>
  );
};

export default DealerForm;
