import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};


export const editTerms = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/terms-and-conditions/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const fetchTerm = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/terms-and-conditions/show?token=${encodedData}`,
    axiosConfig
  );
};

export const editAbout = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/about-us/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const fetchAbout = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/about-us/show?token=${encodedData}`,
    axiosConfig
  );
};

export const editPrivacyPolicy = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/privacy-policy/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const fetchPrivacyPolicy = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/privacy-policy/show?token=${encodedData}`,
    axiosConfig
  );
};

export const editWhatsAppNumber = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/about-us/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const fetchWhatsAppNumber = async (data) => {
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/about-us/show?token=${encodedData}`,
    axiosConfig
  );
};