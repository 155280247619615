import React, { useState, useEffect } from "react";
import { fetchTotalUnverifiedCars } from "../../stores/api_calls/dashboard";
import { GoUnverified } from "react-icons/go";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./card.scss";
import { useNavigate } from "react-router-dom";

const UnverifiedCommercial = () => {
  const navigate = useNavigate();
  const [unverifiedUsedCars, setUnverifiedUsedCars] = useState();
  const [unverifiedNewCars, setUnverifiedNewCars] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const getUnVerifiedUsedCars = fetchTotalUnverifiedCars("Commercial");

    getUnVerifiedUsedCars
      .then((res) => {
        if (res.data) {
          if (res.data.success) {
            setUnverifiedUsedCars(res.data.data);
            setIsLoading(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <div className="unverified header-card">
        <p className="header-text">
          Unverified <br /> Commercial
        </p>
        <GoUnverified />
      </div>
      <div className="unverified body-card">
        <p className="verified-text">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("../cars/unverified-commercial", { replace: true })}
          >
            Total :{" "}
            {isLoading
              ? "fetching"
              : !isLoading && unverifiedUsedCars
              ? unverifiedUsedCars.total_unverified
              : null}
          </span>
        </p>
        <div className='goIcon' onClick={()=>navigate("../cars/unverified-commercial", { replace: true })}>
            <AiOutlineArrowRight/>
        </div>
        
      </div>
    </div>
  );
};

export default UnverifiedCommercial;
