import React, { useState, useEffect } from "react";
import { fetchTotalVerifiedCars } from "../../stores/api_calls/dashboard";
import { GoVerified } from "react-icons/go";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./card.scss";
import { useNavigate } from "react-router-dom";

const VerifiedBidCars = () => {
  const navigate = useNavigate();
  const [verifiedUsedCars, setVerifiedUsedCars] = useState();
//   const [verifiedNewCars, setVerifiedNewCars] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const isBidding = true;

    const getVerifiedUsedCars = fetchTotalVerifiedCars("Used", isBidding);

    getVerifiedUsedCars
      .then((res) => {
        if (res.data) {
          if (res.data.success) {
            setVerifiedUsedCars(res.data.data);
            setIsLoading(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

//   useEffect(() => {
//     setIsLoading(true);

//     const getVerifiedNewCars = fetchTotalVerifiedCars("New");

//     getVerifiedNewCars
//       .then((res) => {
//         if (res.data) {
//           if (res.data.success) {
//             setVerifiedNewCars(res.data.data);
//             setIsLoading(false);
//           }
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   }, []);

  return (
    <div>
      <div className="bid-verified header-card">
        <p className="header-text">
          Verified <br /> Bid Cars
        </p>
        <GoVerified />
      </div>
      <div className="verified body-card">
        <p className="verified-text">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("../car-bids/verified", { replace: true })}
          >
            Total :{" "}
            {isLoading
              ? "fetching"
              : !isLoading && verifiedUsedCars
              ? verifiedUsedCars.total_verified
              : null}
          </span>
        </p>
        <div className='goIcon' onClick={()=>navigate("../car-bids/verified", { replace: true })}>
            <AiOutlineArrowRight/>
        </div>
      </div>
    </div>
  );
};

export default VerifiedBidCars;
