import React , {useEffect , useState , createContext} from 'react'

export const UserContext = createContext();

export const UserContextProvider = (props) => {

    const [userConfig , setUserConfig] = useState({
        isLoggedIn:false,
        userDetails:{}
    })

    useEffect(()=>{
        const loginValue = JSON.parse(localStorage.getItem('isLoggedIn'))
        const userValue = JSON.parse(localStorage.getItem('userDetails'))
        setUserConfig({
            ...userConfig,
            isLoggedIn:loginValue,
            userDetails:userValue
        })
    },[])

    useEffect(()=>{
        if(userConfig){
            localStorage.setItem('isLoggedIn' , JSON.stringify(userConfig.isLoggedIn))
            localStorage.setItem('userDetails' , JSON.stringify(userConfig.userDetails))
        }
    },[userConfig])

    return (
        <UserContext.Provider value={[userConfig,setUserConfig]}>
            {props.children}
        </UserContext.Provider>
    )
}
