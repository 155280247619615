import axios from 'axios'
import Kjur from '../utils/jwt';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`,
    }
};

export const uploadImage = async (file, orderNumber, waterMark, directSeller) => {

    axiosConfig.headers['Content-Type'] = 'multipart/form-data'

    const form = new FormData();

    form.append('image', file);

    form.append('type', 'image');

    form.append("order_number", orderNumber);
    
    if(waterMark) {
        form.append("watermark", true);
    }
    if(directSeller) {
        form.append("direct_seller_watermark", directSeller);
    }

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/images`,
        form,
        axiosConfig
    )
}

export const download = async (id) => {
    const data = {
        id: id
    }

    axiosConfig.headers['Content-Type'] = 'application/json'

    const encodedData = Kjur.encode(data)

    return await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/admin/products/download`, //your url
        method: 'POST',
        responseType: 'blob', // important
        headers:axiosConfig.headers,
        data:JSON.stringify({token:encodedData})
    })
    
}

const formatArray = (data) => {

    data.map((el , i)=>{
        if(i > 0){
            el = ` ${el}`
        }
    })
}

export const sendSMS = async (payload) => {

    const numString = payload.numberArray.map((el,i)=>i>0? ` ${el}` : el)

    const data = {
        message : payload.message,
        numbers : numString.toString()
    }

    const encodedData = Kjur.encode(data)

    console.log(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/trigger-sms`,
        {token:encodedData},
        axiosConfig
    )
}

export const uploadVideo = async (file) => {

    axiosConfig.headers['Content-Type'] = 'multipart/form-data'

    const form = new FormData();

    form.append("file", file);
    form.append('type', 'video');

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/files`,
        form,
        axiosConfig
    )
}
