import { Row , Col, Input, Modal } from 'antd';
import React , {useState , useEffect} from 'react';
import { IconButton } from '../../custom_components/customButton';
import { RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri';
import { addSection, deleteSection, editSection, fetchSections } from '../../stores/api_calls/articles';
import { error, success } from '../../contants/snackbars';

const SectionForm = (props) => {

    const {visible , onCancel , sections , setSections} = props
    const [isEdit ,setIsEdit] = useState(false)
    const [deleteConfirm , setDeleteConfirm] = useState(false)
    const [searchText , setSearchText] = useState("")
    const [pagination , setPagination] = useState({
        current: 1,
        pageSize: 10,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const [form , setForm] = useState({
        sectionId:"",
        sectionName:"",
        articleCount:0
    })

    useEffect(()=>{
        fetchData()
    },[])

    const fetchData = () => {
        const filter = {
            keyword : searchText,
            page : pagination.current,
            limit:pagination.pageSize
        }
        const getSections = fetchSections(filter)

        getSections.then((res)=>{
            if(res.data.success){
                // const sectionsArr = sections.concat(res.data.data)
                setSections([{section_name:"All" , _id:"0"} , ...res.data.data])
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const handleFieldChange = (e) => {
        setForm({...form , sectionName : e.target.value})
    }

    const onEdit = (item) => {
        setIsEdit(true)
        setForm({...form , sectionName : item.section_name , sectionId:item._id})
    }

    const onDelete = (item) => {
        setForm({...form , sectionId : item._id})
        setDeleteConfirm(true)
    }

    const onDeleteConfirm = (confirm) => {
        if(confirm){
            const removeSection = deleteSection(form.sectionId)
            removeSection.then((res)=>{
                if(res.data.success){
                    const filteredData = sections.filter((el)=>el._id!==form.sectionId)
                    setSections(filteredData)
                    success("Section deleted successfully.")
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }
        setDeleteConfirm(false)
    }

    const handleEnter = (e) => {
        if(e.key==="Enter"){
            if(isEdit){
                const updateSection = editSection(form)
                updateSection.then((res)=>{
                    if(res.data.success){
                        console.log(res.data.data)
                        const section_name = form.sectionName
                        const updatedData = sections.map(el => (el._id === form.sectionId ? {...el, section_name } : el))
                        setSections(updatedData)
                        success("Section updated successfully.")
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
                })
            }else{
                const createSection = addSection(form.sectionName)
                createSection.then((res)=>{
                    if(res.data.success){
                        setSections([...sections , res.data.data])
                        success("Section added successfully.")
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
                })
            }
            setIsEdit(false)
            setForm({...form , sectionName:"" , sectionId:""})
        }
    }

    return (
        <div>
            <Modal
                className='formModal'
                title={`Manage Sections`}
                visible={visible}
                onCancel={onCancel}
                footer={null}
            >
                <div>
                    <div style={{marginBottom:"10px"}}>
                        <p>Press "Enter" to {isEdit? "update" : "create new"} section</p>
                        <Input
                            placeholder='Input Section Name Here'
                            value={form.sectionName}
                            onChange={(e)=>handleFieldChange(e)}
                            onKeyDown={(e)=>handleEnter(e)}
                        />
                    </div>

                    {deleteConfirm?
                        <p className='confirm-delete'>
                            Are you sure you want to delete this section? Deleting this section will also delete all articles inside it. <span className='yes' onClick={()=>onDeleteConfirm(true)}>Yes</span> or <span className='no' onClick={()=>onDeleteConfirm(false)}>No</span>
                        </p>
                    :null}


                    {sections.map((section,index)=>
                        <div key={index} className='section-container'>
                            <Row>
                                <Col span={20}>
                                    <p>{section.section_name}</p>
                                </Col>
                                {/* <Col span={10}>
                                    <div>
                                        <p>{section.article_count} Article(s)</p>
                                    </div>
                                </Col> */}
                                {section._id!=="0"?
                                    <Col span={4}>
                                        <div className='icon-container'>
                                            <IconButton 
                                                className='icon-button edit' 
                                                tootTipTitle="Edit Section" 
                                                Icon={<RiEdit2Fill/>} 
                                                onClick={()=>onEdit(section)} 
                                            />
                                            <IconButton 
                                                className='icon-button delete' 
                                                tootTipTitle="Delete Section" 
                                                Icon={<RiDeleteBin2Fill/>} 
                                                onClick={()=>onDelete(section)}
                                            />
                                        </div>
                                    </Col>
                                :null}
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default SectionForm
