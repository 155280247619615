import { Input, Modal } from "antd";
import React, { useState, useEffect, useContext, useRef } from "react";
import "./insurance.scss";
import { error, success } from "../../contants/snackbars";
import { UserContext } from "../../stores/contexts/userContext";
import { IoMdCloseCircle } from "react-icons/io";
import { addInsurance, editInsurance } from "../../stores/api_calls/insurances";
import { FaUpload } from "react-icons/fa";
import { uploadImage } from "../../stores/api_calls/upload";

const InsuranceForm = ({ visible, isEditing, data, onCancel, fetchData }) => {
  const [userConfig, setUserConfig] = useContext(UserContext);
  const profile = userConfig.userDetails;
  const [requestLoading, setRequestLoading] = useState(false);
  const fileUploader = useRef(null);
  const [selectedFile, setSelectedFile] = useState({ file: null, url: "" });

  const [form, setForm] = useState({
    name: "",
    emails: [],
    imageUrl: "",
  });

  useEffect(() => {
    if (isEditing) {
      setForm({
        id: data.id,
        name: data.name,
        emails: data.emails,
        image_id: data.image_id["$oid"],
        imageUrl: data.image.metadata.featured_url,
      });
    }
  }, [isEditing, data]);

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleAddEmails = (e) => {
    if (e.key === "Enter") {
      setForm({
        ...form,
        emailValue: "",
        emails: [...form.emails, e.target.value],
      });
    }
  };

  const resetForm = () => {
    setForm({
      name: "",
      description: "",
      emails: [],
    });
  };

  const deleteEmail = (email) => {
    const filteredArray = form.emails.filter((el) => el !== email);
    setForm({
      ...form,
      emails: filteredArray,
    });
  };

  const refetchLists = () => {
    const pagination = {
      page: 1,
      limit: 10,
    };
    fetchData(pagination);
  };

  const onSubmit = () => {
    setRequestLoading(true);
    const upload = uploadImage(selectedFile.file, 1);
    var data = form;

    upload
      .then((res) => {
        if (res.data.success) {
          data.image_id = res.data.data._id;

          const createInsurance = addInsurance(data);

          createInsurance
            .then((res) => {
              if (res.data.success) {
                onCancel();
                success("Insurance Company Successfully Added");
                refetchLists();
                resetForm();
                setRequestLoading(false);
              }
            })
            .catch((e) => {
              setRequestLoading(false);
              error(
                `Opps! Something went wrong . Fetched Failed. Please try again later`
              );
            });
        }
      })
      .catch((e) => {
        error(e.response.data.server_response);
      });
  };

  const onUpdate = () => {
    setRequestLoading(true);
    var data = form;

    if (selectedFile.file) {
      const upload = uploadImage(selectedFile.file);
      upload
        .then((res) => {
          if (res.data.success) {
            data.image_id = res.data.data._id;
            onUpdateInsurance(data);
          }
        })
        .catch((e) => {
          setRequestLoading(false);
          error(e.response.data.server_response);
        });
    } else {
      onUpdateInsurance(data);
    }
  };

  const onUpdateInsurance = (data) => {
    setRequestLoading(true);
    const updateInsurance = editInsurance(data);

    updateInsurance
      .then((res) => {
        if (res.data.success) {
          refetchLists();
          success("Insurance Company Updated Successfully");
          onCancel();
          setRequestLoading(false);
          resetForm();
        }
      })
      .catch((e) => {
        setRequestLoading(false);
        error(
          `Opps! Something went wrong . Fetched Failed. Please try again later`
        );
      });
  };

  const handleOpenGallery = () => {
    fileUploader.current.click();
  };

  const handleImageChange = (e) => {
    setSelectedFile({ ...selectedFile, file: null, url: "" });
    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile({ ...selectedFile, file: file, url: url });
      setForm({ ...form, imageUrl: url });
    }
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEditing ? "Edit" : "Add"} Insurance Company Details`}
        visible={visible}
        onOk={isEditing ? onUpdate : onSubmit}
        onCancel={() => {
          onCancel();
          resetForm();
        }}
        confirmLoading={requestLoading}
        okText={`${isEditing ? "Save" : "Submit"}`}
      >
        <div className="field-container">
          <p className="field-label">Name: </p>
          <Input
            value={form.name}
            placeholder="Insurance Company"
            onChange={(text) => handleFieldChange("name", text)}
          />
        </div>

        <div className="field-container">
          <p className="field-label">
            Emails <span>*</span> :{" "}
          </p>
          <Input
            placeholder='Press "Enter" after each email.'
            value={form.emailValue}
            onChange={(text) => handleFieldChange("emailValue", text)}
            onKeyDown={(text) => handleAddEmails(text)}
          />
        </div>

        <div className="tags-container">
          {form.emails?.map((email, i) => (
            <div key={i} className="tag-container">
              <IoMdCloseCircle onClick={() => deleteEmail(email)} />
              <p>{email}</p>
            </div>
          ))}
        </div>

        <div className="form-container">
          <p className="form-label">
            Image <span>*</span> :{" "}
          </p>
          <div className="upload-container" onClick={handleOpenGallery}>
            <FaUpload />
            <p className="banner-p">UPLOAD IMAGE</p>
          </div>

          <input
            ref={fileUploader}
            id="getFile"
            type="file"
            hidden
            onChange={handleImageChange}
          />
        </div>

        <div className="image-preview-container">
          <img src={form.imageUrl} />
        </div>
      </Modal>
    </div>
  );
};

export default InsuranceForm;
