import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchInsurances = async (data) => {
  const obj = {
    page: data.page ?? 1,
    limit: data.limit ?? 10,
    sort_by: "desc",
    sort_order: "date_created",
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/insurance?token=${encodedData}`,
    axiosConfig
  );
};



export const addInsurance = async (payload) => {
  const data = {
    name: payload.name,
      emails: payload.emails,
      image_id: payload.image_id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/insurance`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteInsurance = async (id) => {
  console.log(id);
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/insurance/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const editInsurance = async (payload) => {
  const data = {
    id: payload.id,
    name: payload.name,
    emails: payload.emails,
    image_id: payload.image_id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/insurance/update`,
    { token: encodedData },
    axiosConfig
  );
};






