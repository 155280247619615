import React , {useState , useEffect} from 'react';
import { fetchTotalDirectSeller } from '../../stores/api_calls/dashboard';
import {
    FaBuysellads,
  } from "react-icons/fa";
import {AiOutlineArrowRight} from 'react-icons/ai'
import './card.scss'
import { useNavigate } from 'react-router-dom';

const TotalDirectSeller = () => {

    const navigate = useNavigate()
    const [totalUser , setTotalUser] = useState()
    const [isLoading , setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true)
        const getTotalDealer = fetchTotalDirectSeller()

        getTotalDealer.then((res)=>{
            if(res.data){
                if(res.data.success){
                    // console.log(res.data.data);
                    setTotalUser(res.data.data)
                    setIsLoading(false)
                }
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    return (
        <div>
            <div className='total-banner header-card'>
                <p className='header-text'>Direct Seller</p>
                <FaBuysellads style={{width:"80px"}}/>
            </div>
            <div className='total-banner body-card'>
                <p className='active-text'>Total : {isLoading ? 'fetching' : !isLoading&&totalUser? totalUser : 0}</p>
                <div className='goIcon' onClick={()=>navigate("../direct-sellers", { replace: true })}>
                    <AiOutlineArrowRight/>
                </div>
            </div>
        </div>
    )
}

export default TotalDirectSeller;
