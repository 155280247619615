import React , {useState , useRef , useEffect} from 'react';
import './article.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertFromRaw, convertToRaw} from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import { CancelButton, IconButton, LoadingButton, PrimaryButton } from '../../custom_components/customButton';
import {RiImageAddFill} from 'react-icons/ri'
import { IoMdAddCircleOutline , IoMdCloseCircle} from 'react-icons/io';
import { FaUpload } from 'react-icons/fa';
import { Col, Input, Row, Select, Skeleton } from 'antd';
import { colorScheme } from '../../contants/variables';
import ArticlePreviewModal from '../../custom_components/articlePreviewModal';
import { addArticle, editArticle, fetchSections, fetchArticle } from '../../stores/api_calls/articles';
import { error, success } from '../../contants/snackbars';
import { uploadImage } from '../../stores/api_calls/upload';
import { useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../../stores/utils/jwt';
import { validateArticleForm } from '../../stores/helpers/articleFormValidation';

const ImageUploadComponent = (props) => {

    const { onChange } = props;
    const fileUploader = useRef(null)
    
    const uploadImageCallBack = (link) => {
        onChange(link, "auto", "100%", "uploaded image");
    }

    const onSelectFile = (e) => {
        const file = e.target.files[0]
        // const localUrl = URL.createObjectURL(file)
    
        const upload = uploadImage(file)

        upload.then((res)=>{
            if(res.data.success){
                console.log(res.data.data)
                const url = res.data.data.metadata.image_url
                uploadImageCallBack(url)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Upload Failed. Please try again`)
        })
    }

    const handleOpenGallery = event => {
        fileUploader.current.click();
    };

    return(
        <div>
            <div className='icon-button-div'>
                <IconButton 
                    className='icon-button upload' 
                    tootTipTitle="Upload Image" 
                    Icon={<RiImageAddFill/>} 
                    onClick={handleOpenGallery}
                />
            </div>
            
            <input
                ref={fileUploader}
                hidden
                type="file"
                name="image-event"
                id="image-event"
                accept="image/*"
                onChange={onSelectFile}
            />
        </div>
    )
}

const ArticleForm = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search)

    const fileUploader = useRef(null)
    const [isEdit , setIsEdit] = useState(false)
    const [form , setForm] = useState({
        articleId:"",
        articleTitle:"",
        articleAuthor:"",
        articleSection:"",
        articleTagValue:"",
        articleTagsArray:[],
        articleThumbnail:"",
        selectedThumbnailFile:null,
        youtubeVideo:"",
    })
    const [sections , setSections] = useState([])
    const [requestLoading ,setRequestLoading] = useState(false)

    const [showPreview , setShowPreview] = useState(false)
    const [convertedContent, setConvertedContent] = useState(null);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );

    const [article, setArticle] = useState({});
    const [fetchLoading , setFetchLoading] = useState(false)

    useEffect(()=>{
        const pagination = {
            keyword : "",
            page : 1,
            limit:100
        }
        const getSections = fetchSections(pagination)
        getSections.then((res)=>{
            if(res.data.success){
                setSections(res.data.data)
                if(location.pathname.includes("/edit")){
                    setIsEdit(true)
                }
            }
        }).catch((e)=>console.log(e))
    },[])

    useEffect(()=>{
        if (isEdit) {
            // console.log(params.get('token'));
            const parsedData = Kjur.decode(params.get('token'))

            console.log("PARAMS" , parsedData)
            setFetchLoading(true)

            const getArticle = fetchArticle(parsedData?._id)
            getArticle.then((res) => {
                if(res.data.success){
                    
                    setArticle(res.data.data)
                    setFetchLoading(false)
                }
            }).catch((e)=>console.log(e))

            // const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
            // // convert the raw state back to a useable ContentState object
            // const emptyContent = convertFromRaw( JSON.parse( rawDraftContentState) );

            // //converting the json to display in EDITOR
            // const contentState = convertFromRaw( parsedData?.article_body ?? emptyContent);
            // const newContent = EditorState.createWithContent(contentState)

            // setForm({
            //     ...form,
            //     articleId:parsedData?._id,
            //     articleTitle:parsedData?.article_title,
            //     articleAuthor:parsedData?.article_author,
            //     articleSection:parsedData?.article_section._id["$oid"],
            //     articleTagsArray:parsedData?.article_tags,
            //     articleThumbnail:parsedData?.article_thumbnail
            // })
            // setEditorState(newContent)
        }
    },[isEdit])

    useEffect(()=>{
        
        if(!fetchLoading && isEdit) {

            const parsedData = Kjur.decode(params.get('token'))
            
            const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
            // convert the raw state back to a useable ContentState object
            const emptyContent = convertFromRaw( JSON.parse( rawDraftContentState) );

            //converting the json to display in EDITOR
            const contentState = convertFromRaw( article.article_body ?? emptyContent);
            const newContent = EditorState.createWithContent(contentState)

            setForm({
                ...form,
                // articleId:article?._id,
                // articleTitle:article?.article_title,
                // articleAuthor:article?.article_author,
                // articleSection:article?.article_section._id["$oid"],
                // articleTagsArray:article?.article_tags,
                // articleThumbnail:article?.article_thumbnail
                articleId:parsedData?._id,
                articleTitle:parsedData?.article_title,
                articleAuthor:parsedData?.article_author,
                articleSection:parsedData?.article_section._id["$oid"],
                articleTagsArray:parsedData?.article_tags,
                articleThumbnail:parsedData?.article_thumbnail,
                youtubeVideo:article.article_youtube_video,
            })
            setEditorState(newContent)
        }
    }, [fetchLoading])

    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const handleFieldChange = (name , e) => {
        setForm({...form , [name]:e.target.value})
    }

    const handleDropdownChange = (name , value) => {
        setForm({...form , [name]:value})
    }

    const handleTags = (e) => {
        if (e.key === "Enter") {
            setForm({
                ...form,
                articleTagValue:"",
                articleTagsArray:[...form.articleTagsArray , e.target.value]
            })
        }
    }

    const deleteTag = (tag) =>{
        const filteredArray = form.articleTagsArray.filter((el)=>el!==tag)
        setForm({
            ...form,
            articleTagsArray:filteredArray
        })
    }

    const handleOpenGallery = event => {
        fileUploader.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0]
        const localUrl = URL.createObjectURL(file)
        // console.log(file , localUrl)
        setForm({...form , selectedThumbnailFile:file, articleThumbnail:localUrl})
    }

    const handleDeleteSelectedFile = () => {
        setForm({...form , selectedThumbnailFile:null, articleThumbnail:""})
    }

    const onSubmit = () =>{

        setRequestLoading(true)
        // the raw state, stringified
        const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
        // convert the raw state back to a useable ContentState object
        const contentState = convertFromRaw( JSON.parse( rawDraftContentState) );

        const newContent = EditorState.createWithContent(contentState)
        setConvertedContent(newContent)

        const newArticle = form
        // newArticle.articleBody = rawDraftContentState
        newArticle.articleBody = JSON.parse(rawDraftContentState)

        

        if(isEdit){
            if(form.selectedThumbnailFile){
                const uploadThumbnail = uploadImage(form.selectedThumbnailFile)
                uploadThumbnail.then((res)=>{
                    if(res.data.success){
                        const url = res.data.data.metadata.image_url
                        const updateArticle = editArticle(newArticle , url)
                        updateArticle.then((res)=>{
                            if(res.data.success){
                                console.log(res.data.data)
                                success("New Article Added Successfully")
                                navigate("../articles", { replace: true })
                                setRequestLoading(false)
                            }
                        }).catch((e)=>{
                            error("Failed to update Article")
                            setRequestLoading(false)
                        })
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Thumbnail Upload Failed. Please try again`)
                })
            }else{
                const updateArticle = editArticle(newArticle , form.articleThumbnail)
                updateArticle.then((res)=>{
                    if(res.data.success){
                        console.log(res.data.data)
                        success("New Article update Successfully")
                        navigate("../articles", { replace: true })
                        setRequestLoading(false)
                    }
                }).catch((e)=>{
                    error("Failed to Add Article")
                    setRequestLoading(false)
                })
            }
        }else{
            if(form.selectedThumbnailFile){
                const uploadThumbnail = uploadImage(form.selectedThumbnailFile)

                uploadThumbnail.then((res)=>{
                    if(res.data.success){
                        const url = res.data.data.metadata.image_url
                        const createArticle = addArticle(newArticle , url)
                        createArticle.then((res)=>{
                            if(res.data.success){
                                console.log(res.data.data)
                                success("New Article Added Successfully")
                                navigate("../articles", { replace: true })
                                setRequestLoading(false)
                            }
                        }).catch((e)=>{
                            error("Failed to Add Article")
                            setRequestLoading(false)
                        })
                    }
                }).catch((e)=>{
                    error(`Opps! ${e.response.data.server_response} . Thumbnail Upload Failed. Please try again`)
                    setRequestLoading(false)
                })
            }else {
                const createArticle = addArticle(newArticle , form.articleThumbnail)
                createArticle.then((res)=>{
                    if(res.data.success){
                        console.log(res.data.data)
                        success("New Article Added Successfully")
                        navigate("../articles", { replace: true })
                        setRequestLoading(false)
                    }
                }).catch((e)=>{
                    error("Failed to Add Article")
                    setRequestLoading(false)
                })
            }
        }
    }

    const handlePreview = () => {
        // the raw state, stringified
        const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
        // convert the raw state back to a useable ContentState object
        const contentState = convertFromRaw( JSON.parse( rawDraftContentState) );
        const newContent = EditorState.createWithContent(contentState)
        setConvertedContent(newContent)
        setShowPreview(true)
    }
    
    return (
        <div className='article-form-container'>
            <div className={`table-title-icon ${!isEdit? "add" : "edit"}`}>
                <IoMdAddCircleOutline/>
            </div>
            <p className={`table-title-text ${!isEdit? "add" : "edit"}`}>{!isEdit? "Create Article" : `Edit Article`}</p>

            {fetchLoading?
            
                        [...Array(4)].map((_,i)=>
                            <Col key={i} span={6}>
                                <div className='article-card-container' style={{padding:"20px"}}>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            </Col>
                        )
            :
            <div> 
                    <div className='form-container'>
                <Row gutter={[15,15]}>
                    <Col span={12}>
                        <div className='form-fields'>
                            <p className='form-field-label'>Article Title <span>*</span> : </p>
                            <Input
                                placeholder='Article Title'
                                value={form.articleTitle}
                                onChange={text=>handleFieldChange('articleTitle' , text)}
                            />
                        </div>

                        <div className='form-fields'>
                            <p className='form-field-label'>Article Author <span>*</span> : </p>
                            <Input
                                placeholder='John D. Doe'
                                value={form.articleAuthor}
                                onChange={text=>handleFieldChange('articleAuthor' , text)}
                            />
                        </div>

                        <div className='form-fields'>
                            <p className='form-field-label'>Article Section <span>*</span> : </p>
                            <Select
                                showSearch
                                value = {form.articleSection || undefined}
                                onChange={(value)=>handleDropdownChange('articleSection' , value)}
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {sections.map((el,i)=>
                                    <Select.Option key={i} value={el._id}>
                                        {`${el.section_name}`}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className='form-fields'>
                            <p className='form-field-label'>Youtube Video : </p>
                            <Input
                                placeholder='Enter Youtube Video'
                                value={form.youtubeVideo}
                                onChange={text=>handleFieldChange('youtubeVideo' , text)}
                            />
                        </div>

                        <div className='form-fields'>
                            <p className='form-field-label'>Article Tags <span>*</span> : </p>
                            <Input
                                placeholder='Press "Enter" after each tags.'
                                value={form.articleTagValue}
                                onChange={text=>handleFieldChange('articleTagValue' , text)}
                                onKeyDown={text=>handleTags(text)}
                            />
                        </div>

                        <div className='tags-container'>
                            {form.articleTagsArray?.map((tag,i)=>
                                <div key={i} className='tag-container'>
                                    <IoMdCloseCircle onClick={()=>deleteTag(tag)}/>
                                    <p>{tag}</p>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        {!form.articleThumbnail?
                            <div>
                                <div className='upload-container col-div' onClick={handleOpenGallery}>
                                    <FaUpload/>
                                    <p>UPLOAD THUMBNAIL PHOTO</p>
                                </div>
                                
                                <input 
                                    ref={fileUploader} 
                                    id="getFile" 
                                    type="file" 
                                    hidden
                                    onChange={handleImageChange}
                                />
                            </div>
                            :<div className='local-image'>
                                <div className='erase-image' onClick={()=>handleDeleteSelectedFile()}>
                                    <IoMdCloseCircle/>
                                </div>
                                <img src={form.articleThumbnail}/>
                            </div>
                        }
                        <span style={{color:'red'}}>If video inserted photo will not appear.</span>
                    </Col>
                </Row>
            </div>

            <div className='editor-container'>
                <Editor 
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                        inline: { inDropdown: false },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: { 
                            component: ImageUploadComponent, 
                            previewImage: true, 
                            uploadEnabled: true, 
                            alt: { present: true, mandatory: true } 
                        },
                    }}
                />
            </div>

            <ArticlePreviewModal
                visible = {showPreview}
                form = {form}
                editorContent = {convertedContent}
                onCancel = {()=>setShowPreview(false)}
            />
                </div>
            }
            
            

            {/* {convertedContent?
                <Editor editorState={convertedContent} readOnly toolbar={{options:[]}}/>
            :null} */}

            

            <div className='btn-div'>
                <div className="btn">
                    <PrimaryButton 
                        label="Preview Article"
                        color={colorScheme.secondary}
                        onClick={handlePreview}
                    />
                </div>

                <div className="btn">
                    {requestLoading? <LoadingButton/>
                    :<PrimaryButton 
                        label={isEdit?"Update Article" : "Create Article"}
                        color={colorScheme.primary}
                        onClick={()=>onSubmit()}
                        disabled={!validateArticleForm(form)}
                    />
                    }
                </div>
            </div>
            
        </div>
    )
}

export default ArticleForm
