import { Modal } from 'antd'
import React from 'react'
import './custom.scss'

const CustomConfirmModal = (props) => {

    const {title , visible , onSubmit , onCancel , data}=props

    return (
        <Modal
            className='deleteModal'
            title={title}
            visible={visible}
            onOk={onSubmit}
            onCancel={onCancel}
            okText="Okay"
        >
            {props.children}
        </Modal>
    )
}

export default CustomConfirmModal
