import { Modal, Input, Checkbox, Col, Row, Radio } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect, useContext } from "react";
import { error, success } from "../../contants/snackbars";
import { colorScheme } from "../../contants/variables";
import { addUser, editUser } from "../../stores/api_calls/users";
import { UserContext } from "../../stores/contexts/userContext";
import { validateUserForm } from "../../stores/helpers/userHelpers";
import { IoMdCloseCircle } from "react-icons/io";
import Validator from "../../stores/utils/validator";
import "./user.scss";

const UserForm = (props) => {
  const { isEdit, data, onCancel, visible, refetchLists, roles, permissions } =
    props;
  const [activePermission, setActivePermission] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userConfig] = useContext(UserContext);
  const [inputError, setInputError] = useState({});

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    roleId: roles.find((item) => item.name === "Admin")._id,
    contactNumbers: [],
    password: "P@ssw0rd",
  });

  useEffect(() => {
    if (isEdit) {
      setForm({
        ...form,
        firstName: data.user_first_name,
        lastName: data.user_last_name,
        email: data.user_contact_emails[0].email,
        roleId: roles
          .filter((el) => el.name === data.user_role)
          .map((el) => el._id)[0],
        contactNumbers: data.user_contact_numbers.map((item) => item.number),
      });
      setActivePermission(data.user_permissions);
    } else {
      setForm({
        ...form,
        firstName: "",
        lastName: "",
        email: "",
        contactNumbers: [],
      });
      setActivePermission([]);
    }
  }, [isEdit]);

  const handleFieldChange = (name, e) => {
    const value = e.target.value;
    if(name === "contactNumberValue") {
      if (/^\d*$/.test(value)) {
        setForm({ ...form, [name]: value })
      }
    } else if (name === "contactNumbers") {
      setForm({ ...form, [name]: e });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const onCheckboxChange = (permission, e) => {
    if (e.target.checked) {
      setActivePermission([...activePermission, permission]);
    } else {
      const filteredData = activePermission.filter((e) => e !== permission);
      setActivePermission(filteredData);
    }
  };

  const onClose = () => {
    setActivePermission([]);
    onCancel();
  };

  const onSubmit = () => {
    setSubmitLoading(true);

    if(!validateAllFormFields()) {
      setSubmitLoading(false);
      return;
    }

    const user = form;
    user.contactNumber = `+${form.contactNumber}`;
    user.permissions = activePermission;
    user.user_contact_emails = data.user_contact_emails;
    user.user_contact_numbers = form.contactNumbers;

    if (!isEdit) {
      const createUser = addUser(user);
      createUser
        .then((res) => {
          if (res.data.success) {
            resetForm();
            success("User Added");
            refetchLists();
            setSubmitLoading(false);

            onClose();
          }
        })
        .catch((error) => {
          const errorResponse = error.response.data;
        if(errorResponse.error_code === 422) {
          const validationError = Object.keys(errorResponse.server_response).reduce((result, key) => {
            result[key] = errorResponse.server_response[key][0]
            return result;
          }, {});

          const emailError = getValuesByWildcardKey(validationError, 'user_contact_emails*');
          setInputError(previous => {
            return {...previous, email: emailError}
          });

          const contactError = getValuesByWildcardKey(validationError, 'user_contact_numbers*');
          setInputError(previous => {
            return {...previous, contact: contactError}
          });

        } else {
          error(
            `Opps! ${errorResponse.server_response} . Fetched Failed. Please try again later`
          );
        }
          setSubmitLoading(false);
        });
    } else {
      user.id = data._id;
      const updateUser = editUser(user);
      updateUser
        .then((res) => {
          if (res.data.success) {
            resetForm();
            success("User Updated");
            refetchLists();
            setSubmitLoading(false);
            onClose();
          }
        })
        .catch((error) => {
          const errorResponse = error.response.data;
        if(errorResponse.error_code === 422) {
          const validationError = Object.keys(errorResponse.server_response).reduce((result, key) => {
            result[key] = errorResponse.server_response[key][0]
            return result;
          }, {});

          const emailError = getValuesByWildcardKey(validationError, 'user_contact_emails*');
          setInputError(previous => {
            return {...previous, email: emailError}
          });

          const contactError = getValuesByWildcardKey(validationError, 'user_contact_numbers*');
          setInputError(previous => {
            return {...previous, contact: contactError}
          });

        } else {
          error(
            `Opps! ${errorResponse.server_response} . Fetched Failed. Please try again later`
          );
        }
          setSubmitLoading(false);
        });
    }
  };

  const onRadioChange = (e) => {
    // console.log('radio checked', e.target.value);
    setForm({ ...form, roleId: e.target.value });
  };

  const resetForm = () => {
    setForm({
      ...form,
      firstName: "",
      lastName: "",
      email: "",
      contactNumbers: [],
    });
  };

  const handleAddContactNumber = (e) => {
    if (e.key === "Enter") {
      setForm({
        ...form,
        contactNumberValue: "",
        contactNumbers: [...form.contactNumbers, e.target.value],
      });
    }
  };

  function getValuesByWildcardKey(obj, pattern) {
    // Convert the wildcard pattern to a regular expression
    const regex = new RegExp(`^${pattern.replace(/\*/g, '.*')}$`);
  
    // Find keys that match the pattern and return their values
    return Object.keys(obj)
      .filter(key => regex.test(key))
      .reduce((result, key) => {
        return obj[key];
      }, '');
  }

  function validateAllFormFields() {
    let validForm = true;

    if(!validateEmail()) {
      validForm = false;
    }

    if(!validateFirstName()) {
      validForm = false;
    }

    if(!validateLastName()) {
      validForm = false;
    }

    if(!validateContacts()) {
      validForm = false;
    }

    if(!validatePermissions()) {
      validForm = false;
    }

    return validForm;
  }

  function validateContacts() {
    if(form.contactNumbers.length === 0) {
      setInputError(previous => {
        return {...previous, contact: 'At least one contact number is required.'}
      })
      return false;
    } else {
      setInputError(previous => {
        return {...previous, contact: ''}
      })
      return true;
    }
  }

  function validatePermissions() {
    if(activePermission.length === 0) {
      setInputError(previous => {
        return {...previous, permission: 'At least one permission is required.'}
      })
      return false;
    } else {
      setInputError(previous => {
        return {...previous, permission: ''}
      })
      return true;
    }
  }

  function validateEmail() {
    const {value, error} = new Validator(form.email).Required().Email();
    setInputError(previous => {
      return {...previous, email: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function validateFirstName() {
    const {value, error} = new Validator(form.firstName).Required().Max(20);
    setInputError(previous => {
      return {...previous, firstName: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  function validateLastName() {
    const {value, error} = new Validator(form.lastName).Required().Max(20);
    setInputError(previous => {
      return {...previous, lastName: error}
    })

    if(error !== '') {
      return false;
    }  

    return true;
  };

  const deleteNumber = (number) => {
    const filteredArray = form.contactNumbers.filter((el) => el !== number);
    setForm({
      ...form,
      contactNumbers: filteredArray,
    });
  };

  function __renderErrorMessage(message) {
    return(
      <div className="form-container error-content">
        <p className="form-label"></p>
        <p style={{width: '100%', color: 'red'}}><small>{message}</small></p>
      </div>
    );
  }

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEdit ? "Edit" : "Add"} Admin User`}
        visible={visible}
        onOk={onSubmit}
        okButtonProps={{
          disabled: submitLoading,
        }}
        onCancel={onClose}
        okText={`${isEdit ? "Save" : "Submit"}`}
        confirmLoading={submitLoading}
        width={'800px'}
      >
        <div className="form-container">
          <p className="form-label">
            User First Name <span>*</span> :{" "}
          </p>
          <Input
            value={form.firstName}
            onChange={(text) => handleFieldChange("firstName", text)}
            placeholder="Please type First Name"
          />
        </div>
        { inputError.firstName && __renderErrorMessage(inputError.firstName) }
        <div className="form-container">
          <p className="form-label">
            User Last Name <span>*</span> :{" "}
          </p>
          <Input
            value={form.lastName}
            onChange={(text) => handleFieldChange("lastName", text)}
            placeholder="Please type Last Name"
          />
        </div>
        { inputError.lastName && __renderErrorMessage(inputError.lastName) }

        <div className="form-container contact-number">
          <p className="form-label">
            Contact Numbers <span>*</span> :{" "}
          </p>
          <Input
            placeholder='Press "Enter" after each contact number.'
            value={form.contactNumberValue}
            onChange={(text) => handleFieldChange("contactNumberValue", text)}
            onKeyDown={(text) => handleAddContactNumber(text)}
            maxLength={15}
          />
        </div>

        <div className="form-container description">
          <p className="form-label"></p>
          <p style={{width: '100%'}}>
            <small>Please insert mobile number only.</small>
          </p>
        </div>
        {
          form?.contactNumbers.length > 0 ?
          <div className="form-container tags-container">
            <p className="form-label"></p>
            <div style={{width: '100%'}}>
              {form.contactNumbers?.map((number, i) => (
                <div key={i} className="tag-container">
                  <IoMdCloseCircle onClick={() => deleteNumber(number)} />
                  <p>{number}</p>
                </div>
              ))}
            </div>
          </div>
          :
          <>
            {__renderErrorMessage('At least one contact number is required.')}
          </>
        }

        <div className="form-container">
          <p className="form-label">
            Email Address <span>*</span> :{" "}
          </p>
          <Input
            value={form.email}
            onChange={(text) => handleFieldChange("email", text)}
            placeholder="Please type Valid Email Address"
            disabled={isEdit}
          />
        </div>
        { inputError.email && __renderErrorMessage(inputError.email) }

        {userConfig.userDetails.role_details.name === "Super Admin" ? (
          <div style={{ margin: "20px 0px" }}>
            <p className="form-label">
              Role <span>*</span> :{" "}
              <span style={{ color: "red" }}>
                Maximum of Nine (9) Super Admin account only.
              </span>
            </p>
            <Radio.Group onChange={onRadioChange} value={form.roleId}>
              {roles
                .filter((el) => ["Admin", "Super Admin"].includes(el.name))
                .map((el, i) => (
                  <Radio value={el._id}>{el.name}</Radio>
                ))}
            </Radio.Group>
          </div>
        ) : null}

        <div>
          <p className="permision-label">
            Permissions <span>(Check at least one) *</span> :{" "}
          </p>

          <Row gutter={[5, 5]}>
            {permissions
              .filter((el) => el.user_type !== "advertiser")
              .map((permission, idx) => (
                <Col span={12} key={idx}>
                  <div
                    className="permission-checklist"
                    key={idx}
                    style={{
                      background: activePermission.includes(permission.code)
                        ? colorScheme.lightgray
                        : colorScheme.offwhite,
                    }}
                  >
                    <Checkbox
                      valuePropName="checked"
                      value={permission.code}
                      checked={activePermission.includes(permission.code)}
                      onChange={(e) => onCheckboxChange(permission.code, e)}
                    >
                      {permission.name}
                    </Checkbox>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default UserForm;
